import { Center, Heading, Spinner, VStack, useColorModeValue, useToast } from '@chakra-ui/react';
import { type FunctionsError, httpsCallable } from 'firebase/functions';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { User } from '@/models';
import type { joinLeagueResponse } from '@/models/league';
import { firebaseErrorToastOptions, joinLeagueToastOptions } from '@/helpers';
import { EnterNamePage, FirebaseContext } from '@/components';
import bannerLight from '../assets/logos/bannerLight.svg';
import bannerDark from '../assets/logos/bannerDark.svg';
import { logEvent } from 'firebase/analytics';

export default function JoinLeagueView(props: {
  userData: User | undefined;
}) {
  const { authCode } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const ctx = useContext(FirebaseContext);

  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

  const userNamePresent = useMemo(() => !!props.userData && (props.userData.name ?? '').length > 0, [props.userData]);

  const heroSrc = useColorModeValue(bannerLight, bannerDark);

  useEffect(() => {
    if (ctx.user) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, [ctx.user]);

  useEffect(() => {
    if (isSignedIn && userNamePresent) {
      if (authCode !== undefined) {
        const cloudFunc = httpsCallable(ctx.funcs, 'joinLeague');
        cloudFunc({ authCode: authCode }).then(
          (result) => {
            const response = result.data as joinLeagueResponse;
            toast(joinLeagueToastOptions(response));
            logEvent(ctx.analytics, 'league_joined', { league_id: response.uid });
            navigate(`../../league/${response.uid}`, {
              replace: true,
            });
          },
          (error: FunctionsError) => {
            toast(firebaseErrorToastOptions('Failed To Join League', error));
            navigate('../../', { replace: true });
          },
        );
      }
    }
  }, [isSignedIn, userNamePresent, authCode, ctx.funcs, navigate, toast]);

  if (ctx.userDataPresent) {
    if (!userNamePresent) {
      return <EnterNamePage />;
    } else {
      return (
        <Center width='100vw' height='100vh'>
          <VStack spacing={8}>
            <Heading>Joining League...</Heading>
            <Spinner />
          </VStack>
        </Center>
      );
    }
  } else
    return (
      <Center width='100vw' height='100vh'>
        <Spinner />
      </Center>
    );
}
