import type { UseToastOptions } from '@chakra-ui/react';
import type { FirebaseError } from 'firebase/app';
import {
  type DocumentData,
  type QueryDocumentSnapshot,
  type QuerySnapshot,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { gameStage } from './models/fixture';
import type { joinLeagueResponse, leagueCreationDetails } from './models/league';

export type Dict<TValue> = { [Key: string]: TValue };
export function toDict<TValue>(
  snapshot: QuerySnapshot,
  keySelector: (doc: QueryDocumentSnapshot<DocumentData>) => string = (x) => x.id,
) {
  return snapshot.docs.reduce(
    (prev, x) => {
      prev[keySelector(x)] = x.data() as TValue;
      return prev;
    },
    {} as Dict<TValue>,
  );
}

export function getServerTimestamp(): Timestamp {
  return Timestamp.now();
}

export function successToastOptions(title: string, description?: string): UseToastOptions {
  return {
    title: title,
    description: description,
    status: 'success',
    duration: 5000,
    isClosable: true,
  };
}

export function errorToastOptions(title: string, description?: string): UseToastOptions {
  return {
    title: title,
    description: description,
    duration: 5000,
    status: 'error',
    isClosable: true,
  };
}

export function firebaseErrorToastOptions(title: string, error: FirebaseError): UseToastOptions {
  return {
    title: title,
    description: error.message,
    duration: 5000,
    status: 'error',
    isClosable: true,
  };
}

export function joinLeagueToastOptions(response: joinLeagueResponse): UseToastOptions {
  return {
    title: response.alreadyMember ? 'League Already Joined' : 'League Joined',
    status: response.alreadyMember ? 'info' : 'success',
    isClosable: true,
    duration: 5000,
    description: response.alreadyMember
      ? `You were already participating in ${response.name}.`
      : `You have joined ${response.name}.`,
  };
}

export function createLeagueToastOptions(details: leagueCreationDetails): UseToastOptions {
  return successToastOptions('League Created', `The league ${details.name} has been created.`);
}

export function isValidEmail(email: string) {
  const regex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return regex.test(email);
}

export function parseNumberInput(x: string) {
  if (x === '') {
    return 0;
  }
  return Math.abs(Number.parseInt(x));
}

export function ordinalSuffix(n: number) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
}

export function convertStageToString(stage: gameStage): string {
  switch (stage) {
    case gameStage.groupStage:
      return 'Group Stage';
    case gameStage.roundOf16:
      return 'Round of 16';
    case gameStage.quarterFinal:
      return 'Quarter Finals';
    case gameStage.semiFinal:
      return 'Semi Finals';
    case gameStage.thirdPlayoff:
      return 'Third Place Playoff';
    case gameStage.final:
      return 'Final';
    default:
      return 'Unknown';
  }
}
