/* This file exists only for one-off use in loading in fixtures from JSON file. */

import {
  Timestamp,
  collection,
  doc,
  writeBatch,
  type Firestore,
  type DocumentData,
  query,
  type QueryConstraint,
  type Query,
} from 'firebase/firestore';
import { ACTIVE_TOURNAMENT } from '../constants';
import { type Country, countriesInfo } from './country';
import fixturesJson from './fixtures.json';

export interface Fixture {
  date: Timestamp;
  location: string;
  homeTeam: Country;
  awayTeam: Country;
  stage: gameStage;
}

export enum gameStage {
  groupStage = 0,
  roundOf16 = 1,
  quarterFinal = 2,
  semiFinal = 3,
  thirdPlayoff = 4,
  final = 5,
}

function getGameStage(num: number) {
  switch (num) {
    case 1:
    case 2:
    case 3:
      return gameStage.groupStage;
    case 4:
      return gameStage.roundOf16;
    case 5:
      return gameStage.quarterFinal;
    case 6:
      return gameStage.semiFinal;
    case 7:
      return gameStage.thirdPlayoff;
    case 8:
      return gameStage.final;
    default:
      throw Error(`unknown game stage: ${num}`);
  }
}

export function queryFixtures(db: Firestore, ...queries: QueryConstraint[]): Query {
  return query(collection(db, 'competitions', ACTIVE_TOURNAMENT, 'fixtures'), ...queries);
}

export function loadFixtures(db: Firestore) {
  const batch = writeBatch(db);

  for (const fixture of fixturesJson) {
    const fixtureRef = doc(collection(db, 'competitions', ACTIVE_TOURNAMENT, 'fixtures'));
    const homeCountry = Object.values(countriesInfo).filter((x) => x.name === fixture.HomeTeam);
    const awayCountry = Object.values(countriesInfo).filter((x) => x.name === fixture.AwayTeam);
    if (homeCountry.length !== 1 || awayCountry.length !== 1) {
      console.log(homeCountry, awayCountry);
      throw Error(`unknown countries: ${homeCountry} - ${awayCountry}`);
    }

    const date = Timestamp.fromDate(new Date(Date.parse(fixture.DateUtc)));

    const document = {
      date: date,
      location: fixture.Location,
      homeTeam: homeCountry[0].id,
      awayTeam: awayCountry[0].id,
      stage: getGameStage(fixture.RoundNumber),
    } as DocumentData;

    batch.set(fixtureRef, document);
  }

  batch.commit();
  console.log('Done!');
}
