import {
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

// TODO: Investigate use of Modal vs Alert Dialog.
// TODO: Standardise modals to all be same style
export function ConfirmModal(props: {
  title: string;
  message: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => any;
  isDestructive?: boolean;
}) {
  useEffect(() => setIsBusy(false), [props.isOpen]);

  const [isBusy, setIsBusy] = useState(false);
  async function confirm() {
    setIsBusy(true);
    await props.onConfirm();
    props.onClose();
  }
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={28}>{props.title}</Heading>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody>
          <Text>{props.message}</Text>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button isDisabled={isBusy} onClick={props.onClose}>
              Cancel
            </Button>
            <Button colorScheme={props.isDestructive ? 'red' : 'green'} isLoading={isBusy} onClick={confirm}>
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
