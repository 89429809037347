import {
  Button,
  Collapse,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { type FunctionsError, httpsCallable } from 'firebase/functions';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import type { leagueCreationDetails } from '@/models/league';
import { createLeagueToastOptions, firebaseErrorToastOptions } from '../../helpers';
import { CreateLeagueView, FirebaseContext } from '../';
import { logEvent } from 'firebase/analytics';

export function CreateLeagueModal(props: { isOpen: boolean; onClose: () => void }) {
  const [details, setDetails] = useState<leagueCreationDetails>({
    name: '',
  });
  const [isBusy, setIsBusy] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const ctx = useContext(FirebaseContext);

  useEffect(() => setDetails({ name: '' }), [props.isOpen]);

  const leagueValid = details.name.trim().length > 0;

  async function createLeague(details: leagueCreationDetails) {
    const cloudFunc = httpsCallable(ctx.funcs, 'createLeague');
    try {
      const result = (await cloudFunc(details)).data as { uid: string };
      logEvent(ctx.analytics, 'league_created', { league_id: result.uid });
      toast(createLeagueToastOptions(details));
      navigate(`../league/${result.uid}`);
    } catch (error) {
      toast(firebaseErrorToastOptions('Failed To Create League', error as FunctionsError));
    }
  }

  async function onSubmit() {
    if (leagueValid) {
      setIsBusy(true);
      await createLeague(details);
      props.onClose();
      setIsBusy(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={28}>Create League</Heading>
        </ModalHeader>
        <Collapse in={!isBusy}>
          <ModalCloseButton color='black' mt={2} mr={2} />
        </Collapse>
        <ModalBody>
          <CreateLeagueView details={details} setDetails={setDetails} onEnterPressed={onSubmit} isDisabled={isBusy} />
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isBusy} isDisabled={!leagueValid} colorScheme='green' onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
