import {
  Flex,
  HStack,
  Image,
  Text,
  Icon,
  useMediaQuery,
  Box,
  Grid,
} from "@chakra-ui/react";
import type React from "react";
import type { PostCardProps } from "../post.types";
import { FaUserCircle as UserIcon } from "react-icons/fa";
import { IoCalendar as CalendarIcon } from "react-icons/io5";
import { MdOutlineTimelapse as TimeIcon } from "react-icons/md";
import { Link as ReactRouterLink } from "react-router-dom";

export const BlogPostItem: React.FC<
  PostCardProps & { slug: string; isLastItem?: boolean }
> = (props) => {
  const [isLargerThanMobile] = useMediaQuery("(min-width: 800px)");
  const route = `/scout/${props.slug}`;
  const templateColumns = isLargerThanMobile ? "1fr 3fr" : "120px auto";
  const imageBorderRadius = isLargerThanMobile ? "3xl" : "xl";

  return (
    <Grid
      templateColumns={templateColumns}
      alignItems="center"
      py={["16px", "32px"]}
      gap={[4, 8]}
      borderBottom="gray.100"
      borderBottomWidth={props.isLastItem ? 0 : "1.5px"}
    >
      <Image h="100%" borderRadius={imageBorderRadius} src={props.image} />
      <Box as={ReactRouterLink} to={route}>
        <PostCardMetadata {...props} />
      </Box>
    </Grid>
  );
};

export default BlogPostItem;

type PostCardMetadataProps = Pick<
  PostCardProps,
  | "author"
  | "title"
  | "excerpt"
  | "contentColor"
  | "publishedAt"
  | "readingTime"
>;

const PostCardMetadata: React.FC<PostCardMetadataProps> = ({
  title,
  excerpt,
  author,
  publishedAt,
  readingTime,
}) => {
  const [isLargerThanMobile] = useMediaQuery("(min-width: 800px)");

  const data = [
    {
      value: isLargerThanMobile ? author : undefined,
      icon: UserIcon,
    },
    {
      value: publishedAt,
      icon: CalendarIcon,
    },
    {
      value: readingTime,
      icon: TimeIcon,
    },
  ];

  return (
    <Flex flexDir="column" gap={isLargerThanMobile ? 6 : 2} textAlign="left">
      <Text
        overflowWrap="break-word"
        fontFamily="poppins"
        fontWeight={500}
        fontSize="xl"
        color="black"
      >
        {title}
      </Text>
      {isLargerThanMobile && (
        <Text fontWeight={400} fontSize="" color="#4A5568">
          {excerpt}
        </Text>
      )}
      <HStack spacing={isLargerThanMobile ? 5 : 3}>
        {data
          .filter((item) => !!item.value)
          .map((item) => (
            <HStack key={item.value} spacing={1}>
              <Icon boxSize="28px" fill="gray.500" as={item.icon} />
              <Text fontSize="xs" color="gray.500">
                {item.value}
              </Text>
            </HStack>
          ))}
      </HStack>
    </Flex>
  );
};
