import { DragDropContext, Draggable, type DropResult, Droppable, type ResponderProvided } from 'react-beautiful-dnd';
import { type MouseEventHandler, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Text,
} from '@chakra-ui/react';
import {
  type Country,
  countryCategory,
  countryListValid,
  tournamentCountriesInfo,
  countriesInfo,
} from '@/models/country';
import { parseNumberInput } from '../helpers';
import { Flag } from './Flag';
import { CountryTag } from './CountryTag';

function ToggleCountryCard(props: {
  country: Country;
  isSelected: boolean;
  onClick: MouseEventHandler;
  canBeSelected: boolean;
}) {
  const countryInfo = countriesInfo[props.country];

  return (
    <Button
      isDisabled={!(props.isSelected || props.canBeSelected)}
      colorScheme={props.isSelected ? 'green' : undefined}
      h={9}
      width='full'
      onClick={props.onClick}
      p={1.5}
      pr={4}
    >
      <Box minW={6}>
        <Flag country={countryInfo.id} borderRadius={3} h={6} w={6} hasShadow={props.isSelected} />
      </Box>
      <Box width='full' pb={0.5}>
        <Heading width='full' pt={0.5} textAlign={'center'} fontSize={16}>
          {countryInfo.name}
        </Heading>
      </Box>
    </Button>
  );
}

export function EditListCountryCard(props: { country: Country; index: number; isDisabled: boolean }) {
  const countryInfo = countriesInfo[props.country];

  return (
    <Draggable
      key={countryInfo.id}
      draggableId={countryInfo.name}
      index={props.index}
      isDragDisabled={props.isDisabled}
    >
      {(provided) => (
        <Box
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          width='full'
          minW={'10.5rem'}
          h={9}
        >
          <Center>
            <CountryTag country={countryInfo.id} opaque hasShadow={!props.isDisabled} draggable />
          </Center>
        </Box>
      )}
    </Draggable>
  );
}

export function EditCountryListView(props: {
  countryList: Country[];
  setCountryList: (list: Country[]) => void;
  mobileProps?: {
    tabIndex: number;
    setTabIndex: (index: number) => void;
    goalsGuess: number;
    setGoalsGuess: (guess: number) => void;
  };
}) {
  const selectionAreaRef = useRef<HTMLDivElement>(null);
  const listAreaRef = useRef<HTMLDivElement>(null);
  // save current window width in the state object
  const [dividerHeight, setDividerHeight] = useState(0);

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    setDividerHeight(Math.max(listAreaRef.current?.clientHeight ?? 0, selectionAreaRef.current?.clientHeight ?? 0));
    // timeoutId for debounce mechanism
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(
        () =>
          setDividerHeight(
            Math.max(listAreaRef.current?.clientHeight ?? 0, selectionAreaRef.current?.clientHeight ?? 0),
          ),
        150,
      );
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [listAreaRef, selectionAreaRef]);

  const countryList = props.countryList;
  const listValid = countryListValid(countryList);
  function toggleCountry(country: Country) {
    if (countryList.includes(country)) {
      props.setCountryList(countryList.filter((x) => x !== country));
    } else {
      const newList = Array.from(countryList);
      newList.push(country);
      props.setCountryList(newList);
    }
  }

  // Toggle Conditions
  const countryListFull = countryList.length >= 10;
  const canSelectFavourite =
    !countryListFull &&
    countryList.filter((x) => tournamentCountriesInfo[x].category === countryCategory.Favourite).length < 4;

  // Cards
  const favouriteToggleCards = Object.values(tournamentCountriesInfo)
    .filter((x) => x.category === countryCategory.Favourite)
    .map((x) => (
      <ToggleCountryCard
        key={x.id}
        country={x.id}
        canBeSelected={canSelectFavourite}
        isSelected={countryList.includes(x.id)}
        onClick={() => toggleCountry(x.id)}
      />
    ));
  const intermediateToggleCards = Object.values(tournamentCountriesInfo)
    .filter((x) => x.category === countryCategory.Intermediate)
    .map((x) => (
      <ToggleCountryCard
        key={x.id}
        country={x.id}
        canBeSelected={!countryListFull}
        isSelected={countryList.includes(x.id)}
        onClick={() => toggleCountry(x.id)}
      />
    ));
  const outsiderToggleCards = Object.values(tournamentCountriesInfo)
    .filter((x) => x.category === countryCategory.Outsider)
    .map((x) => (
      <ToggleCountryCard
        key={x.id}
        country={x.id}
        canBeSelected={!countryListFull}
        isSelected={countryList.includes(x.id)}
        onClick={() => toggleCountry(x.id)}
      />
    ));

  const selectionArea = (
    <VStack spacing={4}>
      <Heading fontSize={18} textAlign='start' width={'full'}>
        Seeds (max 4)
      </Heading>
      <SimpleGrid minChildWidth={36} gap={2} w='full' pb={3}>
        {favouriteToggleCards}
      </SimpleGrid>
      <Heading fontSize={18} textAlign='start' width={'full'}>
        Intermediates
      </Heading>
      <SimpleGrid minChildWidth={36} gap={2} w='full' pb={3}>
        {intermediateToggleCards}
      </SimpleGrid>
      <Heading fontSize={18} textAlign='start' width={'full'}>
        Outsiders (min 2)
      </Heading>
      <SimpleGrid minChildWidth={36} gap={2} w='full'>
        {outsiderToggleCards}
      </SimpleGrid>
    </VStack>
  );

  const listCards = countryList.map((x, i) => (
    <EditListCountryCard key={x} index={i} country={x} isDisabled={!listValid} />
  ));
  const listNumbers = Array.from(Array(10).keys()).map((i) => (
    <Center key={i} height={9}>
      <Heading fontSize={16}>{i + 1}</Heading>
    </Center>
  ));

  const listArea = (
    <HStack
      height='full'
      align={'start'}
      spacing={8}
      width='full'
      pl={props.mobileProps ? 12 : 0}
      pr={props.mobileProps ? 12 : 0}
    >
      <VStack height='full' spacing={2}>
        <Heading fontSize={18} pb={3}>
          Rank
        </Heading>
        {listNumbers}
      </VStack>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list' isDropDisabled={!listValid}>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps} width='full' height='full'>
              <VStack height='full' spacing={2} width='full' minW={'10.5rem'}>
                <Heading fontSize={18} pb={3}>
                  Country
                </Heading>
                {listCards}
                {provided.placeholder}
              </VStack>
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </HStack>
  );

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (result.source.droppableId === 'list' && result.destination?.droppableId === 'list') {
      const newList = Array.from(countryList);
      const country = newList.splice(result.source.index, 1)[0];
      newList.splice(result.destination.index, 0, country);
      props.setCountryList(newList);
    }
  }
  if (props.mobileProps) {
    return (
      <Tabs index={props.mobileProps.tabIndex} onChange={props.mobileProps.setTabIndex} mt={-3}>
        <TabPanels>
          <TabPanel>{selectionArea}</TabPanel>
          <TabPanel>{listArea}</TabPanel>
          <TabPanel>
            <FormControl>
              <FormLabel>Goals Guess</FormLabel>
              <NumberInput
                min={0}
                value={props.mobileProps.goalsGuess}
                onChange={(x) => props.mobileProps!.setGoalsGuess(parseNumberInput(x))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormHelperText>How many goals in total do you think will be scored in Euro 2024?</FormHelperText>
            </FormControl>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  } else
    return (
      <HStack spacing={10} height='full' align={'start'} width='full' pr={8} pl={8}>
        <Box width='full' ref={selectionAreaRef}>
          <Heading fontSize={28} mb={4}>
            1. Select Your Teams
          </Heading>
          {selectionArea}
        </Box>
        <Box pl={2}>
          <Divider orientation='vertical' height={`${dividerHeight}px`} />
        </Box>
        <Box ref={listAreaRef} opacity={listValid ? 1 : 0.4}>
          <Heading fontSize={28} mb={4}>
            2. Rank Teams
          </Heading>
          <Text pb={4}>The higher the rank, the more points!</Text>
          {listArea}
        </Box>
      </HStack>
    );
}
