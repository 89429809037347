import { type PropsWithChildren, createContext, useEffect, useState } from 'react';
import { type Analytics, getAnalytics } from 'firebase/analytics';
import { type User, getAuth, type Auth } from 'firebase/auth';
import { type FirebaseApp, initializeApp } from 'firebase/app';
import { type Firestore, getFirestore } from 'firebase/firestore';
import { type Functions, getFunctions } from 'firebase/functions';
import { type FirebaseStorage, getStorage } from 'firebase/storage';
import { getPerformance } from 'firebase/performance';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import { useAuthState } from 'react-firebase-hooks/auth';

// Your web app"s Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
function getFirebaseConfig() {
  if (import.meta.env.VITE_FIREBASE_ENV === 'development') {
    return {
      apiKey: 'AIzaSyBMBCiIaFSAVkMNd2Vcd4iST3qmGAfjT2Q',
      authDomain: 'tenteams-dev.web.app',
      projectId: 'tenteams-dev',
      storageBucket: 'tenteams-dev.appspot.com',
      messagingSenderId: '232774450025',
      appId: '1:232774450025:web:848c3f568200d5c3f71bf8',
      measurementId: 'G-SFEVNPZJV8',
    };
  } else {
    return {
      apiKey: 'AIzaSyBcRu2I4ghF6mGWntbooEl72xhgeAhbKmc',
      authDomain: 'tenteams.co.uk',
      projectId: 'tenteams',
      storageBucket: 'tenteams.appspot.com',
      messagingSenderId: '882640012543',
      appId: '1:882640012543:web:cdf17372af252ef3c978ca',
      measurementId: 'G-RQH26KSPH5',
    };
  }
}

const firebaseApp = initializeApp(getFirebaseConfig());
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west2');
const storage = getStorage(firebaseApp);
if (import.meta.env.PROD) {
  // Initialize Performance Monitoring and get a reference to the service
  const perf = getPerformance(firebaseApp);
}
const appCheckKey =
  import.meta.env.VITE_FIREBASE_ENV === 'development'
    ? '6LeQZzwiAAAAAAWT8QWnOX7b1vYOeHpdOqSLXByH'
    : '6LcxGFIhAAAAAHWezhHUWzX8IkSZpCEWemCC3FdC';
initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(appCheckKey),
  isTokenAutoRefreshEnabled: true,
});
const startingContext: IFirebaseContext = {
  app: firebaseApp,
  auth: auth,
  authLoading: false,
  db: db,
  funcs: functions,
  userDataPresent: false,
  user: auth.currentUser,
  storage: storage,
  analytics: analytics,
};

export const FirebaseContext = createContext(startingContext);
export interface IFirebaseContext {
  app: FirebaseApp;
  auth: Auth;
  authLoading: boolean;
  db: Firestore;
  funcs: Functions;
  userDataPresent: boolean;
  user: User | null;
  storage: FirebaseStorage;
  analytics: Analytics;
}

export function FirebaseProvider(props: PropsWithChildren) {
  const [state, changeState] = useState(startingContext);

  const [user, loading, error] = useAuthState(auth, {
    onUserChanged: async (user) => {
      if (user) {
        changeState((oldState) => ({
          ...oldState,
          userDataPresent: true,
          user: user,
        }));
      } else {
        changeState((oldState) => ({
          ...oldState,
          userDataPresent: true,
          user: null,
        }));
      }
    },
  });

  useEffect(() => {
    changeState((oldState) => ({
      ...oldState,
      authLoading: loading,
    }));
  }, [loading]);

  return <FirebaseContext.Provider value={state}>{props.children}</FirebaseContext.Provider>;
}
