import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { type ChangeEvent, type KeyboardEvent, useEffect, useMemo, useState } from 'react';

export function StringEntryModal(props: {
  title: string;
  label: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string) => Promise<void> | void;
  getInitialValue?: () => string;
  isValid?: (s: string) => boolean;
  helperText?: string;
  converter?: (s: string) => string;
}) {
  const getInitialValue = useMemo(() => props.getInitialValue ?? (() => ''), [props.getInitialValue]);
  const [value, setValue] = useState(props.converter ? props.converter(getInitialValue()) : getInitialValue());
  const [isBusy, setIsBusy] = useState(false);

  useEffect(
    () => setValue(props.converter ? props.converter(getInitialValue()) : getInitialValue()),
    [props.isOpen, getInitialValue],
  );

  const isValid = (props.isValid ?? ((_) => true))(value) && value.trim().length > 0;

  async function onSubmit() {
    if (isValid) {
      setIsBusy(true);
      await props.onSubmit(value);
      props.onClose();
      setIsBusy(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={28}>{props.title}</Heading>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody>
          <FormControl>
            <FormLabel>{props.label}</FormLabel>
            <Input
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  onSubmit();
                }
              }}
              value={value}
              isDisabled={isBusy}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setValue(props.converter ? props.converter(e.target.value) : e.target.value)
              }
            />
            {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isBusy} isDisabled={!isValid} colorScheme='green' onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
