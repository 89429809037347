import { Box, SlideFade, useDisclosure, useToast } from '@chakra-ui/react';
import { type ReactNode, useContext } from 'react';
import { deleteUser } from 'firebase/auth';
import { useAuthContext } from '@/views/AuthLayout';
import { updateUser } from '@/models/user';
import { errorToastOptions, successToastOptions } from '@/helpers';
import { Footer } from './Footer';
import { LoggedInNav } from './Navbar';
import { ConfirmModal, RulesModal, StringEntryModal } from './modals';
import { FirebaseContext } from './FirebaseContext';
import { logEvent } from 'firebase/analytics';

export const PageContainer = ({ children }: { children: ReactNode }) => (
  <Box maxWidth={'1444px'} margin='auto' minH={'100svh'}>
    {children}
  </Box>
);

export function AuthedPageContainer({ children }: { children: ReactNode }) {
  const { userData } = useAuthContext();
  const { name } = userData;
  const ctx = useContext(FirebaseContext);
  const toast = useToast();

  const rulesDisclosure = useDisclosure();
  const editNameDisclosure = useDisclosure();
  const deleteAccountDisclosure = useDisclosure();

  async function deleteAccount() {
    try {
      await deleteUser(ctx.user!);
      logEvent(ctx.analytics, 'delete_account');
      toast(successToastOptions('Account Successfully Deleted', 'We hope you enjoyed your time playing 10 Teams!'));
    } catch {
      toast(errorToastOptions('Error Deleting Account', 'Please sign out, sign back in, and try again.'));
    }
  }

  return (
    <SlideFade in>
      <PageContainer>
        <LoggedInNav
          userData={userData}
          openRules={rulesDisclosure.onOpen}
          openEditName={editNameDisclosure.onOpen}
          openDeleteAccount={deleteAccountDisclosure.onOpen}
        />
        <Box as='main' width='full' pt={{ base: 16, md: 16 }}>
          {children}
        </Box>
        <Footer />

        <RulesModal isOpen={rulesDisclosure.isOpen} onClose={rulesDisclosure.onClose} />

        <StringEntryModal
          isOpen={editNameDisclosure.isOpen}
          title='Edit Name'
          label='Your Name'
          onClose={editNameDisclosure.onClose}
          onSubmit={(name) =>
            // TODO: Check if this should be async.
            updateUser(ctx.db, ctx.user!.uid, {
              name: name,
              requiresShareUpdate: true,
            })
          }
          getInitialValue={() => name}
        />

        <ConfirmModal
          isOpen={deleteAccountDisclosure.isOpen}
          title='Delete Account'
          message={"Are you sure that you'd like to delete your account?"}
          onClose={deleteAccountDisclosure.onClose}
          isDestructive
          onConfirm={deleteAccount}
        />
      </PageContainer>
    </SlideFade>
  );
}
