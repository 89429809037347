import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Center, Grid, GridItem, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { Fragment } from 'react';
import { countriesInfo, type Country } from '@/models/country';
import type { gameResult } from '@/models/gameUpdate';
import { Flag } from './Flag';
import { CountryStatModal } from './modals';

type gameResults = {
  [key in Country]: gameResult[];
};

export function CountryListGrid(props: { countryList: Country[]; gameResults?: gameResult[]; svg?: boolean }) {
  const rulesDisclosures = props.countryList.map((_) => useDisclosure());
  const countryGameResults: Partial<gameResults> = {};
  for (const country of props.countryList) {
    countryGameResults[country] = props.gameResults?.filter((x) => x.countryA === country || x.countryB === country);
  }

  return (
    <Grid columnGap={6} rowGap={2}>
      {props.countryList.map((country, i) => {
        return (
          <Fragment key={i}>
            <GridItem rowStart={i + 1} colStart={1}>
              <Center height={'full'}>
                <Text fontWeight={'bold'} fontSize={22}>
                  {i + 1}
                </Text>
              </Center>
            </GridItem>
            <GridItem rowStart={i + 1} colStart={2}>
              <Center height={'full'} mr={'0.18rem'}>
                <Text fontSize={20}>{countriesInfo[country].name}</Text>
                {/* {props.gameResults && (
                  <IconButton
                    aria-label='Country Info'
                    icon={<InfoOutlineIcon />}
                    variant='unstyled'
                    onClick={rulesDisclosures[i].onOpen}
                    mt={-0.5}
                    pl={2}
                    minW={4}
                  />
                )} */}
              </Center>
            </GridItem>
            <GridItem rowStart={i + 1} colStart={3}>
              <Center height={'full'} minH={6} minW={6}>
                <Flag country={country} w={6} h={6} borderRadius='3px' svg={props.svg} />
              </Center>
            </GridItem>

            {props.gameResults && (
              <CountryStatModal
                country={country}
                countryRank={i + 1}
                isOpen={rulesDisclosures[i].isOpen}
                onClose={rulesDisclosures[i].onClose}
                gameResults={countryGameResults[country]}
              />
            )}
          </Fragment>
        );
      })}
    </Grid>
  );
}
