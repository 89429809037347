import {
  Center,
  Collapse,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AuthCards, type AuthCardsMode } from '../';

export function AuthModal(props: { mode: AuthCardsMode; isOpen: boolean; onClose: () => void }) {
  const [authUIReady, setAuthUIReady] = useState(false);
  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => setAuthUIReady(true), 250);
    } else {
      setAuthUIReady(false);
    }
  }, [props.isOpen]);

  return (
    <Modal closeOnOverlayClick={true} isCentered={true} isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalHeader />
      <ModalContent>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody pt={8}>
          <Collapse in={authUIReady} unmountOnExit={false}>
            <Center pb={4}>
              <AuthCards initialMode={props.mode} />
            </Center>
          </Collapse>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
