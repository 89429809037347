import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  VStack,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { type KeyboardEvent, useContext, useState } from 'react';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
} from 'firebase/auth';
import { useNavigate } from 'react-router';
import { errorToastOptions, firebaseErrorToastOptions, isValidEmail } from '@/helpers';
import { FirebaseContext } from '../FirebaseContext';
import { AuthDivider, GoogleButton, _handleEmailLogin, handleGoogle } from './utils';
import { BaseAuthCard } from './base-card';
import { useSearchParams } from 'react-router-dom';

export function LogInCard(props: { triggerSignup: () => void; triggerForgot: () => void }) {
  const { auth } = useContext(FirebaseContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPW, setShowPW] = useState<boolean>(false);
  const [signInAttempted, setSignInAttemped] = useState<boolean>(false);
  const [showGoogleError, setShowGoogleError] = useState<boolean>(false);
  const [signInEmailSent, setSignInEmailSent] = useState<boolean>(false);
  const [pwVisible, setPWVisible] = useState<boolean>(false);

  const handleEmail = () => {
    setSignInAttemped(true);
    setShowGoogleError(false);
    // TODO: if no email present then set error state. Use formik?
    // TODO: Check usage of this API. Is it being deprecated?
    // Email Enumeration Attacks?
    fetchSignInMethodsForEmail(auth, email)
      .then((signInMethods) => {
        // This returns the same array as fetchProvidersForEmail but for email
        // provider identified by 'password' string, signInMethods would contain 2
        // different strings:
        // 'emailLink' if the user previously signed in with an email/link
        // 'password' if the user has a password.
        // A user could have both.
        if (signInMethods.indexOf(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD) !== -1) {
          // handleGoogle(auth, navigate)
          setShowGoogleError(true);
        } else if (signInMethods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
          // User can sign in with email/password.
          setShowPW(true);
        } else if (signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !== -1) {
          // User can sign in with email/link.
          sendSignInLinkToEmail(auth, email, {
            url: searchParams.get('redirect_to')
              ? `${window.location.origin}${searchParams.get('redirect_to')}`
              : `${window.location.origin}/profile`,
            handleCodeInApp: true,
          }).catch((error) => {
            // console.error('sign in email link error', error);
            // TODO: Sentry report these errors
            toast(
              errorToastOptions(
                'Something went wrong',
                'Please refresh the page and try again later or contact hello@tenteams.co.uk',
              ),
            );
            // Some error occurred, you can inspect the code: error.code
          });
          setSignInEmailSent(true);
          localStorage.setItem('emailForSignIn', email);
        }
      })
      .catch((error) => {
        // console.error('error', error);
        // TODO: Sentry report these errors
        toast(
          errorToastOptions(
            'Something went wrong',
            'Please refresh the page and try again later or contact hello@tenteams.co.uk',
          ),
        );
      });
  };

  const handleEmailLogin = async () => {
    if (await _handleEmailLogin(auth, toast, email, password)) {
      navigate('/profile', { relative: 'path' });
    }
  };

  return (
    <BaseAuthCard>
      <Heading fontSize={28}>Log in to your account</Heading>
      <Text py={1}>
        Don&apos;t have an account yet?{' '}
        <Button variant='link' onClick={props.triggerSignup}>
          <Text as='b'>Sign Up</Text>
        </Button>
      </Text>
      <VStack gap={2} pt={2}>
        <FormControl isInvalid={signInAttempted && !isValidEmail(email)} isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            autoComplete='email'
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter' && !signInEmailSent) {
                showPW ? null : handleEmail();
              }
            }}
          />
          <FormErrorMessage>A valid email is required.</FormErrorMessage>
        </FormControl>
        {showGoogleError && <Text>Please use Log In with Google instead.</Text>}
        {showPW && (
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={pwVisible ? 'text' : 'password'}
                autoComplete='current-password'
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleEmailLogin();
                  }
                }}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={() => setPWVisible(!pwVisible)}>
                  {pwVisible ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button variant='link' onClick={props.triggerForgot}>
              <FormHelperText
                color={useColorModeValue('gray.600', 'white')}
                textDecoration={'underline'}
                fontSize='md'
                mt={0}
              >
                Forgot your password?
              </FormHelperText>
            </Button>
          </FormControl>
        )}
        <Button
          isDisabled={(signInAttempted && !isValidEmail(email)) || signInEmailSent || showGoogleError}
          onClick={showPW ? handleEmailLogin : handleEmail}
          width='full'
          backgroundColor={useColorModeValue('gray.200', 'white')}
          color='black'
          _hover={{}}
          mt={2}
        >
          {showPW ? 'Log In' : signInEmailSent ? 'Check your email to complete sign-in' : 'Continue'}
        </Button>
      </VStack>
      <AuthDivider />
      <HStack pb={4}>
        <GoogleButton auth={auth}>Log in with Google</GoogleButton>
      </HStack>
    </BaseAuthCard>
  );
}
