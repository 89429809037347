import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Center,
  Flex,
  Text,
  useBreakpointValue,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Divider,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import type { User } from '@/models';
import { countriesInfo } from '@/models/country';
import { type Fixture, gameStage } from '@/models/fixture';
import type { gameResult } from '@/models/gameUpdate';
import { convertStageToString } from '../helpers';
import { Flag } from './Flag';

export function ResultListGrid(props: { userData: User; gameResults: gameResult[]; svg?: boolean }) {
  const fontSize = useBreakpointValue({
    base: 16,
    sm: 16,
  });

  return (
    <Accordion w='full' maxW='500px' allowToggle>
      {[
        gameStage.final,
        gameStage.thirdPlayoff,
        gameStage.semiFinal,
        gameStage.quarterFinal,
        gameStage.roundOf16,
        gameStage.groupStage,
      ].map((stage) => {
        if (props.gameResults.filter((x) => x.stage === stage).length > 0) {
          return (
            <Fragment key={`${stage}`}>
              <Flex align='center'>
                <Divider />
                <Text padding='2' w={800}>
                  {convertStageToString(stage)}
                </Text>
                <Divider />
              </Flex>
              {props.gameResults
                .filter((x) => x.stage === stage)
                .map((result, i) => {
                  const aWon: boolean =
                    result.goalsA > result.goalsB ||
                    (result.goalsA === result.goalsB && !!result.wentToPens && result.penaltiesA! > result.penaltiesB!);
                  const matchDrawn: boolean = result.goalsA === result.goalsB && !result.wentToPens;
                  const pointsBreakdown = [
                    // [result.goalsA, 'Goals', result.goalsB],
                    [result.pointsA, 'Points', result.pointsB],
                  ];

                  const homeWon = result.wentToPens ? (result.penaltiesA! > result.penaltiesB!) : result.goalsA > result.goalsB

                  // if (matchDrawn) {
                  //   pointsBreakdown.push([1, 'Draw', 1])
                  // } else if (aWon) {
                  //   pointsBreakdown.push([3, 'Win', 0])
                  // } else {
                  //   pointsBreakdown.push([0, 'Win', 3])
                  // }

                  // if (result.stage != (gameStage.groupStage || undefined)) {
                  //   if (aWon) {
                  //     pointsBreakdown.push([stageToPoints(result.stage), convertStageToString(stage), 0])
                  //   } else {
                  //     pointsBreakdown.push([0, convertStageToString(stage), stageToPoints(result.stage)])
                  //   }
                  // }

                  return (
                    <Fragment key={i}>
                      <AccordionItem border={0} pb={2}>
                        <AccordionButton p={0}>
                          <Flex w='full' justify='space-between' gap={2} align='center' pb={2}>
                            <Center height={'full'} minH={6} minW={6} flex='1'>
                              <Flag country={result.countryA} w={6} h={6} borderRadius='3px' svg={props.svg} />
                            </Center>
                            <Center height={'full'} mr={'0.18rem'} flex='3'>
                              <Text fontSize={fontSize}>
                                <span style={{textDecoration: result.stage! > 0 ? (!homeWon ? 'line-through': 'unset') : 'unset'}}>{countriesInfo[result.countryA].name}</span>
                                <br />
                                {result.goalsA}{' '}
                                {result.wentToPens && (
                                  <Text as='span' color='gray'>
                                    ({result.penaltiesA})
                                  </Text>
                                )}
                              </Text>
                            </Center>
                            <Center height={'full'} justifySelf='center' flex='1'>
                              <Text fontWeight={'bold'} fontSize={fontSize}>
                                vs
                              </Text>
                            </Center>
                            <Center height={'full'} mr={'0.18rem'} flex='3'>
                              <Text fontSize={fontSize}>
                              <span style={{textDecoration: result.stage! > 0 ? (homeWon ? 'line-through': 'unset') : 'unset'}}>{countriesInfo[result.countryB].name}</span>
                                <br />
                                {result.wentToPens && (
                                  <Text as='span' color='gray'>
                                    ({result.penaltiesB})
                                  </Text>
                                )}{' '}
                                {result.goalsB}
                              </Text>
                            </Center>
                            <Center height={'full'} minH={6} minW={6} flex='1'>
                              <Flag country={result.countryB} w={6} h={6} borderRadius='3px' svg={props.svg} />
                            </Center>
                          </Flex>
                        </AccordionButton>
                        <AccordionPanel p={0} pr={2}>
                          <TableContainer w='full' overflowX='hidden' mx='auto'>
                            <Table size='sm' colorScheme='blue'>
                              <Thead>
                                <Tr>
                                  <Th w={1 / 3}>{countriesInfo[result.countryA].name}</Th>
                                  <Th w={1 / 3} />
                                  <Th isNumeric w={1 / 3}>
                                    {countriesInfo[result.countryB].name}
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {pointsBreakdown.map((item, idx) => (
                                  <Tr key={idx}>
                                    <Td>{item[0]}</Td>
                                    <Td textAlign={'center'}>{item[1]}</Td>
                                    <Td isNumeric>{item[2]}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                              {/* <Tfoot>
                                <Tr>
                                  <Td>x10</Td>
                                  <Td textAlign={'center'}>Multiplier</Td>
                                  <Td isNumeric>x9</Td>
                                </Tr>
                                <Tr>
                                  <Td>110</Td>
                                  <Td textAlign={'center'}>Total Points</Td>
                                  <Td isNumeric>9</Td>
                                </Tr>
                              </Tfoot> */}
                            </Table>
                          </TableContainer>
                        </AccordionPanel>
                      </AccordionItem>
                    </Fragment>
                  );
                })}
            </Fragment>
          );
        }
      })}
    </Accordion>
  );
}

function stageToPoints(stage: gameStage): number {
  switch (stage) {
    case gameStage.groupStage:
      return 0;
    case gameStage.roundOf16:
      return 3;
    case gameStage.quarterFinal:
      return 6;
    case gameStage.semiFinal:
      return 9;
    case gameStage.thirdPlayoff:
      return 0;
    case gameStage.final:
      return 12;
    default:
      return 0;
  }
}

export function FixtureListGrid(props: { fixtureList: Fixture[]; svg?: boolean }) {
  const fontSize = useBreakpointValue({
    base: 16,
    sm: 16,
  });
  const dateFontSize = useBreakpointValue({
    base: 14,
    sm: 16,
  });

  return (
    <Accordion w='full' maxW='500px'>
      {[
        gameStage.groupStage,
        gameStage.roundOf16,
        gameStage.quarterFinal,
        gameStage.semiFinal,
        gameStage.thirdPlayoff,
        gameStage.final,
      ].map((stage) => {
        if (props.fixtureList.filter((x) => x.stage === stage).length > 0) {
          return (
            <Fragment key={`${stage}`}>
              <Flex align='center'>
                <Divider />
                <Text padding='2' w={800}>
                  {convertStageToString(stage)}
                </Text>
                <Divider />
              </Flex>
              {props.fixtureList
                .filter((x) => x.stage === stage)
                .map((fixture, i) => (
                  <Fragment key={`${stage}-${i}`}>
                    <VStack pt={2}>
                      <Flex w='full' maxW='500px' justify='space-between'>
                        <Center height={'full'} minH={6} minW={6} flex='1'>
                          <Flag country={fixture.homeTeam} w={6} h={6} borderRadius='3px' svg={props.svg} />
                        </Center>
                        <Center height={'full'} mr={'0.18rem'} flex='3'>
                          <Text fontSize={fontSize}>{countriesInfo[fixture.homeTeam].name}</Text>
                        </Center>
                        <Center height={'full'} justifySelf='center' flex='1'>
                          <Text fontWeight={'bold'} fontSize={fontSize}>
                            vs
                          </Text>
                        </Center>
                        <Center height={'full'} mr={'0.18rem'} flex='3'>
                          <Text fontSize={fontSize}>{countriesInfo[fixture.awayTeam].name}</Text>
                        </Center>
                        <Center height={'full'} minH={6} minW={6} flex='1'>
                          <Flag country={fixture.awayTeam} w={6} h={6} borderRadius='3px' svg={props.svg} />
                        </Center>
                      </Flex>
                      <Center height={'full'} width={'full'} maxW='500px'>
                        <Text fontSize={dateFontSize}>
                          {/* TODO: Replace with user's locale. */}
                          {fixture.date.toDate().toLocaleString('en-GB')}
                        </Text>
                      </Center>
                    </VStack>
                  </Fragment>
                ))}
            </Fragment>
          );
        }
      })}
    </Accordion>
  );
}
