import { useContext } from 'react';
import { Navigate } from 'react-router';
import { FirebaseContext } from '@/components';

export default function SignOutPage() {
  const { auth } = useContext(FirebaseContext);
  auth.signOut();

  return <Navigate replace to='/' />;
}
