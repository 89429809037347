import { FaPlayCircle } from "react-icons/fa";
import type { IPost } from "../post.types";

export const HOW_TO_WIN_EVERY_GAME: IPost = {
  slug: "how-to-win-every-game",
  title: "How to Win Every Game",
  excerpt:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  content: `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
  <br><br>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  `,
  publishedAt: "2021-01-01",
  image: "https://picsum.photos/seed/picsum/536/354",
  imageAlt: "cover image for how to win every game article",
  isFeatured: true,
  relatedPosts: ["perfect-rank-strategy-guide", "the-ultimate-league-guide"],
  author: "John Doe",
  readingTime: "5 mins",
  cardConfig: {
    bgColor: "#19C8F5",
    contentColor: "white",
    icon: FaPlayCircle,
  },
};
