import {
  collection,
  collectionGroup,
  doc,
  type Firestore,
  getDoc,
  query,
  type Query,
  type QueryConstraint,
  serverTimestamp,
  setDoc,
  type Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { ACTIVE_TOURNAMENT } from '../constants';
import type { Country } from './country';
import { sentinelType } from './gameUpdate';

export default interface User {
  admin: boolean;
  name: string;
  email: string;
  shareShortLink: string | null;
  requiresShareUpdate: boolean;
  excludeMarketing?: boolean;
  createdAt?: Timestamp;
}
export interface TeamSelectionRecord {
  tournament: 'wwc-23' | 'mwc-22' | 'euro-24';
  countryList: Country[];
  prevScore: number;
  currentScore: number;
  potentialScore?: number; //a new property to account for users who made lists after start tiem
  goalsGuess: number;
  scoreLastUpdated: Timestamp;
  listIndexSwapPair: [number, number] | null;
  latecomer: boolean | undefined;
  spectator: boolean | undefined;
  listUpdatedAt?: Timestamp;
  swapUpdatedAt?: Timestamp;
}

export interface TeamSelectionData {
  [key: string]: TeamSelectionRecord;
}

export interface leagueMember {
  name: string;
  currentRank: number;
  prevRank: number;
  currentScore: number;
  prevScore: number;
  guessError: number | undefined;
  userID: string; // We need this to run a collection group query against for getting members of a specific user
  joinedLeague: Timestamp;
  latecomer: boolean | undefined;
  spectator: boolean | undefined;
  rankLastUpdated: Timestamp | undefined;
}

export async function updateUser(db: Firestore, userID: string, data: Partial<User>): Promise<void> {
  return await updateDoc(doc(db, 'users', userID), data);
}

export async function excludeUserFromMarketing(db: Firestore, userID: string): Promise<void> {
  return await updateDoc(doc(db, 'users', userID), { excludeMarketing: true });
}

export async function updateTeamSelectionData(
  db: Firestore,
  userID: string,
  data: Partial<TeamSelectionRecord>,
): Promise<void> {
  const teamDoc = doc(db, 'users', userID, 'tournaments', ACTIVE_TOURNAMENT);
  const docSnap = await getDoc(teamDoc);
  if (docSnap.exists()) {
    return await updateDoc(teamDoc, data);
  } else {
    const gameStartedDoc = await getDoc(
      doc(db, 'competitions', ACTIVE_TOURNAMENT, 'gameUpdates', sentinelType.tournamentStarted),
    );
    const tournamentStarted = gameStartedDoc.exists();

    const teamSelectionRecord = {
      tournament: ACTIVE_TOURNAMENT,
      countryList: [],
      currentScore: 0,
      prevScore: 0,
      goalsGuess: 0,
      scoreLastUpdated: serverTimestamp(),
      listIndexSwapPair: null,
      latecomer: tournamentStarted,
      spectator: true,
    };
    // Create the plain doc first + then update it. This forces an onTeamSelectionUpdated call as required
    await setDoc(teamDoc, { ...teamSelectionRecord });
    return await updateDoc(teamDoc, data);
  }
}

export function queryUsers(db: Firestore, ...queries: QueryConstraint[]): Query {
  return query(collection(db, 'users'), ...queries);
}

export function queryUserTeams(db: Firestore, ...queries: QueryConstraint[]): Query {
  return query(collectionGroup(db, 'tournaments'), ...queries);
}
