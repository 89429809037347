import {
  Box,
  Button,
  Heading,
  Image,
  Flex,
  Text,
  SlideFade,
  VStack,
  useColorModeValue,
  useDisclosure,
  Container,
  Stack,
  HStack,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { countriesInfo, Country as countryCode } from '@/models/country';
import { FirebaseContext, Footer, Nav, CTA, PageContainer, Flag, type AuthCardsMode } from '../../components';
import { RulesModal, StringEntryModal } from '../../components/modals';
import competeLight from '../../assets/logos/competeLight.png';
import competeDark from '../../assets/logos/competeDark.png';
import createListLight from '../../assets/logos/createListLight.png';
import createListDark from '../../assets/logos/createListDark.png';
import { AuthModal } from '../../components/modals/AuthModal';

export default function LegacyHomePage() {
  const joinLeagueDisclosure = useDisclosure();
  const rulesDisclosure = useDisclosure();
  const authModalDisclosure = useDisclosure();
  const ctx = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [authModalMode, setAuthModalMode] = useState<AuthCardsMode>('sign-up');

  const competeSrc = useColorModeValue(competeLight, competeDark);
  const createListSrc = useColorModeValue(createListLight, createListDark);
  const cardBackground = useColorModeValue('gray.50', 'gray.700');

  const dividerOrientation: 'horizontal' | 'vertical' | undefined = useBreakpointValue({
    base: 'horizontal',
    lg: 'vertical',
  });
  const dividerHeight = useBreakpointValue({ base: 'full', lg: '300px' });
  const dividerColor = useColorModeValue('black', 'white');

  function onGetStarted() {
    if (!ctx.user) {
      setAuthModalMode('sign-up');
      authModalDisclosure.onOpen();
    } else {
      navigate('../profile');
    }
  }
  const leagueButtons = (
    <VStack spacing={4} width='full'>
      <Button onClick={joinLeagueDisclosure.onOpen} width='full'>
        Join League
      </Button>
    </VStack>
  );

  const createListButton = (
    <VStack spacing={4} width='full'>
      <Button onClick={onGetStarted} width='full'>
        Create Your Team List
      </Button>
    </VStack>
  );

  const pointsTable = (
    <TableContainer>
      <Table variant='simple' size='sm'>
        <Thead>
          <Tr>
            <Th />
            <Th isNumeric>Points Earned</Th>
          </Tr>
        </Thead>
        <Tbody>
          {[
            ['Goal', 1],
            ['Win', 3],
            ['Draw', 1],
            ['Round of 16', 3],
            ['Quarter Final', 6],
            ['Semi Final', 9],
            ['Final', 12],
            ['Winner', 15],
          ].map((item, idx) => (
            <Tr key={idx}>
              <Td>{item[0]}</Td>
              <Td isNumeric>{item[1]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  const staticExampleList: [countryCode, number, string, number][] = [
    [countryCode.ES, 12, 'x10', 120],
    [countryCode.US, 8, 'x9', 72],
    [countryCode.BR, 14, 'x8', 112],
    [countryCode.PT, 9, 'x7', 63],
    [countryCode.CH, 3, 'x6', 18],
    [countryCode.HT, 7, 'x5', 35],
    [countryCode.GB_ENG, 2, 'x4', 8],
    [countryCode.JP, 1, 'x3', 3],
    [countryCode.NZ, 3, 'x2', 6],
    [countryCode.CR, 1, 'x1', 1],
  ];

  const exampleListTable = (
    <TableContainer>
      <Table variant='simple' size='sm'>
        <Thead>
          <Tr>
            <Th>Country List</Th>
            <Th isNumeric>Points</Th>
            <Th isNumeric>Multiplier</Th>
            <Th isNumeric>Weighted Points</Th>
          </Tr>
        </Thead>
        <Tbody>
          {staticExampleList.map((item, idx) => (
            <Tr key={idx}>
              <Td>
                <HStack>
                  <Text>{countriesInfo[item[0]].name}</Text>
                  <Flag country={item[0]} w={4} h={4} borderRadius='3px' svg />
                </HStack>
              </Td>
              <Td isNumeric>{item[1]}</Td>
              <Td isNumeric>{item[2]}</Td>
              <Td isNumeric>{item[3]}</Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td />
            <Td />
            <Td>Total Points</Td>
            <Td isNumeric>438</Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );

  return (
    <SlideFade in>
      <PageContainer>
        <Nav disclosure={authModalDisclosure} setAuthModalMode={setAuthModalMode} />
        <Box as='main' width='full' pt={{ base: 4, md: 12 }}>
          <CTA onGetStarted={onGetStarted} onHowTo={rulesDisclosure.onOpen} />
          <Container px={{ base: 2, md: 4 }} maxW={'full'}>
            <Stack
              align={'center'}
              spacing={{ base: 8, md: 10 }}
              px={{ base: 4, md: 0 }}
              py={{ base: 4, md: 8 }}
              direction={{ base: 'column-reverse', md: 'row' }}
            >
              <Flex flex={2} justify={'center'} align={'center'} position={'relative'} w={'full'}>
                <Box background={cardBackground} width={'full'} overflow={'hidden'}>
                  <Image
                    alt={'Hero Image'}
                    fit={'contain'}
                    align={'center'}
                    w={'100%'}
                    h={'100%'}
                    src={createListSrc}
                  />
                </Box>
              </Flex>
              <Stack width='full' align='start' flex={1} spacing={{ base: 4, md: 8 }}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  textAlign='start'
                  fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
                >
                  <Text as={'span'} position={'relative'}>
                    Rank Your Teams
                  </Text>
                </Heading>
                <Text textAlign='start' color={'gray.500'}>
                  Select just 10 teams from the 32 countries competing this summer. Pick a maximum of 4 Seeds and a
                  minimum of 2 Outsiders!
                </Text>
                {createListButton}
              </Stack>
            </Stack>
          </Container>
          <Container px={{ base: 2, md: 4 }} maxW={'full'}>
            <Stack
              align={'center'}
              spacing={{ base: 8, md: 10 }}
              px={{ base: 4, md: 0 }}
              py={{ base: 4, md: 8 }}
              direction={{ base: 'column-reverse', md: 'row-reverse' }}
            >
              <Flex flex={2} justify={'center'} align={'center'} position={'relative'} w={'full'}>
                <Box background={cardBackground} py={4} width={'full'} overflowX={'scroll'}>
                  <Stack justify='center' height={'full'} direction={{ base: 'column', lg: 'row' }}>
                    {pointsTable}
                    <Box px={2}>
                      <Divider orientation={dividerOrientation} height={dividerHeight} borderColor={dividerColor} />
                    </Box>
                    {exampleListTable}
                  </Stack>
                </Box>
              </Flex>
              <Stack width='full' align='start' flex={1} spacing={{ base: 4, md: 8 }}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  textAlign='start'
                  fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
                >
                  <Text as={'span'} position={'relative'}>
                    Score Points
                  </Text>
                </Heading>
                <Text textAlign='start' color={'gray.500'}>
                  Points are awarded if any of your teams score a goal, draw, win, or progress through the competition.
                  The higher your team is in your list, the more points these are worth.
                </Text>
                {/* {createListButton} */}
              </Stack>
            </Stack>
          </Container>
          <Container px={{ base: 2, md: 4 }} maxW={'full'}>
            <Stack
              align={'center'}
              spacing={{ base: 8, md: 10 }}
              px={{ base: 4, md: 0 }}
              py={{ base: 4, md: 8 }}
              direction={{ base: 'column-reverse', md: 'row' }}
            >
              <Flex flex={2} justify={'center'} align={'center'} position={'relative'} w={'full'}>
                <Box width={'full'} overflow={'hidden'}>
                  <Image alt={'Hero Image'} fit={'contain'} align={'center'} w={'100%'} h={'100%'} src={competeSrc} />
                </Box>
              </Flex>
              <Stack width='full' align='start' flex={1} spacing={{ base: 4, md: 8 }}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  textAlign='start'
                  fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
                >
                  <Text as={'span'} position={'relative'}>
                    Compete in Leagues
                  </Text>
                </Heading>
                <Text textAlign='start' color={'gray.500'}>
                  Join or create a league and compete with friends, family and colleagues.
                </Text>
                {leagueButtons}
              </Stack>
            </Stack>
          </Container>
        </Box>
        <Footer />
        <AuthModal
          mode={authModalMode}
          isOpen={authModalDisclosure.isOpen && !ctx.user}
          onClose={authModalDisclosure.onClose}
        />
        <StringEntryModal
          isOpen={joinLeagueDisclosure.isOpen}
          title='Join League'
          label='6 Digit Authentication Code'
          onClose={joinLeagueDisclosure.onClose}
          onSubmit={(authCode) => navigate(`../join/${authCode}`)}
          isValid={(authCode) => authCode.trim().length === 6}
          helperText='Anyone in your league can give this to you.'
          converter={(x) => x.toUpperCase()}
        />
        <RulesModal isOpen={rulesDisclosure.isOpen} onClose={rulesDisclosure.onClose} />
      </PageContainer>
    </SlideFade>
  );
}
