import { MinusIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';
import type { leagueMember } from '@/models/user';

export function RankChangeIndicator(
  props: {
    memberData: leagueMember;
    getListPosition: () => number;
  } & IconProps,
) {
  const { memberData, getListPosition, ...rest } = props;
  const prevRank = memberData.prevRank > 0 ? memberData.prevRank : getListPosition() + 1;
  if (memberData.currentRank < prevRank) {
    return <TriangleUpIcon color={'green.500'} {...rest} />;
  } else if (memberData.currentRank > prevRank) {
    return <TriangleDownIcon color={'red'} {...rest} />;
  } else return <MinusIcon color={'orange'} {...rest} />;
}
