// theme.ts

// 1. import `extendTheme` function
import { type ThemeConfig, extendTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { type StyleFunctionProps, mode } from '@chakra-ui/theme-tools';
import { inputAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const semanticTokens = {
  colors: {
    listItemBackground: { default: 'gray.100', _dark: 'whiteAlpha.200' },
    listItemBackgroundOpaque: { default: 'gray.100', _dark: 'gray.600' },
    bgBlue: '#0d0b2d',
  },
};

const styles = {
  global: (props: StyleFunctionProps) => ({
    body: {
      bg: mode('white', semanticTokens.colors.bgBlue)(props),
    },
  }),
};

const defaultTextColor = {
  baseStyle: (props: StyleFunctionProps) => ({
    color: mode('black', 'white')(props),
  }),
};

const baseInputStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    color: 'black', // change the input text color
  },
});
const inputTheme = defineMultiStyleConfig({
  baseStyle: baseInputStyle, // Add the baseInputStyle property
});

const components = {
  Text: defaultTextColor,
  Heading: defaultTextColor,
  FormLabel: defaultTextColor,
  Tab: defaultTextColor,
  Td: defaultTextColor,
  Input: inputTheme,
  NumberInput: inputTheme,
};

const fonts = {
  heading: 'Montserrat, Jost, Roboto, sans-serif',
  body: '"Open Sans", Montserrat, Jost, Roboto, sans-serif',
  mono: '"Open Sans", Menlo, monospace',
};

const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

// const fontSizes = {
//   xs: '12px',
//   sm: '14px',
//   md: '16px',
//   lg: '18px',
//   xl: '20px',
//   '2xl': '24px',
//   '3xl': '28px',
//   '4xl': '36px',
//   '5xl': '48px',
//   '6xl': '64px',
// }

const colors = {
  gray: {
    800: '#0d0b2d',
    700: '#13103f',
    600: '#25234E',
  },
};

// 3. extend the theme
const theme = extendTheme({
  config,
  semanticTokens,
  fonts,
  fontWeights,
  // fontSizes,
  styles,
  components,
  colors,
});

export default theme;
