import { Button, Center, HStack, Heading, Spinner, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import type { ParsedToken } from 'firebase/auth';
import { Timestamp, collectionGroup, doc, limit, onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import { type FunctionsError, httpsCallable } from 'firebase/functions';
import { type ReactNode, useContext, useEffect, useState } from 'react';
import { countryCategory, tournamentCountriesInfo } from '@/models/country';
import {
  addGame,
  deleteGame,
  type gameResult,
  type gameUpdate,
  queryGameUpdates,
  sentinelType,
} from '@/models/gameUpdate';
import { type Fixture, queryFixtures } from '@/models/fixture';
import { queryLeagues } from '@/models/league';
import { queryUserTeams } from '@/models/user';
import { AdminFixtureList, FirebaseContext } from '../components';
import { EditGameUpdateModal, NumberInputModal } from '../components/modals';
import { type Dict, firebaseErrorToastOptions, getServerTimestamp, successToastOptions, toDict } from '../helpers';
import { ACTIVE_TOURNAMENT } from '../constants';

export function AdminGameManagement() {
  const ctx = useContext(FirebaseContext);
  const [claims, setClaims] = useState<ParsedToken>();
  const editGameResultDisclosure = useDisclosure();
  const endTournamentDisclosure = useDisclosure();
  const toast = useToast();
  const [editingGameResultID, setEditingGameResultId] = useState<string>();
  const [gameUpdates, setGameUpdates] = useState<Dict<gameUpdate>>();
  const [futureFixtures, setFutureFixtures] = useState<Fixture[]>();
  const [allUsersUpToDate, setAllUsersUpToDate] = useState(true);
  const [allLeaguesUpToDate, setAllLeaguesUpToDate] = useState(true);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (ctx.user) {
      ctx.user.getIdTokenResult().then((result) => {
        setClaims(result.claims);
      });
    }
  }, [ctx.user]);

  useEffect(() => {
    if (ctx.user) {
      return onSnapshot(
        queryFixtures(ctx.db, where('date', '<', Timestamp.fromMillis(Date.now())), orderBy('date', 'desc'), limit(1)),
        (snapshot) => {
          setFutureFixtures(snapshot.docs.map((x) => x.data() as Fixture));
        },
      );
    }
  }, [ctx.user]);

  useEffect(() => {
    if (claims?.admin) {
      return onSnapshot(queryGameUpdates(ctx.db, orderBy('timestamp', 'desc')), (snapshot) => {
        setGameUpdates(toDict(snapshot));
      });
    } else {
      setGameUpdates(undefined);
    }
  }, [claims, ctx.db]);

  useEffect(() => {
    if (claims?.admin) {
      if (gameUpdates) {
        const gameResultKeys = Object.keys(gameUpdates).filter(
          (x) => !(x in sentinelType) && gameUpdates[x].timestamp !== null,
        );
        const tournamentEnded = sentinelType.tournamentEnded in gameUpdates;
        if (gameResultKeys.length > 0) {
          return onSnapshot(
            queryUserTeams(
              ctx.db,
              where('tournament', '==', ACTIVE_TOURNAMENT),
              where(
                'scoreLastUpdated',
                '<',
                tournamentEnded ? gameUpdates.tournamentEnded.timestamp : gameUpdates[gameResultKeys[0]].timestamp,
              ),
              where(
                'countryList',
                'array-contains-any',
                Object.values(tournamentCountriesInfo)
                  .filter((x) => x.category === countryCategory.Outsider)
                  .map((x) => x.id),
                // We're just checking that their list isn't empty, and we can do this by checking the outsiders which it must contain it has any elements.
              ),
              limit(1),
            ),
            (snapshot) => {
              setAllUsersUpToDate(snapshot.empty);
            },
          );
        } else if (Object.keys(gameUpdates).length < 1) {
          return onSnapshot(
            queryUserTeams(
              ctx.db,
              where('tournament', '==', ACTIVE_TOURNAMENT),
              where('currentScore', '!=', 0),
              limit(1),
            ),
            (snapshot) => {
              setAllUsersUpToDate(snapshot.empty);
            },
          );
        } else {
          setAllUsersUpToDate(true);
        }
      }
    } else {
      setAllUsersUpToDate(true);
    }
  }, [claims, ctx.db, gameUpdates]);

  useEffect(() => {
    if (claims?.admin) {
      if (gameUpdates) {
        const gameResultKeys = Object.keys(gameUpdates).filter(
          (x) => !(x in sentinelType) && gameUpdates[x].timestamp !== null,
        );
        const tournamentEnded = sentinelType.tournamentEnded in gameUpdates;
        if (gameResultKeys.length > 0) {
          return onSnapshot(
            queryLeagues(
              ctx.db,
              where(
                'ranksLastUpdated',
                '<',
                tournamentEnded ? gameUpdates.tournamentEnded.timestamp : gameUpdates[gameResultKeys[0]].timestamp,
              ),
              limit(1),
            ),
            (snapshot) => {
              setAllLeaguesUpToDate(snapshot.empty);
            },
          );
        } else if (Object.keys(gameUpdates).length < 1) {
          return onSnapshot(
            query(collectionGroup(ctx.db, 'members'), where('currentRank', '!=', -1), limit(1)),
            (snapshot) => {
              setAllLeaguesUpToDate(snapshot.empty);
            },
          );
        } else {
          setAllLeaguesUpToDate(true);
        }
      }
    } else {
      setAllLeaguesUpToDate(true);
    }
  }, [claims, ctx.db, gameUpdates]);

  function setSentinel(sentinel: sentinelType, otherData?: any) {
    return setDoc(
      doc(ctx.db, 'competitions', ACTIVE_TOURNAMENT, 'gameUpdates', sentinel),
      otherData
        ? {
            timestamp: getServerTimestamp(),
            ...otherData,
          }
        : { timestamp: getServerTimestamp() },
    );
  }

  function toggleAllowTeamSwap() {
    if (sentinelType.allowTeamSwap in gameUpdates!) {
      return deleteGame(ctx.db, sentinelType.allowTeamSwap);
    } else {
      return setSentinel(sentinelType.allowTeamSwap);
    }
  }

  function endTournament(totalGoals: number) {
    return setSentinel(sentinelType.tournamentEnded, {
      totalGoals: totalGoals,
    });
  }

  function resetTournament() {
    return Promise.all(Object.keys(gameUpdates!).map((x) => deleteGame(ctx.db, x)));
  }

  async function runCalculationBatch(funcName: string, reset: boolean) {
    setIsBusy(true);
    try {
      await httpsCallable(ctx.funcs, funcName, { timeout: 530000 })({ reset: reset });
      toast(successToastOptions('Calculations Successful', 'Successfully ran batch of calculations.'));
    } catch (e) {
      toast(firebaseErrorToastOptions('Calculations Error', e as FunctionsError));
    }
    setIsBusy(false);
  }

  function editGameResult(uid: string | undefined) {
    setEditingGameResultId(uid);
    editGameResultDisclosure.onOpen();
  }

  if (gameUpdates) {
    const tournamentStarted = sentinelType.tournamentStarted in gameUpdates;
    const teamSwapAllowed = sentinelType.allowTeamSwap in gameUpdates;
    const tournamentEnded = sentinelType.tournamentEnded in gameUpdates;

    let gameUpdateButtons: ReactNode;
    if (tournamentEnded) {
      gameUpdateButtons = (
        <Button colorScheme={'red'} onClick={resetTournament}>
          Reset Tournament
        </Button>
      );
    } else {
      if (tournamentStarted) {
        gameUpdateButtons = (
          <HStack justify='center'>
            <Button colorScheme={'green'} onClick={() => editGameResult(undefined)}>
              Add Game Result
            </Button>
            <Button colorScheme={teamSwapAllowed ? 'red' : 'green'} onClick={toggleAllowTeamSwap}>
              {teamSwapAllowed ? 'Disallow' : 'Allow'} Team Swap
            </Button>
            <Button colorScheme={'red'} onClick={endTournamentDisclosure.onOpen}>
              End Tournament
            </Button>
          </HStack>
        );
      } else {
        gameUpdateButtons = (
          <>
            <VStack>
              <Button colorScheme={'green'} onClick={() => setSentinel(sentinelType.tournamentStarted)}>
                Start Tournament
              </Button>
            </VStack>
          </>
        );
      }
    }

    const rankCalculationButton = (
      <VStack spacing={8}>
        {allUsersUpToDate && !allLeaguesUpToDate && (
          <Heading>There are leagues whose ranks need to be updated.</Heading>
        )}
        <Button
          colorScheme={'green'}
          isDisabled={!(allUsersUpToDate && !allLeaguesUpToDate)}
          isLoading={isBusy}
          onClick={() => runCalculationBatch('updateLeagueRankings', Object.keys(gameUpdates).length === 0)}
        >
          Run Rank Calculations
        </Button>
      </VStack>
    );

    const scoreCalculationButton = (
      <VStack spacing={8}>
        {!allUsersUpToDate && !allLeaguesUpToDate && (
          <Heading>There are users whose scores need to be updated.</Heading>
        )}
        <Button
          colorScheme={'green'}
          isLoading={isBusy}
          // isDisabled={!(!allUsersUpToDate && !allLeaguesUpToDate)}
          onClick={() => runCalculationBatch('updateUserScores', Object.keys(gameUpdates).length === 0)}
        >
          Run Score Calculations
        </Button>
      </VStack>
    );

    return (
      <>
        <VStack gap={4}>
          {gameUpdateButtons}
          {scoreCalculationButton}
          {rankCalculationButton}
          <AdminFixtureList editGameResult={editGameResult} />
        </VStack>

        <EditGameUpdateModal
          isOpen={editGameResultDisclosure.isOpen}
          onClose={editGameResultDisclosure.onClose}
          getInitialValue={() => (editingGameResultID ? (gameUpdates[editingGameResultID] as gameResult) : undefined)}
          onSubmit={(x) =>
            editingGameResultID
              ? setDoc(doc(ctx.db, 'competitions', ACTIVE_TOURNAMENT, 'gameUpdates', editingGameResultID), x)
              : addGame(ctx.db, x)
          }
          loadNextFixture={() => {
            console.log('future fixture ', futureFixtures);
            return futureFixtures ? futureFixtures[0] : undefined;
          }}
        />
        <NumberInputModal
          title='End Tournament'
          label='Enter the total number of goals that were scored throughout the entire tournament.'
          isOpen={endTournamentDisclosure.isOpen}
          onClose={endTournamentDisclosure.onClose}
          onSubmit={endTournament}
          isValid={(x) => x > 0}
          min={0}
        />
      </>
    );
  } else
    return (
      <Center width='full' height='full'>
        <Spinner />
      </Center>
    );
}
