import {
  Box,
  chakra,
  Container,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaEnvelope, FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import type { ReactNode } from 'react';

const SocialButton = ({ children, label, href }: { children: ReactNode; label: string; href: string }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      target='_blank'
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export function Footer() {
  return (
    <Box>
      <Container
        as={SimpleGrid}
        maxW={'full'}
        py={2}
        px={{ base: 2, md: 8 }}
        pb={{ base: 2, md: 4 }}
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 2, md: 4 }}
      >
        <Text alignItems={{ base: 'center', md: 'start' }} alignSelf='center' textAlign='center'>
          © 2024 The 10 Teams Company. All rights reserved
        </Text>
        <Flex gap={4} direction={{ base: 'column', md: 'row' }} justifyContent={'center'} alignItems={'center'}>
          <Link href={'/Privacy_Policy.pdf'} alignSelf='center' target='_blank' rel='noopener noreferrer'>
            Privacy Policy
          </Link>
          <Link href={'/Competition_T&Cs.pdf'} alignSelf='center' target='_blank' rel='noopener noreferrer'>
            Global League Competition T&Cs
          </Link>
        </Flex>
        <Stack direction={'row'} spacing={6} alignItems='center' justify={{ base: 'center', md: 'end' }}>
          <Text>Check us out at</Text>
          {/* <SocialButton label={'Email'} href={'mailto:contact@alexandria-software.com'}>
            <FaEnvelope />
          </SocialButton> */}
          {/* <SocialButton label={'Facebook'} href={'#'}>
            <FaFacebook />
          </SocialButton> */}
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/ten.teams/'}>
            <FaInstagram />
          </SocialButton>
          <SocialButton label={'Twitter'} href={'https://twitter.com/ten_teams'}>
            <FaTwitter />
          </SocialButton>
          <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/company/alexandria-software-ltd/'}>
            <FaLinkedin />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
