import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import type { ParsedToken } from 'firebase/auth';
import { onSnapshot, orderBy } from 'firebase/firestore';
import { countriesInfo } from '@/models/country';
import { deleteGame, type gameResult, type gameUpdate, queryGameUpdates, sentinelType } from '@/models/gameUpdate';
import { type Dict, toDict } from '@/helpers';
import { CountryTag, FirebaseContext } from '../components';
import { ConfirmModal } from '../components/modals';

export function GameResultCard(props: { id: string; gameResult: gameResult; editGameResult: (id: string) => void }) {
  const ctx = useContext(FirebaseContext);
  const deleteGameDisclosure = useDisclosure();
  const cardBrackground = useColorModeValue('gray.50', 'gray.700');
  const result = props.gameResult;
  const date = result.timestamp.toDate();
  return (
    <Box bg={cardBrackground} borderRadius='lg' p={4} width='full'>
      <VStack fontSize={16} spacing={2} width='full'>
        <HStack width='full' spacing={4}>
          <CountryTag country={result.countryA} />
          <Heading fontSize={16} width={'full'} textAlign='start'>
            +{result.pointsA} points
          </Heading>
        </HStack>
        <HStack width='full' spacing={4}>
          <CountryTag country={result.countryB} />
          <Heading fontSize={16} width={'full'} textAlign='start'>
            +{result.pointsB} points
          </Heading>
        </HStack>
        <Text>
          Added on {date.toLocaleDateString()} at {date.toLocaleTimeString()}
        </Text>
        <HStack alignSelf={'stretch'}>
          <Button width={'full'} colorScheme='blue' onClick={() => props.editGameResult(props.id)}>
            Edit
          </Button>
          <Button width={'full'} colorScheme={'red'} onClick={deleteGameDisclosure.onOpen}>
            Delete
          </Button>
        </HStack>
      </VStack>

      <ConfirmModal
        isOpen={deleteGameDisclosure.isOpen}
        title={`Delete ${countriesInfo[result.countryA].name} vs ${countriesInfo[result.countryB].name}`}
        message={"Are you sure that you'd like to delete this game result + points?"}
        onClose={deleteGameDisclosure.onClose}
        isDestructive
        onConfirm={() =>
          // TODO: Check if this should be async.
          deleteGame(ctx.db, props.id)
        }
      />
    </Box>
  );
}

interface AdminFixtureListProps {
  editGameResult: (uid: string | undefined) => void;
}

export function AdminFixtureList(props: AdminFixtureListProps) {
  const ctx = useContext(FirebaseContext);
  const [claims, setClaims] = useState<ParsedToken>();
  const [gameUpdates, setGameUpdates] = useState<Dict<gameUpdate>>();

  useEffect(() => {
    if (ctx.user) {
      ctx.user.getIdTokenResult().then((result) => {
        setClaims(result.claims);
      });
    }
  }, [ctx.user]);

  useEffect(() => {
    if (claims?.admin) {
      return onSnapshot(queryGameUpdates(ctx.db, orderBy('timestamp', 'desc')), (snapshot) => {
        setGameUpdates(toDict(snapshot));
      });
    } else {
      setGameUpdates(undefined);
    }
  }, [claims, ctx.db]);

  if (gameUpdates) {
    const gameResultKeys = Object.keys(gameUpdates).filter(
      (x) => !(x in sentinelType) && gameUpdates[x].timestamp !== null,
    );

    // Timestamp is only null when a local version is published by the
    // API before sync as we are using server timestamps.
    const gameResultCards = gameResultKeys
      .map((x) => ({ id: x, result: gameUpdates[x] as gameResult }))
      .filter(({ result }) => result.countryA !== undefined && result.timestamp)
      .map(({ id, result }) => (
        <GameResultCard key={id} id={id} gameResult={result} editGameResult={props.editGameResult} />
      ));

    return (
      <>
        <VStack overflowY={'auto'} maxH={'50vh'}>
          {gameResultCards}
        </VStack>
      </>
    );
  } else
    return (
      <Center width='full' height='full'>
        <Spinner />
      </Center>
    );
}
