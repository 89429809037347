import { FaPlayCircle } from "react-icons/fa";
import type { IPost } from "../post.types";

export const THE_ULTIMATE_LEAGUE_GUIDE: IPost = {
  slug: "the-ultimate-league-guide",
  title: "The Ultimate League Guide",
  excerpt:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  content: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    <br><br>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    `,
  publishedAt: "2021-01-01",
  image: "https://picsum.photos/id/143/367/267",
  imageAlt: "cover image for the ultimate league guide article",
  isFeatured: true,
  relatedPosts: ["perfect-rank-strategy-guide", "how-to-win-every-game"],
  author: "Jet Lee",
  readingTime: "1 min",
  cardConfig: {
    bgColor: "#002654",
    variant: "full-image",
    contentColor: "#F3EABC",
    icon: FaPlayCircle,
  },
};
