import type React from 'react';
import { Button as ChakraButton, type ResponsiveValue } from '@chakra-ui/react';

interface ButtonProps {
  text: string;
  bgColor?: string;
  onClick?: VoidFunction;
  fontSize?: ResponsiveValue<string | number>;
  px?: ResponsiveValue<string | number>;
  py?: ResponsiveValue<string | number>;
  variant?: 'solid' | 'outline' | 'ghost' | 'link';
  hoverBgColor?: string;
  hoverTextColor?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  bgColor = '#27ADE4',
  onClick,
  fontSize,
  px,
  py,
  variant = 'solid',
  hoverBgColor = 'black',
  hoverTextColor = 'white',
}) => {
  const isOutline = variant === 'outline';
  return (
    <ChakraButton
      variant={variant}
      onClick={onClick}
      bgColor={isOutline ? 'none' : bgColor}
      _hover={
        isOutline
          ? undefined
          : {
              bgColor: hoverBgColor,
              color: hoverTextColor,
            }
      }
      borderRadius='full'
      width='fit-content'
      color={!isOutline ? 'white' : 'default'}
      fontSize={
        fontSize ?? {
          base: '16px',
          sm: '20px',
          md: '24px',
        }
      }
      px={
        px ?? {
          base: '14px',
          md: '22px',
        }
      }
      py={
        py ?? {
          base: '8px',
          md: '28px',
        }
      }
      zIndex={1}
    >
      {text}
    </ChakraButton>
  );
};

export default Button;
