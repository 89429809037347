import { Box, Button, Center, HStack, Heading, Image, Show, Spacer, VStack, useColorModeValue } from '@chakra-ui/react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import type { Country } from '@/models/country';
import { CountryListGrid } from '../components';
import bannerDark from '../assets/logos/bannerDark.svg';
import bannerLight from '../assets/logos/bannerLight.svg';

export default function ListPage() {
  const bannerSrc = useColorModeValue(bannerLight, bannerDark);
  const params = new URLSearchParams(window.location.search);
  const name = params.get('name');
  const listString = params.get('list');
  if (!!name && !!listString) {
    const countryList = listString.split(',') as Country[];
    try {
      if (countryList.length === 10) {
        const titleArea = (
          <Box height='min-content'>
            <Image maxW={'20rem'} mb={4} src={bannerSrc} width='full' />
            <Heading textAlign='center' fontSize={26}>
              {`${name}'s List`}
            </Heading>
          </Box>
        );

        const playButton = (
          <Link to='../'>
            <Button colorScheme={'green'}>Play Now!</Button>
          </Link>
        );

        return (
          <Center width='100vw' height={'100vh'} overflow='hidden' p={8}>
            <Show above='md'>
              <HStack spacing={'4rem'} align='center' justify={'center'}>
                <Spacer />
                <VStack height='full' spacing={8}>
                  {titleArea}
                  {playButton}
                </VStack>
                <CountryListGrid countryList={countryList} svg />
                <Spacer />
              </HStack>
            </Show>
            <Show below='md'>
              <VStack spacing={8}>
                {titleArea}
                <CountryListGrid countryList={countryList} svg />
                {playButton}
              </VStack>
            </Show>
          </Center>
        );
      } else return <Navigate replace to='../' />;
    } catch {
      return <Navigate replace to='../' />;
    }
  } else return <Navigate replace to='../' />;
}
