import { useContext } from 'react';
import { Navigate } from 'react-router';
import { Center, HStack, IconButton, Image, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { Link, useSearchParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { AuthCards, FirebaseContext } from '@/components';
import bannerLight from '../assets/logos/bannerLight.svg';
import bannerDark from '../assets/logos/bannerDark.svg';

export default function LogInPage() {
  const ctx = useContext(FirebaseContext);
  const [searchParams] = useSearchParams();
  const heroSrc = useColorModeValue(bannerLight, bannerDark);

  if (ctx.user) {
    if (searchParams.get('redirect_to')) {
      return <Navigate to={`${searchParams.get('redirect_to')}`} relative='path' />;
    }
    return <Navigate to='/profile' relative='path' />;
  }

  return (
    <Center width='100vw' height='100vh'>
      <Link to='/home' relative='path'>
        <IconButton
          position='absolute'
          top={8}
          left={8}
          aria-label='back'
          icon={
            <HStack p={4}>
              <ArrowBackIcon />
              <Text>Return to home page </Text>
            </HStack>
          }
        />
      </Link>
      <VStack gap={8}>
        <Image src={heroSrc} height={12} />
        <VStack p={8} border={'1px solid gray'} borderRadius={8}>
          <AuthCards initialMode={'log-in'} />
        </VStack>
      </VStack>
    </Center>
  );
}
