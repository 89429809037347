import { useState } from 'react';
import { LogInCard } from './log-in';
import { ForgotPasswordCard } from './forgot-password';
import { SignUpCard } from './sign-up';
import { BaseAuthCard } from './base-card';
import { Heading } from '@chakra-ui/react';

export type AuthCardsMode = 'log-in' | 'sign-up' | 'forgot-password';

export function AuthCards(props: { initialMode: AuthCardsMode }) {
  const [mode, setMode] = useState<AuthCardsMode>(props.initialMode);

  const AuthCardComponents: { [key in AuthCardsMode]: JSX.Element } = {
    'log-in': <LogInCard triggerSignup={() => setMode('sign-up')} triggerForgot={() => setMode('forgot-password')} />,
    'sign-up': <SignUpCard triggerLogin={() => setMode('log-in')} />,
    'forgot-password': <ForgotPasswordCard triggerSignup={() => setMode('sign-up')} />,
  };

  const isWebview = () => {
    if (typeof window === 'undefined') {
      return false;
    }

    const navigator = window.navigator;

    const standalone = (navigator as any).standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    return ios ? !standalone && !safari : /\bwv\b/.test(userAgent);
  };

  if (!isWebview()) {
    return <>{AuthCardComponents[mode] || 'Invalid mode'}</>;
  }

  return (
    <BaseAuthCard>
      <Heading fontSize={24}>
        For the best experience, please switch to using your main browser and go to https://tenteams.co.uk
      </Heading>
    </BaseAuthCard>
  );
}
