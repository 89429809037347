import {
  collection,
  deleteDoc,
  doc,
  type Firestore,
  orderBy,
  type Query,
  query,
  type QueryConstraint,
  type Timestamp,
  updateDoc,
} from 'firebase/firestore';

export interface leagueCreationDetails {
  name: string;
}

export default interface League extends leagueCreationDetails {
  adminUID: string;
  authCode: string;
  memberUIDs: string[];
  ranksLastUpdated: Timestamp;
}

export interface joinLeagueResponse {
  uid: string;
  name: string;
  alreadyMember: boolean;
}

export const defaultEntryFee = 5;

export async function updateLeague(db: Firestore, leagueID: string, data: Partial<League>): Promise<void> {
  return await updateDoc(doc(db, 'leagues', leagueID), data);
}

export async function deleteLeague(db: Firestore, leagueID: string): Promise<void> {
  return await deleteDoc(doc(db, 'leagues', leagueID));
}

export function queryLeagueMembers(db: Firestore, leagueID: string, ...queries: QueryConstraint[]): Query {
  return query(
    collection(db, 'leagues', leagueID, 'members'),
    orderBy('spectator', 'asc'),
    orderBy('currentRank', 'asc'),
    orderBy('joinedLeague', 'asc'),
    ...queries,
  );
}

export function queryLeagues(db: Firestore, ...queries: QueryConstraint[]): Query {
  return query(collection(db, 'leagues'), ...queries);
}
