import type React from 'react';
import {
  Button as CButton,
  Spacer as ChakraSpacer,
  Heading,
  VStack,
  Text,
  HStack,
  Flex,
  Divider,
  Box,
} from '@chakra-ui/react';
import { FaChevronCircleUp, FaChevronCircleDown } from 'react-icons/fa';
import { BsDashCircleFill } from 'react-icons/bs';
import { ordinalSuffix } from '../../helpers';
import PlaceTeamsSection from './PlaceTeamsSection';
import { HomeHeading } from './HomeHeading';

type JoinLeagueSectionProps = {
  onClickCTA: () => void;
};

const JoinLeagueSection: React.FC<JoinLeagueSectionProps> = (props) => {
  return (
    <VStack py='48px' bgColor='#F6F7F9' borderRadius='8px' justifyContent='center' width='full'>
      <PlaceTeamsSection />
      <Box
        px={{
          base: '1em',
          sm: '2em',
          md: '24px',
        }}
        maxW='8xl'
        m='auto'
      >
        <Divider my={4} maxW='container.lg' borderColor='gray.400' />
        <HeadingTexts />
        <LeagueTables />
        <Spacer />
        <CreateListCTA ctaOnClick={props.onClickCTA} />
      </Box>
    </VStack>
  );
};

export default JoinLeagueSection;

const HeadingTexts = () => (
  <VStack gap={0} pb={4}>
    <HomeHeading
      fontWeight={700}
      w={{
        base: '70%',
        md: '100%',
        lg: '100%',
        xl: '100%',
      }}
    >
      3. Join a League. Create Your Own.
    </HomeHeading>
    <Text
      textAlign='center'
      fontFamily='Montserrat'
      opacity={0.6}
      fontWeight={400}
      fontSize={{
        base: '16px',
        md: '18px',
        lg: '24px',
        xl: '28px',
      }}
      w={{
        base: '70%',
        lg: '100%',
        xl: '100%',
      }}
    >
      Compete with Friends, Family and Colleagues
    </Text>
  </VStack>
);

interface LeagueTableItemProps {
  rank: number;
  name: string;
  trend: 'up' | 'down' | 'same';
  points?: number;
}

const LeagueTableItem: React.FC<LeagueTableItemProps> = ({ rank, name, trend, points }) => {
  return (
    <HStack
      justifyContent='space-between'
      w='100%'
      py={{ base: '8px', md: points ? '16px' : '24px' }}
      borderBottomWidth='1px'
    >
      <HStack gap='1em'>
        {points && (
          <Text minWidth={'max-content'}>
            {rank}
            <span style={{ display: 'inline-block', fontSize: '10px' }}>{ordinalSuffix(rank)}</span>
          </Text>
        )}
        <Text
          fontSize={{
            base: '12px',
            md: '16px',
          }}
        >
          {name}
        </Text>
      </HStack>
      <HStack gap='1em'>
        {points && (
          <Text
            fontSize={{
              base: '10px',
              md: '14px',
            }}
            bgColor='#e2e3e5'
            px={{
              base: '12px',
              md: '14px',
            }}
            py='8px'
            borderRadius='full'
          >
            {points} points
          </Text>
        )}
        {!points && (
          <Text>
            {rank}
            <span style={{ display: 'inline-block', fontSize: '10px' }}>{ordinalSuffix(rank)}</span>
          </Text>
        )}
        {trend === 'up' && <FaChevronCircleUp size={20} color='green' />}
        {trend === 'down' && <FaChevronCircleDown size={20} color='red' />}
        {trend === 'same' && <BsDashCircleFill size={20} color='black' />}
      </HStack>
    </HStack>
  );
};

const LeagueTable: React.FC<{
  title: string;
  data: LeagueTableItemProps[];
}> = ({ title, data }) => {
  return (
    <VStack w='100%'>
      <Text fontFamily='montserrat' fontWeight={600} fontSize={24}>
        {title}
      </Text>
      <VStack
        justifyContent='space-between'
        w='100%'
        borderWidth='1px'
        borderRadius='16px'
        px={{
          base: '16px',
          md: '24px',
        }}
      >
        {data.map((item, idx) => (
          <LeagueTableItem key={idx} {...item} />
        ))}
      </VStack>
    </VStack>
  );
};

const LeagueTables = () => {
  return (
    <Flex
      flexDir={{
        base: 'column',
        md: 'row',
      }}
      maxW='container.lg'
      w='100%'
      gap='2em'
    >
      <LeagueTable title='Global League' data={globalLeagueData} />
      <LeagueTable title='Leagues' data={leagueData} />
    </Flex>
  );
};

const globalLeagueData: LeagueTableItemProps[] = [
  { rank: 1, name: 'James', trend: 'down', points: 160 },
  { rank: 2, name: 'Amanda', trend: 'up', points: 149 },
  { rank: 3, name: 'William', trend: 'same', points: 144 },
  { rank: 4, name: 'Mia', trend: 'down', points: 121 },
];
const leagueData: LeagueTableItemProps[] = [
  { rank: 8218, name: 'Global League', trend: 'up' },
  { rank: 2, name: 'The Smiths League', trend: 'up' },
  { rank: 3, name: 'Office League', trend: 'down' },
  { rank: 4, name: 'Michaels League', trend: 'same' },
];

const Spacer = () => (
  <ChakraSpacer
    mt={{
      base: '2rem',
      md: '3rem',
    }}
  />
);

const CreateListCTA = (props: { ctaOnClick: () => void }) => (
  <VStack gap={6}>
    <Heading
      fontStyle='italic'
      textAlign='center'
      color='black'
      fontWeight={700}
      w={{
        base: '80%',
        md: '100%',
      }}
      fontSize={{
        base: '24px',
        md: '24px',
        lg: '34px',
        xl: '48px',
      }}
    >
      Every Game Matters. Create Your List Now!
    </Heading>
    <CButton
      bgColor='#27ADE4'
      borderRadius='full'
      width='fit-content'
      color='white'
      fontSize={{
        base: '16px',
        sm: '20px',
        md: '24px',
      }}
      px={{
        base: '22px',
        sm: '32px',
        md: '48px',
        lg: '64px',
        xl: '80px',
      }}
      py={{
        base: '8px',
        sm: '12px',
        md: '16px',
        lg: '24px',
        xl: '32px',
      }}
      zIndex={1}
      onClick={props.ctaOnClick}
    >
      Create Your List
    </CButton>
  </VStack>
);
