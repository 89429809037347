import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import '@fontsource/open-sans/800.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/poppins/500.css';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { captureConsoleIntegration, init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseProvider } from './components/FirebaseContext';
import theme from './theme';
import { AuthModalProvider } from './hooks/useAuthModal';

// Only add Sentry if we're not working on a local dev server
if (import.meta.env.VITE_FIREBASE_ENV !== 'development') {
  const integrations = [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ];

  if (import.meta.env.PROD) {
    integrations.push(
      captureConsoleIntegration({
        levels: ['warn', 'error', 'debug', 'assert'],
      }),
    );
  }

  init({
    environment: import.meta.env.VITE_FIREBASE_ENV === 'development' ? 'development' : 'production',
    dsn: 'https://21b28e7d6d764039aebb8710314d46db@o4505522567315456.ingest.sentry.io/4505522574393344',
    integrations: integrations,
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of the transactions.
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <FirebaseProvider>
          <AuthModalProvider>
            <App />
          </AuthModalProvider>
        </FirebaseProvider>
      </ChakraProvider>
    </BrowserRouter>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
