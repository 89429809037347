import { Heading, type HeadingProps } from '@chakra-ui/react';

export const HomeHeading = ({ children, ...props }: { children: React.ReactNode } & HeadingProps) => {
  return (
    <Heading
      fontStyle='italic'
      textAlign='center'
      color='black'
      width={{
        base: '80%',
        md: '70%',
        lg: '70%',
        xl: '100%',
      }}
      fontSize={{
        base: '20px',
        md: '24px',
        lg: '34px',
        xl: '58px',
      }}
      {...props}
    >
      {children}
    </Heading>
  );
};
