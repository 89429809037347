import { Box, type ChakraProps, useBreakpointValue } from '@chakra-ui/react';
import type { ReactNode } from 'react';

export function BaseAuthCard({ children }: { children: ReactNode }) {
  const mediaProps = useBreakpointValue<Partial<ChakraProps>>({
    base: { height: 'unset', overflowY: 'unset' },
    lg: { height: 'full', overflowY: 'auto' },
  });

  return (
    <Box width='100%' px={2} borderRadius={8} {...mediaProps}>
      {children}
    </Box>
  );
}
