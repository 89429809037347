import {
  Center,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Flex,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Spacer,
  Tooltip,
  Icon,
  type ChakraProps,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { FaTrophy } from 'react-icons/fa';
import { type PropsWithChildren, useRef } from 'react';
import { countriesInfo, Country, countryCategory, tournamentCountriesInfo } from '@/models/country';
import type { gameResult } from '@/models/gameUpdate';
import bannerDark from '../../assets/logos/bannerDark.svg';
import bannerLight from '../../assets/logos/bannerLight.svg';
import { Flag } from '../Flag';

function Card(props: ChakraProps & PropsWithChildren) {
  const cardBackground = useColorModeValue('gray.50', 'gray.700');
  const ref = useRef(null);
  return <Box ref={ref} background={cardBackground} width='full' padding={8} borderRadius={16} {...props} />;
}

function TeamHeader(props: {
  country: Country;
}) {
  return (
    <Flex gap='4' py={2}>
      <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
        <Flag country={props.country} w={36} h={36} borderRadius='3px' svg />

        <Box textAlign='start'>
          <Heading size='lg'>{countriesInfo[props.country].name}</Heading>
          {STATIC_EURO_WINS.map((year, idx) => (
            <Tooltip key={idx} label={year} placement='top' hasArrow arrowSize={15}>
              <StarIcon color='yellow.400' boxSize={6} />
            </Tooltip>
          ))}
          <Box pb={2} />
          {STATIC_WORLD_CUP_WINS.map((year, idx) => (
            <Tooltip key={idx} label={year} placement='top' shouldWrapChildren={true} hasArrow arrowSize={15}>
              <span>
                <Icon as={FaTrophy} color='yellow.400' boxSize={6} />
              </span>
            </Tooltip>
          ))}
          <Text>{countryCategory[tournamentCountriesInfo[props.country].category]}</Text>
          <Text>Chosen by: 72% of users</Text>
        </Box>
      </Flex>
    </Flex>
  );
}

function TeamResults(props: {
  gameResults?: gameResult[];
  country: Country;
}) {
  const titlePadX = useBreakpointValue({ base: 2, md: 0 });
  return (
    <Card border={'1px solid'} px={2} py={2}>
      <Heading size='md' textAlign={'start'} pl={titlePadX}>
        This Tournament
      </Heading>
      <Spacer h={5} />
      <TableContainer pb={4} overflowX='hidden'>
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th px={2}>No.</Th>
              <Th>Opponent</Th>
              <Th>Score</Th>
              <Th>Points</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.gameResults?.map((result, i) => (
              <Tr key={i}>
                <Td px={2}>{props.gameResults!.length - i}</Td>
                <Td>
                  {result.countryA === props.country
                    ? `${countriesInfo[result.countryB].name} (H)`
                    : `${countriesInfo[result.countryA].name} (A)`}
                </Td>
                <Td>
                  {result.goalsA}{' '}
                  {result.wentToPens && (
                    <Text as='span' color='gray'>
                      ({result.penaltiesA})
                    </Text>
                  )}{' '}
                  -{' '}
                  {result.wentToPens && (
                    <Text as='span' color='gray'>
                      ({result.penaltiesB})
                    </Text>
                  )}{' '}
                  {result.goalsB}
                </Td>
                <Td>{result.countryA === props.country ? result.pointsA : result.pointsB}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}

interface TablePosition {
  country: Country;
  mp: number;
  w: number;
  d: number;
  l: number;
  gf: number;
  ga: number;
  gd: number;
  pts: number;
}

function NarrowTh(props: { children: React.ReactNode }) {
  return <Th px={2}>{props.children}</Th>;
}

function NarrowTd(props: { children: React.ReactNode }) {
  return <Td px={2}>{props.children}</Td>;
}

function TeamQualifyingResults(props: {
  tablePositions: TablePosition[];
  country: Country;
}) {
  const titlePadX = useBreakpointValue({ base: 2, md: 0 });
  return (
    <Card border={'1px solid'} px={2} py={2}>
      <Heading size='md' textAlign={'start'} pl={titlePadX}>
        Qualifying History
      </Heading>
      <Spacer h={5} />
      <TableContainer pb={4} overflowX='hidden'>
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th px={2}>Team</Th>
              <Th>MP</Th>
              <NarrowTh>W</NarrowTh>
              <NarrowTh>D</NarrowTh>
              <NarrowTh>L</NarrowTh>
              <NarrowTh>GF</NarrowTh>
              <NarrowTh>GA</NarrowTh>
              <NarrowTh>GD</NarrowTh>
              <Th>PTS</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.tablePositions.map((teamRow, i) => (
              <Tr key={i}>
                <Td px={2}>
                  <Flex align={'center'} gap={2}>
                    {i + 1}
                    <Flag country={teamRow.country} w={6} h={6} borderRadius='3px' svg />
                    {countriesInfo[teamRow.country].name}
                  </Flex>
                </Td>
                <Td>{teamRow.mp}</Td>
                <NarrowTd>{teamRow.w}</NarrowTd>
                <NarrowTd>{teamRow.d}</NarrowTd>
                <NarrowTd>{teamRow.l}</NarrowTd>
                <NarrowTd>{teamRow.gf}</NarrowTd>
                <NarrowTd>{teamRow.ga}</NarrowTd>
                <NarrowTd>{teamRow.gd}</NarrowTd>
                <Td>{teamRow.pts}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}

function TeamStats(props: {
  gameResults?: gameResult[];
  countryRank: number;
  country: Country;
}) {
  return (
    <StatGroup pt={2}>
      <Stat>
        <StatLabel>Total Points</StatLabel>
        <StatNumber>
          {props.gameResults?.reduce(
            (runningSum, x) => runningSum + (x.countryA === props.country ? x.pointsA : x.pointsB),
            0,
          )}
        </StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Teams Selected By</StatLabel>
        <StatNumber>TBD</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Multiplier</StatLabel>
        <StatNumber>x{11 - props.countryRank}</StatNumber>
      </Stat>
    </StatGroup>
  );
}

function TeamGroup() {
  const titlePadX = useBreakpointValue({ base: 2, md: 0 });
  return (
    <Card border={'1px solid'} px={4} py={2}>
      <Heading size='md' textAlign={'start'} pl={titlePadX}>
        Group A
      </Heading>
      <Spacer h={3} />
      <VStack alignItems={'start'} textAlign={'start'}>
        {STATIC_GROUP_TEAMS.map((cty, idx) => (
          <Flex key={idx} gap={2}>
            <Flag country={cty} w={6} h={6} borderRadius='3px' svg />
            <Text key={idx}>{countriesInfo[cty].name}</Text>
          </Flex>
        ))}
      </VStack>
    </Card>
  );
}

function TeamFixtures() {
  const titlePadX = useBreakpointValue({ base: 2, md: 0 });
  return (
    <Card border={'1px solid'} px={3} py={2}>
      <Heading size='md' textAlign={'start'} pl={titlePadX}>
        Upcoming Fixtures
      </Heading>
      <Spacer h={3} />
      <VStack alignItems={'start'} textAlign={'start'} gap={3}>
        {STATIC_FIXTURES.map((fixture, idx) => (
          <Flex key={idx} w='full' justify='space-between' gap={3}>
            <Center height={'full'} minH={6} minW={6} flex='1'>
              <Flag country={fixture.homeTeam} w={6} h={6} borderRadius='3px' />
            </Center>
            <Center height={'full'} mr={'0.18rem'} flex='3'>
              <Text>{countriesInfo[fixture.homeTeam].name}</Text>
            </Center>
            <Center height={'full'} justifySelf='center' flex='1'>
              <Text fontWeight={'bold'}>vs</Text>
            </Center>
            <Center height={'full'} mr={'0.18rem'} flex='3'>
              <Text>{countriesInfo[fixture.awayTeam].name}</Text>
            </Center>
            <Center height={'full'} minH={6} minW={6} flex='1'>
              <Flag country={fixture.awayTeam} w={6} h={6} borderRadius='3px' />
            </Center>
          </Flex>
        ))}
      </VStack>
    </Card>
  );
}

function TeamSquad() {
  const titlePadX = useBreakpointValue({ base: 2, md: 0 });
  return (
    <Card border={'1px solid'} px={3} py={2}>
      <Heading size='md' textAlign={'start'} pl={titlePadX}>
        Squad
      </Heading>
      <Spacer h={3} />
      <Box textAlign={'start'}>
        <Heading size='sm'>Goalkeepers</Heading>
        {STATIC_SQUAD_GKS.map((player, idx) => (
          <Text key={idx}>- {player}</Text>
        ))}
        <Spacer h={2} />
        <Heading size='sm'>Defenders</Heading>
        {STATIC_SQUAD_DEF.map((player, idx) => (
          <Text key={idx}>- {player}</Text>
        ))}
        <Spacer h={2} />
        <Heading size='sm'>Midfielders</Heading>
        {STATIC_SQUAD_MID.map((player, idx) => (
          <Text key={idx}>- {player}</Text>
        ))}
        <Spacer h={2} />
        <Heading size='sm'>Forwards</Heading>
        {STATIC_SQUAD_FWD.map((player, idx) => (
          <Text key={idx}>- {player}</Text>
        ))}
      </Box>
    </Card>
  );
}

export function CountryStatModal(props: {
  isOpen: boolean;
  onClose: () => void;
  prompt?: boolean;
  onNext?: () => void;
  country: Country;
  countryRank: number;
  gameResults?: gameResult[];
  //  TODO: Add view with no game results yet.
}) {
  const modalMaxW = useBreakpointValue({
    base: '80vw',
    md: '80vw',
    lg: '60vw',
  });

  const heroSrc = useColorModeValue(bannerLight, bannerDark);
  const modalBodyPadX = useBreakpointValue({ base: 2, md: 4 });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} scrollBehavior='outside'>
      <ModalOverlay />
      <ModalContent maxW={modalMaxW} borderRadius='12px'>
        <ModalHeader>
          <VStack align='start'>
            <Image height={5} mt={1} src={heroSrc} />
          </VStack>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} />
        <ModalBody px={modalBodyPadX}>
          <TeamHeader country={props.country} />
          <TeamStats gameResults={props.gameResults} country={props.country} countryRank={props.countryRank} />
          <Spacer h={5} />

          <Flex gap={4}>
            <Box flex={2}>
              <Flex gap={4}>
                <TeamGroup />
                <TeamFixtures />
              </Flex>
              <Spacer h={4} />
              <TeamQualifyingResults tablePositions={STATIC_TABLE_POSITIONS} country={props.country} />
              <Spacer h={4} />
              <TeamResults gameResults={props.gameResults} country={props.country} />
            </Box>
            <Box width={48}>
              <TeamSquad />
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const STATIC_SQUAD_GKS = ['M. Neuer', 'K. Trapp', 'O. Baumann'];
const STATIC_SQUAD_DEF = ['A. Rüdiger', 'J. Tah', 'M. Hummels', 'N. Süle', 'B. Henrichs', 'D. Raum'];
const STATIC_SQUAD_MID = [
  'G. Prömel',
  'J. Kimmich',
  'K. Havertz',
  'L. Goretzka',
  'J. Brandt',
  'P. Groß',
  'F. Wirtz',
  'J. Hoffman',
  'L. Sané',
  'Í. Gündoğan (C)',
  'R. Andrich',
];
const STATIC_SQUAD_FWD = ['N. Füllkrug', 'S. Gnabry', 'T. Müller', 'M. Ducksch'];

const STATIC_GROUP_TEAMS = [Country.GB_ENG, Country.DK, Country.SE, Country.NL];
const STATIC_EURO_WINS = [1995, 2001, 2010, 2020];
const STATIC_WORLD_CUP_WINS = [1995, 2000, 2023];

const STATIC_FIXTURES = [
  {
    homeTeam: Country.DK,
    awayTeam: Country.GB_ENG,
  },
  {
    homeTeam: Country.SE,
    awayTeam: Country.GB_ENG,
  },
  {
    homeTeam: Country.GB_ENG,
    awayTeam: Country.NL,
  },
];

const STATIC_TABLE_POSITIONS: TablePosition[] = [
  {
    country: Country.GB_ENG,
    mp: 8,
    w: 6,
    d: 2,
    l: 0,
    gf: 22,
    ga: 4,
    gd: 18,
    pts: 20,
  },
  {
    country: Country.IT,
    mp: 8,
    w: 4,
    d: 2,
    l: 2,
    gf: 16,
    ga: 9,
    gd: 7,
    pts: 14,
  },
  {
    country: Country.NG,
    mp: 8,
    w: 4,
    d: 2,
    l: 2,
    gf: 11,
    ga: 8,
    gd: 3,
    pts: 14,
  },
  {
    country: Country.PH,
    mp: 8,
    w: 2,
    d: 2,
    l: 4,
    gf: 10,
    ga: 20,
    gd: -10,
    pts: 8,
  },
  {
    country: Country.ZA,
    mp: 8,
    w: 0,
    d: 0,
    l: 8,
    gf: 2,
    ga: 20,
    gd: -18,
    pts: 0,
  },
];
