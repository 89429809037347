import { type SetStateAction, type Dispatch, useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  Image,
  useColorModeValue,
  Stack,
  useColorMode,
  Show,
  useBreakpointValue,
  type UseDisclosureReturn,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import smolLight from '@/assets/logos/smolLight.svg';
import smolDark from '@/assets/logos/smolDark.svg';
import bannerDark from '@/assets/logos/bannerDark.svg';
import bannerLight from '@/assets/logos/bannerLight.svg';
import { FirebaseContext } from '../FirebaseContext';
import type { AuthCardsMode } from '../AuthCards';

export function Nav(props: {
  disclosure: UseDisclosureReturn;
  setAuthModalMode: Dispatch<SetStateAction<AuthCardsMode>>;
}) {
  const ctx = useContext(FirebaseContext);
  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();
  const smolLogoSrc = useColorModeValue(smolLight, smolDark);
  const heroSrc = useColorModeValue(bannerLight, bannerDark);
  const navPaddingL = useBreakpointValue({ base: 6, lg: 8 });
  const navPaddingR = useBreakpointValue({ base: 6, lg: 8 });

  const goToGame = () => {
    navigate('../profile');
  };

  return (
    <>
      <Box
        maxW={'1444px'}
        width='full'
        position='relative'
        pt={2}
        pb={1}
        pl={navPaddingL}
        pr={navPaddingR}
        bgColor={useColorModeValue('white', 'bgBlue')}
        zIndex={1}
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Image alignSelf='center' height={10} mt={1} src={useBreakpointValue({ base: smolLogoSrc, lg: heroSrc })} />

          <Flex alignItems={'center'}>
            <Stack direction={'row'}>
              <Show above='md'>
                <Button onClick={toggleColorMode}>{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}</Button>
              </Show>
              {ctx.user ? (
                <Button rightIcon={<ArrowForwardIcon />} colorScheme='green' onClick={goToGame}>
                  Go to game
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      props.setAuthModalMode('sign-up');
                      props.disclosure.onOpen();
                    }}
                  >
                    Sign Up
                  </Button>
                  <Button
                    onClick={() => {
                      props.setAuthModalMode('log-in');
                      props.disclosure.onOpen();
                    }}
                  >
                    Log In
                  </Button>
                </>
              )}
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
