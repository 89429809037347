import { type ChakraProps, Image } from '@chakra-ui/react';
import { Country as countryCode } from '@/models/country';
import QA from '../assets/flags/QA.png';
import GB_ENG from '../assets/flags/GB_ENG.png';
import AR from '../assets/flags/AR.png';
import FR from '../assets/flags/FR.png';
import ES from '../assets/flags/ES.png';
import BE from '../assets/flags/BE.png';
import BR from '../assets/flags/BR.png';
import PT from '../assets/flags/PT.png';
import NL from '../assets/flags/NL.png';
import US from '../assets/flags/US.png';
import MX from '../assets/flags/MX.png';
import DK from '../assets/flags/DK.png';
import SN from '../assets/flags/SN.png';
import IR from '../assets/flags/IR.png';
import PL from '../assets/flags/PL.png';
import TN from '../assets/flags/TN.png';
import DE from '../assets/flags/DE.png';
import HR from '../assets/flags/HR.png';
import CH from '../assets/flags/CH.png';
import UY from '../assets/flags/UY.png';
import JP from '../assets/flags/JP.png';
import MA from '../assets/flags/MA.png';
import RS from '../assets/flags/RS.png';
import KR from '../assets/flags/KR.png';
import EC from '../assets/flags/EC.png';
import GB_WLS from '../assets/flags/GB_WLS.png';
import SA from '../assets/flags/SA.png';
import AU from '../assets/flags/AU.png';
import CR from '../assets/flags/CR.png';
import CA from '../assets/flags/CA.png';
import CM from '../assets/flags/CM.png';
import GH from '../assets/flags/GH.png';
import NZ from '../assets/flags/NZ.png';
import SE from '../assets/flags/SE.png';
import NO from '../assets/flags/NO.png';
import IT from '../assets/flags/IT.png';
import CN from '../assets/flags/CN.png';
import IE from '../assets/flags/IE.png';
import CO from '../assets/flags/CO.png';
import VN from '../assets/flags/VN.png';
import JM from '../assets/flags/JM.png';
import NG from '../assets/flags/NG.png';
import PH from '../assets/flags/PH.png';
import ZA from '../assets/flags/ZA.png';
import ZM from '../assets/flags/ZM.png';
import HT from '../assets/flags/HT.png';
import PA from '../assets/flags/PA.png';
import HU from '../assets/flags/HU.png';
import TR from '../assets/flags/TR.png';
import RO from '../assets/flags/RO.png';
import AL from '../assets/flags/AL.png';
import AT from '../assets/flags/AT.png';
import GB_SCT from '../assets/flags/GB_SCT.png';
import SI from '../assets/flags/SI.png';
import SK from '../assets/flags/SK.png';
import CZ from '../assets/flags/CZ.png';
import UA from '../assets/flags/UA.png';
import GE from '../assets/flags/GE.png';
import TBD from '../assets/flags/XX.png';
import SVG_QA from '../assets/flags/QA.svg';
import SVG_GB_ENG from '../assets/flags/GB_ENG.svg';
import SVG_AR from '../assets/flags/AR.svg';
import SVG_FR from '../assets/flags/FR.svg';
import SVG_ES from '../assets/flags/ES.svg';
import SVG_BE from '../assets/flags/BE.svg';
import SVG_BR from '../assets/flags/BR.svg';
import SVG_PT from '../assets/flags/PT.svg';
import SVG_NL from '../assets/flags/NL.svg';
import SVG_US from '../assets/flags/US.svg';
import SVG_MX from '../assets/flags/MX.svg';
import SVG_DK from '../assets/flags/DK.svg';
import SVG_SN from '../assets/flags/SN.svg';
import SVG_IR from '../assets/flags/IR.svg';
import SVG_PL from '../assets/flags/PL.svg';
import SVG_TN from '../assets/flags/TN.svg';
import SVG_DE from '../assets/flags/DE.svg';
import SVG_HR from '../assets/flags/HR.svg';
import SVG_CH from '../assets/flags/CH.svg';
import SVG_UY from '../assets/flags/UY.svg';
import SVG_JP from '../assets/flags/JP.svg';
import SVG_MA from '../assets/flags/MA.svg';
import SVG_RS from '../assets/flags/RS.svg';
import SVG_KR from '../assets/flags/KR.svg';
import SVG_EC from '../assets/flags/EC.svg';
import SVG_GB_WLS from '../assets/flags/GB_WLS.svg';
import SVG_SA from '../assets/flags/SA.svg';
import SVG_AU from '../assets/flags/AU.svg';
import SVG_CR from '../assets/flags/CR.svg';
import SVG_CA from '../assets/flags/CA.svg';
import SVG_CM from '../assets/flags/CM.svg';
import SVG_GH from '../assets/flags/GH.svg';
import SVG_NZ from '../assets/flags/NZ.svg';
import SVG_SE from '../assets/flags/SE.svg';
import SVG_NO from '../assets/flags/NO.svg';
import SVG_IT from '../assets/flags/IT.svg';
import SVG_CN from '../assets/flags/CN.svg';
import SVG_IE from '../assets/flags/IE.svg';
import SVG_CO from '../assets/flags/CO.svg';
import SVG_VN from '../assets/flags/VN.svg';
import SVG_JM from '../assets/flags/JM.svg';
import SVG_NG from '../assets/flags/NG.svg';
import SVG_PH from '../assets/flags/PH.svg';
import SVG_ZA from '../assets/flags/ZA.svg';
import SVG_ZM from '../assets/flags/ZM.svg';
import SVG_HT from '../assets/flags/HT.svg';
import SVG_PA from '../assets/flags/PA.svg';
import SVG_HU from '../assets/flags/HU.svg';
import SVG_TR from '../assets/flags/TR.svg';
import SVG_RO from '../assets/flags/RO.svg';
import SVG_AL from '../assets/flags/AL.svg';
import SVG_AT from '../assets/flags/AT.svg';
import SVG_GB_SCT from '../assets/flags/GB_SCT.svg';
import SVG_SI from '../assets/flags/SI.svg';
import SVG_SK from '../assets/flags/SK.svg';
import SVG_CZ from '../assets/flags/CZ.svg';
import SVG_UA from '../assets/flags/UA.svg';
import SVG_GE from '../assets/flags/GE.svg';
import SVG_TBD from '../assets/flags/XX.svg';

// flags from https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/

const flags: { [key in countryCode]: string } = {
  [countryCode.QA]: QA,
  [countryCode.GB_ENG]: GB_ENG,
  [countryCode.AR]: AR,
  [countryCode.FR]: FR,
  [countryCode.ES]: ES,
  [countryCode.BE]: BE,
  [countryCode.BR]: BR,
  [countryCode.PT]: PT,
  [countryCode.NL]: NL,
  [countryCode.US]: US,
  [countryCode.MX]: MX,
  [countryCode.DK]: DK,
  [countryCode.SN]: SN,
  [countryCode.IR]: IR,
  [countryCode.PL]: PL,
  [countryCode.TN]: TN,
  [countryCode.DE]: DE,
  [countryCode.HR]: HR,
  [countryCode.CH]: CH,
  [countryCode.UY]: UY,
  [countryCode.JP]: JP,
  [countryCode.MA]: MA,
  [countryCode.RS]: RS,
  [countryCode.KR]: KR,
  [countryCode.EC]: EC,
  [countryCode.GB_WLS]: GB_WLS,
  [countryCode.SA]: SA,
  [countryCode.AU]: AU,
  [countryCode.CR]: CR,
  [countryCode.CA]: CA,
  [countryCode.CM]: CM,
  [countryCode.GH]: GH,
  [countryCode.NZ]: NZ,
  [countryCode.SE]: SE,
  [countryCode.NO]: NO,
  [countryCode.IT]: IT,
  [countryCode.CN]: CN,
  [countryCode.IE]: IE,
  [countryCode.CO]: CO,
  [countryCode.VN]: VN,
  [countryCode.JM]: JM,
  [countryCode.NG]: NG,
  [countryCode.PH]: PH,
  [countryCode.ZA]: ZA,
  [countryCode.ZM]: ZM,
  [countryCode.HT]: HT,
  [countryCode.PA]: PA,
  [countryCode.HU]: HU,
  [countryCode.TR]: TR,
  [countryCode.RO]: RO,
  [countryCode.AL]: AL,
  [countryCode.AT]: AT,
  [countryCode.GB_SCT]: GB_SCT,
  [countryCode.SI]: SI,
  [countryCode.SK]: SK,
  [countryCode.CZ]: CZ,
  [countryCode.UA]: UA,
  [countryCode.GE]: GE,
  [countryCode.TBD]: TBD,
};

const svgFlags: { [key in countryCode]: string } = {
  [countryCode.QA]: SVG_QA,
  [countryCode.GB_ENG]: SVG_GB_ENG,
  [countryCode.AR]: SVG_AR,
  [countryCode.FR]: SVG_FR,
  [countryCode.ES]: SVG_ES,
  [countryCode.BE]: SVG_BE,
  [countryCode.BR]: SVG_BR,
  [countryCode.PT]: SVG_PT,
  [countryCode.NL]: SVG_NL,
  [countryCode.US]: SVG_US,
  [countryCode.MX]: SVG_MX,
  [countryCode.DK]: SVG_DK,
  [countryCode.SN]: SVG_SN,
  [countryCode.IR]: SVG_IR,
  [countryCode.PL]: SVG_PL,
  [countryCode.TN]: SVG_TN,
  [countryCode.DE]: SVG_DE,
  [countryCode.HR]: SVG_HR,
  [countryCode.CH]: SVG_CH,
  [countryCode.UY]: SVG_UY,
  [countryCode.JP]: SVG_JP,
  [countryCode.MA]: SVG_MA,
  [countryCode.RS]: SVG_RS,
  [countryCode.KR]: SVG_KR,
  [countryCode.EC]: SVG_EC,
  [countryCode.GB_WLS]: SVG_GB_WLS,
  [countryCode.SA]: SVG_SA,
  [countryCode.AU]: SVG_AU,
  [countryCode.CR]: SVG_CR,
  [countryCode.CA]: SVG_CA,
  [countryCode.CM]: SVG_CM,
  [countryCode.GH]: SVG_GH,
  [countryCode.NZ]: SVG_NZ,
  [countryCode.SE]: SVG_SE,
  [countryCode.NO]: SVG_NO,
  [countryCode.IT]: SVG_IT,
  [countryCode.CN]: SVG_CN,
  [countryCode.IE]: SVG_IE,
  [countryCode.CO]: SVG_CO,
  [countryCode.VN]: SVG_VN,
  [countryCode.JM]: SVG_JM,
  [countryCode.NG]: SVG_NG,
  [countryCode.PH]: SVG_PH,
  [countryCode.ZA]: SVG_ZA,
  [countryCode.ZM]: SVG_ZM,
  [countryCode.HT]: SVG_HT,
  [countryCode.PA]: SVG_PA,
  [countryCode.HU]: SVG_HU,
  [countryCode.TR]: SVG_TR,
  [countryCode.RO]: SVG_RO,
  [countryCode.AL]: SVG_AL,
  [countryCode.AT]: SVG_AT,
  [countryCode.GB_SCT]: SVG_GB_SCT,
  [countryCode.SI]: SVG_SI,
  [countryCode.SK]: SVG_SK,
  [countryCode.CZ]: SVG_CZ,
  [countryCode.UA]: SVG_UA,
  [countryCode.GE]: SVG_GE,
  [countryCode.TBD]: SVG_TBD,
};

export function Flag(
  props: {
    country: countryCode;
    hasShadow?: boolean;
    svg?: boolean;
  } & ChakraProps,
) {
  const { country, hasShadow, svg, ...rest } = props;
  return (
    <Image
      boxShadow={hasShadow ? '0px 0px 3px 0.3px #3336' : undefined}
      src={svg ? svgFlags[country] : flags[country]}
      {...rest}
    />
  );
}
