export enum batchEmailSubset {
  all = 0,
  incompleteList = 1,
  completeList = 2,
  admin = 3,
  custom = 4,
}

export default interface batchEmailOptions {
  subset: batchEmailSubset;
  customList: string[] | undefined;
  template: string;
  subject: string;
  personalisation: boolean;
}
