import { Box, Grid, GridItem, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import type React from 'react';
import { Flag } from '../../components';
import { countriesInfo, Country, type CountryCode } from '../../models/country';
import EU_BG from '../../assets/europe.svg';
import DemoTeamList from './DemoTeamList';

const PlaceTeamsSection: React.FC = () => {
  const teams: CountryCode[] = ['FR', 'GB_ENG', 'HU', 'HR', 'UA', 'DE', 'IT', 'TR', 'CZ', 'AL'];

  return (
    <VStack maxW='8xl' m='auto' width='full' justifyContent='center' bgImage={EU_BG} bgSize='contain' bgPos='top'>
      <Heading
        fontStyle='italic'
        textAlign='center'
        color='black'
        mb='2rem'
        width={{
          base: '100%',
          sm: '80%',
          md: '70%',
          lg: '65%',
        }}
        textTransform={{
          base: 'capitalize',
        }}
        fontSize={{
          base: '20px',
          md: '24px',
          lg: '34px',
          xl: '48px',
        }}
        px={{
          base: '1em',
          sm: '2em',
          md: '24px',
        }}
      >
        The Higher You Place Your Teams, The More Points You Get
      </Heading>
      <DemoTeamList withMultipliers />
    </VStack>
  );
};

export default PlaceTeamsSection;
