import { QuestionIcon } from '@chakra-ui/icons';
import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Show,
  Spacer,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
  useBreakpointValue,
  IconButton,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { countryListValid, type Country } from '@/models/country';
import { updateTeamSelectionData, updateUser } from '@/models/user';
import { type Dict, getServerTimestamp, parseNumberInput } from '../../helpers';
import { EditCountryListView, FirebaseContext } from '../';
import { RulesModal } from './RulesModal';
import { ConfirmModal } from './ConfirmModal';
import { type gameUpdate, sentinelType } from '@/models/gameUpdate';
import { logEvent } from 'firebase/analytics';

export function EditCountryListModal(props: {
  isOpen: boolean;
  onClose: () => void;
  getStartingList: () => Country[];
  getStartingGoalsGuess: () => number;
  prompt?: boolean;
  gameUpdates: Dict<gameUpdate>;
}) {
  const startingList = props.getStartingList();
  const [tabIndex, setTabIndex] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const [countryList, setCountryList] = useState<Country[]>(startingList);
  const [goalsGuess, setGoalsGuess] = useState(props.getStartingGoalsGuess());
  const rulesDisclosure = useDisclosure();
  const confirmEditDisclosure = useDisclosure();
  const ctx = useContext(FirebaseContext);

  const tournamentStarted = Object.keys(props.gameUpdates ?? {}).includes(sentinelType.tournamentStarted);
  const latecomersAllowed = Object.keys(props.gameUpdates ?? {}).includes(sentinelType.latecomersAllowed);

  useEffect(() => {
    setCountryList(props.getStartingList());
    setGoalsGuess(props.getStartingGoalsGuess());
  }, [props.isOpen]);

  const listValid = countryListValid(countryList);
  const inputValid = listValid && goalsGuess > 0;

  const modalCentered = useBreakpointValue({ base: undefined, md: true });
  const modalMaxW = useBreakpointValue({
    base: undefined,
    lg: '1444px',
  });

  const titleArea = (
    <>
      <HStack align='flex-start'>
        <Center>
          <Heading fontSize={28}>{startingList.length < 10 ? 'Create' : 'Edit'} Team List</Heading>
          <IconButton
            onClick={rulesDisclosure.onOpen}
            pl={4}
            aria-label='How to play'
            icon={<QuestionIcon fontSize={24} mb={0.5} />}
            isRound
            variant='unstyled'
          />
        </Center>
      </HStack>
      {props.prompt ? <Text>{"You can do this later if you'd prefer to."}</Text> : null}
    </>
  );

  function isFinalTab() {
    return tabIndex === 2;
  }

  async function submitTeam(): Promise<void> {
    setIsBusy(true);
    logEvent(ctx.analytics, 'submit_team', { late_submission: latecomersAllowed });
    await updateTeamSelectionData(ctx.db, ctx.user!.uid, {
      countryList: countryList,
      goalsGuess: goalsGuess,
      spectator: false,
    });
    await updateUser(ctx.db, ctx.user!.uid, {
      requiresShareUpdate: true,
    });
    props.onClose();
    setIsBusy(false);
  }

  async function handleSubmit(): Promise<void> {
    if (!tournamentStarted) {
      await submitTeam();
    } else if (tournamentStarted && latecomersAllowed) {
      if (props.getStartingList().length === 10) {
        confirmEditDisclosure.onOpen();
      } else {
        await submitTeam();
      }
    } else {
      // Do nothing
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} scrollBehavior='inside' isCentered={modalCentered}>
      <ModalOverlay />
      <ModalContent maxW={modalMaxW}>
        <Show above='lg'>
          <ModalHeader>
            <VStack align='stretch'>{titleArea}</VStack>
          </ModalHeader>
          <ModalCloseButton color='black' mt={2} mr={2} />
          <ModalBody overflowY={'scroll'}>
            <EditCountryListView countryList={countryList} setCountryList={setCountryList} />
          </ModalBody>
        </Show>
        <Show below='lg'>
          <ModalHeader>
            <VStack align='stretch'>
              {titleArea}
              <Tabs isFitted index={tabIndex} onChange={setTabIndex}>
                <TabList>
                  <Tab>1. Selection</Tab>
                  <Tab isDisabled={!listValid}>2. Ranking</Tab>
                  <Tab isDisabled={!listValid}>3. Tiebreaker</Tab>
                </TabList>
              </Tabs>
            </VStack>
          </ModalHeader>
          <ModalCloseButton color='black' mt={2} mr={2} />
          <ModalBody>
            <EditCountryListView
              countryList={countryList}
              setCountryList={setCountryList}
              mobileProps={{
                tabIndex: tabIndex,
                setTabIndex: setTabIndex,
                goalsGuess: goalsGuess,
                setGoalsGuess: setGoalsGuess,
              }}
            />
          </ModalBody>
        </Show>
        <ModalFooter>
          <HStack width={'full'} align='end'>
            <Show above='lg'>
              <Collapse in={listValid}>
                <FormControl>
                  <FormLabel>
                    <strong>Tiebreaker:</strong> Goals Guess
                  </FormLabel>
                  <NumberInput
                    min={0}
                    value={goalsGuess}
                    width='min-content'
                    minW={48}
                    onChange={(x) => setGoalsGuess(parseNumberInput(x))}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormHelperText>How many goals in total do you think will be scored in Euro 2024?</FormHelperText>
                </FormControl>
              </Collapse>
            </Show>
            <Spacer />
            <Show below='lg'>{!listValid && <Text alignSelf='center'>You must select 10 Teams!</Text>}</Show>
            {props.prompt ? <Button onClick={props.onClose}>Skip</Button> : null}
            <Show above='lg'>
              <Button
                isLoading={isBusy}
                isDisabled={!inputValid}
                colorScheme='green'
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                Submit
              </Button>
            </Show>
            <Show below='lg'>
              <Button
                isLoading={isBusy}
                isDisabled={isFinalTab() ? !inputValid : !listValid}
                colorScheme='green'
                onClick={async () => {
                  if (isFinalTab()) {
                    await handleSubmit();
                  } else {
                    setTabIndex(tabIndex + 1);
                  }
                }}
              >
                {isFinalTab() ? 'Submit' : 'Next'}
              </Button>
            </Show>
          </HStack>
        </ModalFooter>
      </ModalContent>
      <RulesModal isOpen={rulesDisclosure.isOpen} onClose={rulesDisclosure.onClose} />
      <ConfirmModal
        isOpen={confirmEditDisclosure.isOpen}
        title={'Edit your existing 10 Teams'}
        message={
          "Are you sure that you'd like to edit your 10 teams?\nDoing so will cause you to lose ALL existing points"
        }
        onClose={confirmEditDisclosure.onClose}
        isDestructive
        onConfirm={async () => {
          await submitTeam();
        }}
      />
    </Modal>
  );
}
