import { Box, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import type React from 'react';
import { Flag } from '../../components';
import { countriesInfo, Country, type CountryCode } from '../../models/country';

interface DemoTeamListProps {
  withMultipliers?: boolean;
}
const DemoTeamList: React.FC<DemoTeamListProps> = (props = { withMultipliers: false }) => {
  const teams: CountryCode[] = ['FR', 'GB_ENG', 'HU', 'HR', 'UA', 'DE', 'IT', 'TR', 'CZ', 'AL'];

  return (
    <VStack
      textAlign='center'
      borderWidth='1px'
      borderColor='#232323'
      borderRadius='16px'
      bgColor='white'
      maxW={'80%'}
      w={{
        base: '80%',
        md: '100%',
      }}
      px={{
        base: 4,
        md: 4,
      }}
      py={2}
      fontSize={{
        base: '16px',
        md: '18px',
        lg: '24px',
        xl: '28px',
      }}
    >
      {teams.map((team, idx) => (
        <Grid
          key={team}
          w='100%'
          templateColumns={{
            base: props.withMultipliers ? '18px 2fr 1fr' : '18px 2fr',
            md: props.withMultipliers ? '48px 3fr 1fr' : '48px 3fr',
          }}
          alignItems='center'
          gap={4}
          borderWidth={idx === teams.length - 1 ? '0' : '1px'}
          borderColor='white'
          borderBottomColor='rgb(0 0 0 / 20%)'
          py={{ base: 1, md: 2 }}
        >
          <GridItem alignItems='flex-start'>
            <Text
              fontWeight={{
                base: 600,
                md: 700,
              }}
              fontSize={{
                base: '12px',
                md: '18px',
                lg: '24px',
              }}
            >
              {idx + 1}.
            </Text>
          </GridItem>
          <GridItem>
            <HStack
              spacing={{
                base: 2,
                md: 4,
                lg: 8,
              }}
            >
              <Box
                boxSize={{
                  base: '18px',
                  md: '36px',
                }}
                borderWidth='1px'
                borderColor='gray.100'
                borderRadius={{
                  base: '4px',
                  md: '8px',
                }}
                overflow='hidden'
              >
                <Flag country={Country[team]} svg />
              </Box>
              <Text
                fontWeight={700}
                fontSize={{
                  base: '12px',
                  md: '16px',
                  lg: '24px',
                }}
              >
                {countriesInfo[team].name}
              </Text>
            </HStack>
          </GridItem>
          {props.withMultipliers && (
            <GridItem textAlign='right'>
              <Text
                fontWeight={700}
                fontSize={{
                  base: '18px',
                  md: '24px',
                  lg: '32px',
                }}
              >
                x{teams.length - idx}
              </Text>
            </GridItem>
          )}
        </Grid>
      ))}
    </VStack>
  );
};

export default DemoTeamList;
