import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  type DocumentReference,
  type Firestore,
  type Query,
  query,
  type QueryConstraint,
  type Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { ACTIVE_TOURNAMENT } from '../constants';
import type { Country } from './country';
import type { gameStage } from './fixture';

export enum sentinelType {
  tournamentStarted = 'tournamentStarted',
  allowTeamSwap = 'allowTeamSwap',
  tournamentEnded = 'tournamentEnded',
  latecomersAllowed = 'latecomersAllowed',
  pointsUpdating = 'pointsUpdating',
}

export interface gameUpdate {
  timestamp: Timestamp;
}

export interface gameResult extends gameUpdate {
  stage?: gameStage;
  countryA: Country;
  countryB: Country;
  pointsA: number;
  pointsB: number;
  goalsA: number;
  goalsB: number;
  wentToPens?: boolean;
  penaltiesA?: number;
  penaltiesB?: number;
  // TODO: Add kick-off time to admin update
  // Can be easily auto loaded
  kickOffTime: Timestamp;
}

// interface endOfTournamentSentinel extends gameUpdate {
//   totalGoals: number;
// }

export async function updateGame(db: Firestore, gameUpdateID: string, data: Partial<gameResult>): Promise<void> {
  return await updateDoc(doc(db, 'competitions', ACTIVE_TOURNAMENT, 'gameUpdates', gameUpdateID), data);
}

export async function addGame(db: Firestore, data: Partial<gameResult>): Promise<DocumentReference> {
  return await addDoc(collection(db, 'competitions', ACTIVE_TOURNAMENT, 'gameUpdates'), data);
}

export async function deleteGame(db: Firestore, ...keys: string[]): Promise<void> {
  return await deleteDoc(doc(db, 'competitions', ACTIVE_TOURNAMENT, 'gameUpdates', ...keys));
}

export function queryGameUpdates(db: Firestore, ...queries: QueryConstraint[]): Query {
  return query(collection(db, 'competitions', ACTIVE_TOURNAMENT, 'gameUpdates'), ...queries);
}

/* export enum gameWinner {
  teamA,
  teamB,
  draw,
}

export enum gameStage {
  groupStage,
  roundOf16,
  quarterFinal,
  semiFinal,
  final,
}

export default interface gameResult extends gameUpdate {
  countryA: country;
  countryB: country;
  goalsA: number;
  goalsB: number;
  stage: gameStage;
  updateType: gameUpdateType.gameResult;
}

export interface endOfGroupStageSentinel extends gameUpdate {
  gameSetups: [country, country][];
  updateType: gameUpdateType.endOfGroupStageSentinel;
}

export function gameWinnerFromResult(result: gameResult) {
  if (result.goalsA > result.goalsB) return gameWinner.teamA;
  if (result.goalsA < result.goalsB) return gameWinner.teamB;
  return gameWinner.draw;
}

export function getTournamentCountries(sentinel: endOfGroupStageSentinel) {
  return sentinel.gameSetups.flat();
} */
