import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  VStack,
  Checkbox,
  RadioGroup,
  Radio,
  Input,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { countriesInfo, type Country } from '@/models/country';
import { type Fixture, gameStage } from '@/models/fixture';
import type { gameResult, gameUpdate } from '@/models/gameUpdate';
import { parseNumberInput, getServerTimestamp, convertStageToString } from '../../helpers';
import { Flag } from '../Flag';
import { Timestamp } from 'firebase/firestore';

export function EditGameUpdateModal(props: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (gameResult: gameUpdate) => Promise<any> | undefined;
  getInitialValue: () => gameResult | undefined;
  loadNextFixture: () => Fixture | undefined;
}) {
  const initialValue = props.getInitialValue();
  const [countryA, setCountryA] = useState<Country | undefined>(initialValue?.countryA);
  const [countryB, setCountryB] = useState<Country | undefined>(initialValue?.countryB);
  const [pointsA, setPointsA] = useState<number>(initialValue?.pointsA ?? 0);
  const [pointsB, setPointsB] = useState<number>(initialValue?.pointsB ?? 0);
  const [goalsA, setGoalsA] = useState<number>(initialValue?.goalsA ?? 0);
  const [goalsB, setGoalsB] = useState<number>(initialValue?.goalsB ?? 0);
  const [wentToPens, setWentToPens] = useState<boolean>(initialValue?.wentToPens ?? false);
  const [penaltiesA, setPenaltiesA] = useState<number>(initialValue?.goalsA ?? 0);
  const [penaltiesB, setPenaltiesB] = useState<number>(initialValue?.goalsB ?? 0);
  const [kickOffTime, setKickOffTime] = useState<string>(parseFirebaseTimestamp(initialValue?.kickOffTime) ?? 0);
  const [stage, setStage] = useState<number>(initialValue?.stage ?? gameStage.groupStage);
  const [isBusy, setIsBusy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initialValue = props.getInitialValue();
    setCountryA(initialValue?.countryA);
    setCountryB(initialValue?.countryB);
    setPointsA(initialValue?.pointsA ?? 0);
    setPointsB(initialValue?.pointsB ?? 0);
    setGoalsA(initialValue?.goalsA ?? 0);
    setGoalsB(initialValue?.goalsB ?? 0);
    setWentToPens(initialValue?.wentToPens ?? false);
    setPenaltiesA(initialValue?.penaltiesA ?? 0);
    setPenaltiesB(initialValue?.penaltiesB ?? 0);
    setStage(initialValue?.stage ?? 0);
    setKickOffTime(parseFirebaseTimestamp(initialValue?.kickOffTime ?? getServerTimestamp()));
  }, [props.isOpen]);

  async function getNextFixture() {
    setIsLoading(true);
    const initialValue = props.loadNextFixture();
    setCountryA(initialValue?.homeTeam);
    setCountryB(initialValue?.awayTeam);
    setStage(initialValue?.stage ?? 0);
    setKickOffTime(parseFirebaseTimestamp(initialValue?.date ?? getServerTimestamp()));
    setIsLoading(false);
  }

  function parseFirebaseTimestamp(timestamp: Timestamp | undefined) {
    // TODO: Fix this. This only works for me cos I'm in the UK but isn't
    // actually time zone dependent
    if (!timestamp) return '';
    const convertedString = new Date(timestamp.seconds * 1000)
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T');
    return convertedString;
  }

  function parseDatetimeLocalString(timestamp: string): string {
    const convertedString = new Date(Date.parse(timestamp))
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T');
    return convertedString;
  }

  const isValid = countryA !== undefined && countryB !== undefined && countryA !== countryB;

  async function onSubmit() {
    if (isValid) {
      setIsBusy(true);
      await props.onSubmit({
        timestamp: getServerTimestamp(),
        countryA: countryA,
        countryB: countryB,
        pointsA: pointsA,
        pointsB: pointsB,
        goalsA: goalsA,
        goalsB: goalsB,
        wentToPens: wentToPens,
        penaltiesA: penaltiesA,
        penaltiesB: penaltiesB,
        stage: stage,
        kickOffTime: Timestamp.fromDate(new Date(Date.parse(kickOffTime))),
      } as gameResult);
      props.onClose();
      setIsBusy(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={28}>{initialValue ? 'Edit Game Result' : 'Add Game Result'}</Heading>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody>
          {initialValue ? null : (
            <Button mb={4} colorScheme='yellow' isLoading={isLoading} onClick={getNextFixture}>
              Load Last Game
            </Button>
          )}
          <FormControl>
            <FormLabel>Tournament Stage</FormLabel>
            <HStack>
              <RadioGroup value={stage.toString()} onChange={(x) => setStage(Number.parseInt(x))}>
                {/* <option disabled value={'Select a tournament stage'}>Select a tournament stage</option> */}
                {[
                  gameStage.groupStage,
                  gameStage.roundOf16,
                  gameStage.quarterFinal,
                  gameStage.semiFinal,
                  gameStage.thirdPlayoff,
                  gameStage.final,
                ].map((x) => (
                  <Radio key={x} value={x.toString()} p={2}>
                    {convertStageToString(x)}
                  </Radio>
                ))}
              </RadioGroup>
            </HStack>
          </FormControl>
          <FormControl>
            <FormLabel>Kick Off Time</FormLabel>
            <Input
              placeholder='Select Date and Time'
              size='md'
              step={60}
              type='datetime-local'
              value={kickOffTime}
              onChange={(x) => {
                console.log(x);
                return setKickOffTime(parseDatetimeLocalString(x.target.value));
              }}
            />
          </FormControl>
          <HStack align='start' pt={4}>
            <VStack>
              <Heading fontSize={24}>Home Team</Heading>
              <FormControl>
                <FormLabel>Country</FormLabel>
                <HStack>
                  <Select
                    value={countryA ?? 'Select a country'}
                    onChange={(x) =>
                      setCountryA(
                        x.target.value === 'Select a country' ? undefined : (x.target.value as Country | undefined),
                      )
                    }
                  >
                    <option value={'Select a country'}>Select a country</option>
                    {Object.values(countriesInfo).map((x) => (
                      <option key={x.id} disabled={x.id === countryB} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </Select>
                  {countryA ? (
                    <Box minW={6}>
                      <Flag w={6} h={6} borderRadius={3} country={countryA} />
                    </Box>
                  ) : null}
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel>Points</FormLabel>
                <NumberInput min={0} value={pointsA} onChange={(x) => setPointsA(parseNumberInput(x))}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Goals</FormLabel>
                <NumberInput min={0} value={goalsA} onChange={(x) => setGoalsA(parseNumberInput(x))}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                {/* <FormLabel></FormLabel> */}
                <Checkbox isChecked={wentToPens} onChange={(e) => setWentToPens(e.target.checked)}>
                  Went to Penalties?
                </Checkbox>
              </FormControl>
              {wentToPens && (
                <FormControl>
                  <FormLabel>Penalties</FormLabel>
                  <NumberInput min={0} value={penaltiesA} onChange={(x) => setPenaltiesA(parseNumberInput(x))}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              )}
            </VStack>
            <VStack>
              <Heading fontSize={24}>Away Team</Heading>
              <FormControl>
                <FormLabel>Country</FormLabel>
                <HStack>
                  <Select
                    value={countryB ?? 'Select a country'}
                    onChange={(x) =>
                      setCountryB(
                        x.target.value === 'Select a country' ? undefined : (x.target.value as Country | undefined),
                      )
                    }
                  >
                    <option value={'Select a country'}>Select a country</option>
                    {Object.values(countriesInfo).map((x) => (
                      <option key={x.id} disabled={x.id === countryA} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </Select>
                  {countryB ? (
                    <Box minW={6}>
                      <Flag w={6} h={6} borderRadius={3} country={countryB} />
                    </Box>
                  ) : null}
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel>Points</FormLabel>
                <NumberInput min={0} value={pointsB} onChange={(x) => setPointsB(parseNumberInput(x))}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Goals</FormLabel>
                <NumberInput min={0} value={goalsB} onChange={(x) => setGoalsB(parseNumberInput(x))}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              {wentToPens && (
                <FormControl>
                  <FormLabel>Penalties</FormLabel>
                  <NumberInput min={0} value={penaltiesB} onChange={(x) => setPenaltiesB(parseNumberInput(x))}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              )}
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isBusy} isDisabled={!isValid} colorScheme='green' onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
