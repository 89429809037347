import type React from 'react';
import {
  Box,
  Center,
  Circle,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import type { IconType } from 'react-icons';
import { RiSendPlaneFill as SendIcon } from 'react-icons/ri';
import { Link as RLink } from 'react-router-dom';

const Footer: React.FC = () => {
  const templateAreasDesktop = `
  'newsletter company resources account socials'
  `;
  const templateAreasMobile = `
  'newsletter newsletter'
  'company resources'
  'account socials'
  `;

  return (
    <Box
      as='footer'
      maxW='full'
      bgColor='#F6F7F9'
      borderRadius='8px'
      justifyContent='center'
      alignItems='center'
      alignContent='center'
      py={{
        base: '2em',
        md: '4em',
      }}
      px={{
        base: '1em',
        md: '4em',
      }}
    >
      <Box maxW='container.xl' w='100%' mx='auto'>
        {/* <Grid
          rowGap={{
            base: '3em',
            lg: '0',
          }}
          columnGap={{
            base: '0',
            lg: '3em',
          }}
          templateAreas={{
            base: templateAreasMobile,
            lg: templateAreasDesktop,
          }}
          gridAutoColumns='minmax(0, 1fr)'
        >
          <GridItem area='newsletter'>
            <JoinNewsletter />
          </GridItem>
          <GridItem area='company'>
            <FooterLinks title='Company' data={companyLinks} />
          </GridItem>
          <GridItem area='resources'>
            <FooterLinks title='Resources' data={resourcesLinks} />
          </GridItem>
          <GridItem area='account'>
            <FooterLinks title='Account' data={accountLinks} />
          </GridItem>
          <GridItem area='socials'>
            <Socials title='Socials' data={socialLinks} />
          </GridItem>
        </Grid> */}
        <Copyright />
      </Box>
    </Box>
  );
};
export default Footer;

const FooterLinks: React.FC<{ title: string; data: LinksType }> = ({ data, title }) => {
  return (
    <Flex direction='column' gap={2}>
      <Text
        fontSize={{
          base: '16px',
          md: '18px',
        }}
        fontWeight='semibold'
      >
        {title}
      </Text>
      <Flex
        direction='column'
        gap={4}
        color='grey'
        fontSize={{
          base: '12px',
          md: '14px',
          xl: '16px',
        }}
      >
        {data.map(({ name, href }) => (
          <Link key={name} href={href}>
            {name}
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};

const Socials: React.FC<{ title: string; data: LinksType }> = ({ data, title }) => {
  return (
    <Flex
      direction='column'
      gap={2}
      maxW={{
        base: '80%',
        md: '100%',
      }}
    >
      <Text
        fontSize={{
          base: '16px',
          md: '18px',
        }}
        fontWeight='semibold'
      >
        {title}
      </Text>
      <Flex color='grey' wrap='wrap' gap={4}>
        {data.map(({ name, href, icon }) => (
          <Link key={name} href={href}>
            <Circle size='40px' key={name} bg='#00B93C' color='white'>
              <Icon as={icon} boxSize={6} />
            </Circle>
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};

const JoinNewsletter: React.FC = () => {
  return (
    <Flex
      direction='column'
      gap={4}
      alignItems={{
        base: 'center',
        lg: 'flex-start',
      }}
    >
      <Text
        fontSize={{
          base: '16px',
          md: '18px',
        }}
        fontWeight='semibold'
      >
        Join Newsletter
      </Text>
      <InputGroup size='lg'>
        <Input borderRadius='full' placeholder='your@email.com' />
        <InputRightElement>
          <IconButton
            aria-label='submit email'
            bg='none'
            _hover={{
              bg: 'transparent',
            }}
            onClick={() => {
              // toggle show state
            }}
          >
            <Circle size='35px' bg='#00B93C' color='white'>
              <Icon as={SendIcon} boxSize={6} />
            </Circle>
          </IconButton>
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

const Copyright: React.FC = () => {
  return (
    <VStack alignItems='center' justifyContent='center'>
      <Flex
        gap={4}
        direction={{ base: 'column', sm: 'row' }}
        mt={{
          base: '3em',
          lg: '5em',
        }}
      >
        <RLink to='/Privacy_Policy.pdf' target='_blank' rel='noopener noreferrer'>
          <Text textDecoration={'underline'}>Privacy Policy</Text>
        </RLink>
        <RLink to='/Competition_T&Cs.pdf' target='_blank' rel='noopener noreferrer'>
          <Text textDecoration={'underline'}>Global League Competition T&Cs</Text>
        </RLink>
      </Flex>
      <Text>10 Teams © Copyright 2024</Text>
    </VStack>
  );
};

export type LinksType = Array<{ name: string; href: string; icon?: IconType }>;
const companyLinks: LinksType = [
  {
    name: 'Home',
    href: '/',
  },
  {
    name: 'About Us',
    href: '/about',
  },
  {
    name: 'Blog',
    href: '/blog',
  },
  {
    name: 'Support Us',
    href: '/support',
  },
  {
    name: 'Contact Us',
    href: '/contact',
  },
];
const resourcesLinks: LinksType = [
  {
    name: 'Game',
    href: '/game',
  },
  {
    name: 'Info',
    href: '/info',
  },
  {
    name: 'Scout',
    href: '/scout',
  },
  {
    name: 'Tournament',
    href: '/tournament',
  },
  {
    name: 'Insights',
    href: '/insights',
  },
];
const accountLinks: LinksType = [
  {
    name: 'Register',
    href: '/signup',
  },
  {
    name: 'Login',
    href: '/login',
  },
  {
    name: 'Affiliate',
    href: '/affiliate',
  },
];
const socialLinks: LinksType = [
  { name: 'LinkedIn', href: 'https://www.linkedin.com/company/alexandria-software-ltd/', icon: FaLinkedinIn },
  // { name: 'Facebook', href: '', icon: FaFacebookF },
  { name: 'Instagram', href: 'https://www.instagram.com/ten.teams/', icon: FaInstagram },
  { name: 'Twitter', href: 'https://x.com/ten_teams', icon: FaXTwitter },
];
