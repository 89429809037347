import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import type { IPost } from "../../posts/post.types";
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { PostCardFullImage } from "./FeaturedPostCard";

interface BlogPostContentProps {
  post: IPost;
  relatedPosts: IPost[];
}

export const BlogPostContent: React.FC<BlogPostContentProps> = ({
  post,
  relatedPosts,
}) => {
  if (!post) {
    return <div>Post not found</div>;
  }

  const [isLargerThanMobile] = useMediaQuery("(min-width: 800px)");
  const gridTemplateColumns = isLargerThanMobile
    ? "3fr minmax(200px, 250px)"
    : "1fr";

  const minHeight = isLargerThanMobile ? "400px" : "250px";
  const titleFontSize = isLargerThanMobile ? "4xl" : "xl";

  const handlePostClick = (slug?: string) => {
    if (slug) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Grid
      templateColumns={gridTemplateColumns}
      justifyContent="center"
      gap={8}
      mx="auto"
      w="100%"
      maxW="container.xl"
      textAlign="left"
      py={8}
    >
      <Flex flexDir="column" w="100%">
        <PostCardFullImage
          {...post}
          minHeight={minHeight}
          titleConfig={{
            size: titleFontSize,
          }}
          iconConfig={{
            iconsGroupSpacing: "compact",
          }}
        />
        <Text
          fontSize={{
            base: "md",
            md: "lg",
          }}
          mt={8}
          dangerouslySetInnerHTML={{
            __html: post.content,
          }}
        />
      </Flex>
      {relatedPosts.length === 0 ? null : (
        <RelatedPosts
          relatedPosts={relatedPosts}
          onPostClick={handlePostClick}
        />
      )}
    </Grid>
  );
};

const RelatedPosts: React.FC<{
  relatedPosts: IPost[];
  onPostClick?: (slug?: string) => void;
}> = ({ relatedPosts, onPostClick }) => {
  const [isLargerThanMobile] = useMediaQuery("(min-width: 800px)");
  const isCompact = isLargerThanMobile;
  return (
    <Flex flexDir="column" w="100%" gap={4}>
      <Heading fontSize="lg">Related Posts</Heading>
      <Grid gap={4}>
        {relatedPosts.map((relatedPost, idx) => {
          relatedPost = {
            ...relatedPost,
            ...relatedPost.cardConfig,
          };
          const route = `/scout/${relatedPost.slug}`;

          return (
            <Box
              as={ReactRouterLink}
              to={route}
              onClick={() => onPostClick?.(relatedPost.slug)}
            >
              <PostCardFullImage
                key={idx}
                {...relatedPost}
                isCompact={isCompact}
              />
            </Box>
          );
        })}
      </Grid>
    </Flex>
  );
};

export default BlogPostContent;
