import React from "react";
import { Helmet } from "react-helmet";

interface SEOProps {
  title: string;
  description?: string;
  lang?: string;
  contentType?: "article" | "website" | "profile" | "book" | "music" | "video";
  meta?: Array<{
    name: string;
    content: string;
  }>;
  image?: string;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description = "",
  lang = "en",
  meta = [],
  image,
  contentType = "website",
}) => {
  const defaultMeta = [
    {
      name: "description",
      content: description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:type",
      content: contentType,
    },
    {
      name: "twitter:card",
      content: image ? "summary_large_image" : "summary",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
  ];
  if (image) {
    defaultMeta.push(
      {
        property: "og:image",
        content: image,
      },
      {
        name: "twitter:image",
        content: image,
      }
    );
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | Ten Teams`}
      meta={defaultMeta.concat(meta)}
    />
  );
};

export default SEO;
