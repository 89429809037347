// RED FLAG - 'Never' change/remove items from this list. Doing so will take it out of line

import { ACTIVE_TOURNAMENT } from '../constants';

// with how the data is stored in the DB.
export enum Country {
  // Old countries from MWC 2022
  QA = 'QA',
  GB_ENG = 'GB_ENG',
  AR = 'AR',
  FR = 'FR',
  ES = 'ES',
  BE = 'BE',
  BR = 'BR',
  PT = 'PT',
  NL = 'NL',
  US = 'US',
  MX = 'MX',
  DK = 'DK',
  SN = 'SN',
  IR = 'IR',
  PL = 'PL',
  TN = 'TN',
  DE = 'DE',
  HR = 'HR',
  CH = 'CH',
  UY = 'UY',
  JP = 'JP',
  MA = 'MA',
  RS = 'RS',
  KR = 'KR',
  EC = 'EC',
  GB_WLS = 'GB_WLS',
  SA = 'SA',
  AU = 'AU',
  CR = 'CR',
  CA = 'CA',
  CM = 'CM',
  GH = 'GH',

  // New countries from WWC 2023
  NZ = 'NZ',
  SE = 'SE',
  NO = 'NO',
  IT = 'IT',
  CN = 'CN',
  IE = 'IE',
  CO = 'CO',
  VN = 'VN',
  JM = 'JM',
  NG = 'NG',
  PH = 'PH',
  ZA = 'ZA',
  ZM = 'ZM',
  HT = 'HT',
  PA = 'PA',

  // New countries from Euro 2024
  HU = 'HU',
  TR = 'TR',
  RO = 'RO',
  AL = 'AL',
  AT = 'AT',
  GB_SCT = 'GB_SCT',
  SI = 'SI',
  SK = 'SK',
  CZ = 'CZ',
  UA = 'UA',
  GE = 'GE',
  TBD = 'TBD',
}

export type CountryCode = keyof typeof Country;

export enum countryCategory {
  Favourite = 0,
  Intermediate = 1,
  Outsider = 2,
  Unknown = 3,
}

enum Continent {
  Africa = 'Africa',
  Asia = 'Asia',
  Europe = 'Europe',
  NorthAm = 'North America',
  SouthAm = 'South America',
  Oceania = 'Oceania',
  Unknown = 'Unknown',
}

export interface TournamentCountryInfo {
  id: Country;
  category: countryCategory;
}

export interface StaticCountryInfo {
  id: Country;
  name: string;
  continent: Continent;
}

const _countriesInfo: Record<'mwc-22' | 'wwc-23' | 'euro-24', any> = {
  'mwc-22': {
    // Old Countries from MWC 2022
    [Country.QA]: { id: Country.QA, category: countryCategory.Favourite },
    [Country.GB_ENG]: { id: Country.GB_ENG, category: countryCategory.Favourite },
    [Country.AR]: { id: Country.AR, category: countryCategory.Favourite },
    [Country.FR]: { id: Country.FR, category: countryCategory.Favourite },
    [Country.ES]: { id: Country.ES, category: countryCategory.Favourite },
    [Country.BE]: { id: Country.BE, category: countryCategory.Favourite },
    [Country.BR]: { id: Country.BR, category: countryCategory.Favourite },
    [Country.PT]: { id: Country.PT, category: countryCategory.Favourite },
    [Country.NL]: { id: Country.NL, category: countryCategory.Intermediate },
    [Country.US]: { id: Country.US, category: countryCategory.Intermediate },
    [Country.MX]: { id: Country.MX, category: countryCategory.Intermediate },
    [Country.DK]: { id: Country.DK, category: countryCategory.Intermediate },
    [Country.SN]: { id: Country.SN, category: countryCategory.Intermediate },
    [Country.IR]: { id: Country.IR, category: countryCategory.Intermediate },
    [Country.PL]: { id: Country.PL, category: countryCategory.Intermediate },
    [Country.TN]: { id: Country.TN, category: countryCategory.Intermediate },
    [Country.DE]: { id: Country.DE, category: countryCategory.Intermediate },
    [Country.HR]: { id: Country.HR, category: countryCategory.Intermediate },
    [Country.CH]: { id: Country.CH, category: countryCategory.Intermediate },
    [Country.UY]: { id: Country.UY, category: countryCategory.Intermediate },
    [Country.JP]: { id: Country.JP, category: countryCategory.Intermediate },
    [Country.MA]: { id: Country.MA, category: countryCategory.Intermediate },
    [Country.RS]: { id: Country.RS, category: countryCategory.Intermediate },
    [Country.KR]: { id: Country.KR, category: countryCategory.Intermediate },
    [Country.EC]: { id: Country.EC, category: countryCategory.Outsider },
    [Country.GB_WLS]: { id: Country.GB_WLS, category: countryCategory.Outsider },
    [Country.SA]: { id: Country.SA, category: countryCategory.Outsider },
    [Country.AU]: { id: Country.AU, category: countryCategory.Outsider },
    [Country.CR]: { id: Country.CR, category: countryCategory.Outsider },
    [Country.CA]: { id: Country.CA, category: countryCategory.Outsider },
    [Country.CM]: { id: Country.CM, category: countryCategory.Outsider },
    [Country.GH]: { id: Country.GH, category: countryCategory.Outsider },
    [Country.TBD]: { id: Country.TBD, category: countryCategory.Unknown },
  },

  'wwc-23': {
    // New Countries from WWC 2023
    [Country.NZ]: { id: Country.NZ, category: countryCategory.Favourite },
    [Country.AU]: { id: Country.AU, category: countryCategory.Favourite },
    [Country.US]: { id: Country.US, category: countryCategory.Favourite },
    [Country.SE]: { id: Country.SE, category: countryCategory.Favourite },
    [Country.DE]: { id: Country.DE, category: countryCategory.Favourite },
    [Country.GB_ENG]: { id: Country.GB_ENG, category: countryCategory.Favourite },
    [Country.FR]: { id: Country.FR, category: countryCategory.Favourite },
    [Country.ES]: { id: Country.ES, category: countryCategory.Favourite },
    [Country.CA]: { id: Country.CA, category: countryCategory.Intermediate },
    [Country.NL]: { id: Country.NL, category: countryCategory.Intermediate },
    [Country.BR]: { id: Country.BR, category: countryCategory.Intermediate },
    [Country.JP]: { id: Country.JP, category: countryCategory.Intermediate },
    [Country.NO]: { id: Country.NO, category: countryCategory.Intermediate },
    [Country.IT]: { id: Country.IT, category: countryCategory.Intermediate },
    [Country.CN]: { id: Country.CN, category: countryCategory.Intermediate },
    [Country.KR]: { id: Country.KR, category: countryCategory.Intermediate },
    [Country.DK]: { id: Country.DK, category: countryCategory.Intermediate },
    [Country.CH]: { id: Country.CH, category: countryCategory.Intermediate },
    [Country.IE]: { id: Country.IE, category: countryCategory.Intermediate },
    [Country.CO]: { id: Country.CO, category: countryCategory.Intermediate },
    [Country.AR]: { id: Country.AR, category: countryCategory.Intermediate },
    [Country.VN]: { id: Country.VN, category: countryCategory.Intermediate },
    [Country.CR]: { id: Country.CR, category: countryCategory.Intermediate },
    [Country.JM]: { id: Country.JM, category: countryCategory.Intermediate },
    [Country.NG]: { id: Country.NG, category: countryCategory.Outsider },
    [Country.PH]: { id: Country.PH, category: countryCategory.Outsider },
    [Country.ZA]: { id: Country.ZA, category: countryCategory.Outsider },
    [Country.MA]: { id: Country.MA, category: countryCategory.Outsider },
    [Country.ZM]: { id: Country.ZM, category: countryCategory.Outsider },
    [Country.PT]: { id: Country.PT, category: countryCategory.Outsider },
    [Country.HT]: { id: Country.HT, category: countryCategory.Outsider },
    [Country.PA]: { id: Country.PA, category: countryCategory.Outsider },
    [Country.TBD]: { id: Country.TBD, category: countryCategory.Unknown },
  },

  'euro-24': {
    // Favourites
    [Country.DE]: { id: Country.DE, category: countryCategory.Favourite },
    [Country.PT]: { id: Country.PT, category: countryCategory.Favourite },
    [Country.FR]: { id: Country.FR, category: countryCategory.Favourite },
    [Country.ES]: { id: Country.ES, category: countryCategory.Favourite },
    [Country.BE]: { id: Country.BE, category: countryCategory.Favourite },
    [Country.GB_ENG]: { id: Country.GB_ENG, category: countryCategory.Favourite },
    // Intermediates
    [Country.HU]: { id: Country.HU, category: countryCategory.Intermediate },
    [Country.TR]: { id: Country.TR, category: countryCategory.Intermediate },
    [Country.RO]: { id: Country.RO, category: countryCategory.Intermediate },
    [Country.DK]: { id: Country.DK, category: countryCategory.Intermediate },
    [Country.AL]: { id: Country.AL, category: countryCategory.Intermediate },
    [Country.AT]: { id: Country.AT, category: countryCategory.Intermediate },
    [Country.NL]: { id: Country.NL, category: countryCategory.Intermediate },
    [Country.GB_SCT]: { id: Country.GB_SCT, category: countryCategory.Intermediate },
    [Country.HR]: { id: Country.HR, category: countryCategory.Intermediate },
    [Country.SI]: { id: Country.SI, category: countryCategory.Intermediate },
    [Country.SK]: { id: Country.SK, category: countryCategory.Intermediate },
    [Country.CZ]: { id: Country.CZ, category: countryCategory.Intermediate },
    // Outsiders
    [Country.IT]: { id: Country.IT, category: countryCategory.Outsider },
    [Country.RS]: { id: Country.RS, category: countryCategory.Outsider },
    [Country.CH]: { id: Country.CH, category: countryCategory.Outsider },
    [Country.PL]: { id: Country.PL, category: countryCategory.Outsider },
    [Country.UA]: { id: Country.UA, category: countryCategory.Outsider },
    [Country.GE]: { id: Country.GE, category: countryCategory.Outsider },

    [Country.TBD]: { id: Country.TBD, category: countryCategory.Unknown },
  },
};

export const countriesInfo: Record<Country, StaticCountryInfo> = {
  // Old Countries from MWC 2022
  [Country.QA]: { id: Country.QA, name: 'Qatar', continent: Continent.Asia },
  [Country.GB_ENG]: { id: Country.GB_ENG, name: 'England', continent: Continent.Europe },
  [Country.AR]: { id: Country.AR, name: 'Argentina', continent: Continent.SouthAm },
  [Country.FR]: { id: Country.FR, name: 'France', continent: Continent.Europe },
  [Country.ES]: { id: Country.ES, name: 'Spain', continent: Continent.Europe },
  [Country.BE]: { id: Country.BE, name: 'Belgium', continent: Continent.Europe },
  [Country.BR]: { id: Country.BR, name: 'Brazil', continent: Continent.SouthAm },
  [Country.PT]: { id: Country.PT, name: 'Portugal', continent: Continent.Europe },
  [Country.NL]: { id: Country.NL, name: 'Netherlands', continent: Continent.Europe },
  [Country.US]: { id: Country.US, name: 'USA', continent: Continent.NorthAm },
  [Country.MX]: { id: Country.MX, name: 'Mexico', continent: Continent.NorthAm },
  [Country.DK]: { id: Country.DK, name: 'Denmark', continent: Continent.Europe },
  [Country.SN]: { id: Country.SN, name: 'Senegal', continent: Continent.Africa },
  [Country.IR]: { id: Country.IR, name: 'Iran', continent: Continent.Asia },
  [Country.PL]: { id: Country.PL, name: 'Poland', continent: Continent.Europe },
  [Country.TN]: { id: Country.TN, name: 'Tunisia', continent: Continent.Africa },
  [Country.DE]: { id: Country.DE, name: 'Germany', continent: Continent.Europe },
  [Country.HR]: { id: Country.HR, name: 'Croatia', continent: Continent.Europe },
  [Country.CH]: { id: Country.CH, name: 'Switzerland', continent: Continent.Europe },
  [Country.UY]: { id: Country.UY, name: 'Uruguay', continent: Continent.SouthAm },
  [Country.JP]: { id: Country.JP, name: 'Japan', continent: Continent.Asia },
  [Country.MA]: { id: Country.MA, name: 'Morocco', continent: Continent.Africa },
  [Country.RS]: { id: Country.RS, name: 'Serbia', continent: Continent.Europe },
  [Country.KR]: { id: Country.KR, name: 'S Korea', continent: Continent.Asia },
  [Country.EC]: { id: Country.EC, name: 'Ecuador', continent: Continent.SouthAm },
  [Country.GB_WLS]: { id: Country.GB_WLS, name: 'Wales', continent: Continent.Europe },
  [Country.SA]: { id: Country.SA, name: 'Saudi Arabia', continent: Continent.Asia },
  [Country.AU]: { id: Country.AU, name: 'Australia', continent: Continent.Oceania },
  [Country.CR]: { id: Country.CR, name: 'Costa Rica', continent: Continent.NorthAm },
  [Country.CA]: { id: Country.CA, name: 'Canada', continent: Continent.NorthAm },
  [Country.CM]: { id: Country.CM, name: 'Cameroon', continent: Continent.Africa },
  [Country.GH]: { id: Country.GH, name: 'Ghana', continent: Continent.Africa },

  // New Countries from WWC 2023
  [Country.NZ]: { id: Country.NZ, name: 'New Zealand', continent: Continent.Oceania },
  [Country.SE]: { id: Country.SE, name: 'Sweden', continent: Continent.Europe },
  [Country.NO]: { id: Country.NO, name: 'Norway', continent: Continent.Europe },
  [Country.IT]: { id: Country.IT, name: 'Italy', continent: Continent.Europe },
  [Country.CN]: { id: Country.CN, name: 'China', continent: Continent.Asia },
  [Country.IE]: { id: Country.IE, name: 'Ireland', continent: Continent.Europe },
  [Country.CO]: { id: Country.CO, name: 'Colombia', continent: Continent.SouthAm },
  [Country.VN]: { id: Country.VN, name: 'Vietnam', continent: Continent.Asia },
  [Country.JM]: { id: Country.JM, name: 'Jamaica', continent: Continent.NorthAm },
  [Country.NG]: { id: Country.NG, name: 'Nigeria', continent: Continent.Africa },
  [Country.PH]: { id: Country.PH, name: 'Philippines', continent: Continent.Asia },
  [Country.ZA]: { id: Country.ZA, name: 'South Africa', continent: Continent.Africa },
  [Country.ZM]: { id: Country.ZM, name: 'Zambia', continent: Continent.Africa },
  [Country.HT]: { id: Country.HT, name: 'Haiti', continent: Continent.NorthAm },
  [Country.PA]: { id: Country.PA, name: 'Panama', continent: Continent.NorthAm },

  // New countries from Euro 2024
  [Country.HU]: { id: Country.HU, name: 'Hungary', continent: Continent.Europe },
  [Country.TR]: { id: Country.TR, name: 'Türkiye', continent: Continent.Europe },
  [Country.RO]: { id: Country.RO, name: 'Romania', continent: Continent.Europe },
  [Country.AL]: { id: Country.AL, name: 'Albania', continent: Continent.Europe },
  [Country.AT]: { id: Country.AT, name: 'Austria', continent: Continent.Europe },
  [Country.GB_SCT]: { id: Country.GB_SCT, name: 'Scotland', continent: Continent.Europe },
  [Country.SI]: { id: Country.SI, name: 'Slovenia', continent: Continent.Europe },
  [Country.SK]: { id: Country.SK, name: 'Slovakia', continent: Continent.Europe },
  [Country.CZ]: { id: Country.CZ, name: 'Czechia', continent: Continent.Europe },
  [Country.UA]: { id: Country.UA, name: 'Ukraine', continent: Continent.Europe },
  [Country.GE]: { id: Country.GE, name: 'Georgia', continent: Continent.Europe },

  [Country.TBD]: { id: Country.TBD, name: 'TBD', continent: Continent.Unknown },
};

export const tournamentCountriesInfo: Record<Country, TournamentCountryInfo> = _countriesInfo[ACTIVE_TOURNAMENT];

export function countryListValid(countryList: Country[]) {
  const favouritesValid =
    countryList.filter((x) => tournamentCountriesInfo[x].category === countryCategory.Favourite).length <= 4;
  const outsidersValid =
    countryList.filter((x) => tournamentCountriesInfo[x].category === countryCategory.Outsider).length >= 2;
  return countryList.length === 10 && favouritesValid && outsidersValid;
}
