import {
  Box,
  Button,
  Center,
  type ChakraProps,
  HStack,
  Heading,
  Portal,
  Show,
  Spacer,
  Stack,
  type StackDirection,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
  Flex,
  Spinner,
  type ResponsiveValue,
  Tooltip,
} from '@chakra-ui/react';
import { type FunctionsError, httpsCallable } from 'firebase/functions';
import { Link, useNavigate } from 'react-router-dom';
import { Fragment, type PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import type { League } from '@/models';
import { type leagueMember, updateTeamSelectionData, updateUser } from '@/models/user';
import { countryListValid, type Country } from '@/models/country';
import type { leagueCreationDetails } from '@/models/league';
import { sentinelType, type gameResult } from '@/models/gameUpdate';
import { ACTIVE_TOURNAMENT, worldPopulation } from '@/constants';
import { CountryListGrid, FirebaseContext, RankChangeIndicator, AuthedPageContainer } from '../components';
import {
  CreateLeagueModal,
  EditCountryListModal,
  ShareModal,
  StringEntryModal,
  SwapTeamModal,
} from '../components/modals';
import { createLeagueToastOptions, firebaseErrorToastOptions, getServerTimestamp, ordinalSuffix } from '../helpers';
import { FixtureListGrid, ResultListGrid } from '../components/FixtureListGrid';
import { useAuthContext } from './AuthLayout';

function Card(props: ChakraProps & PropsWithChildren) {
  const cardBrackground = useColorModeValue('gray.50', 'gray.700');
  const mediaProps = useBreakpointValue<Partial<ChakraProps>>({
    base: { height: 'unset', overflowY: 'unset' },
    lg: { height: 'full', overflowY: 'auto' },
  });
  const ref = useRef(null);
  const { children, ...rest } = props;
  return (
    <Box ref={ref} background={cardBrackground} width='full' py={4} px={6} borderRadius={24} {...mediaProps} {...rest}>
      <Portal containerRef={ref}>{children}</Portal>
    </Box>
  );
}

function LeagueButton(props: { leagueID: string; league: League; memberData: leagueMember }) {
  const { gameUpdates } = useAuthContext();
  const tournamentStarted = Object.keys(gameUpdates).includes(sentinelType.tournamentStarted);
  const ctx = useContext(FirebaseContext);
  const { leagueID, league, memberData } = props;
  const rankToDisplay =
    memberData.currentRank > 0 ? memberData.currentRank : league.memberUIDs.indexOf(ctx.user!.uid) + 1;
  const leagueButtonTextSize = useBreakpointValue<ResponsiveValue<number>>({
    base: 14,
    lg: 15,
    xl: 16,
  });

  return (
    <Link to={`../league/${leagueID}`}>
      <Button width={'full'}>
        <HStack width={'full'} spacing={4}>
          <Text noOfLines={1} textAlign='start' fontSize={leagueButtonTextSize}>
            {league.name}
          </Text>
          <Spacer />
          {league.adminUID === ctx.user!.uid ? (
            <Box>
              <Tag size='md' colorScheme='purple' fontSize={leagueButtonTextSize}>
                Admin
              </Tag>
            </Box>
          ) : null}
          {memberData.currentRank === worldPopulation ? (
            <Text fontWeight={'bold'} fontStyle='italic' fontSize={leagueButtonTextSize}>
              {memberData.latecomer ? '-----' : 'pending'}
            </Text>
          ) : (
            <>
              <Text fontWeight={'bold'} fontSize={leagueButtonTextSize}>
                {!tournamentStarted || memberData.spectator ? (
                  '-----'
                ) : (
                  <>
                    {rankToDisplay}
                    <sup>{ordinalSuffix(rankToDisplay)}</sup>
                  </>
                )}
              </Text>
              {memberData.currentRank > 0 ? (
                <RankChangeIndicator
                  memberData={memberData}
                  fontSize={leagueButtonTextSize}
                  getListPosition={() => league.memberUIDs.indexOf(ctx.user!.uid)}
                />
              ) : null}
            </>
          )}
        </HStack>
      </Button>
    </Link>
  );
}

export default function ProfilePage() {
  const { fixtures, gameUpdates, leagues, memberData, teamSelectionData, userData } = useAuthContext();
  const pointsUpdating = Object.keys(gameUpdates).includes(sentinelType.pointsUpdating);

  const { requiresShareUpdate, shareShortLink } = userData;

  const currentTournamentSelection = teamSelectionData[ACTIVE_TOURNAMENT] ?? {};
  const {
    countryList = [],
    listIndexSwapPair = null,
    goalsGuess = 0,
    currentScore = 0,
    potentialScore = 0,
    prevScore = 0,
    latecomer = undefined,
  } = currentTournamentSelection;

  const ctx = useContext(FirebaseContext);
  const navigate = useNavigate();

  const editCountryListDisclosure = useDisclosure();
  const createLeagueDisclosure = useDisclosure();
  const joinLeagueDisclosure = useDisclosure();
  const shareDisclosure = useDisclosure();

  const scoreStatusStackDirection = useBreakpointValue<StackDirection>({
    base: 'column',
    lg: 'row',
  });
  const cardMaxW = useBreakpointValue({ base: '100%' });
  const cardPadX = useBreakpointValue({ base: 4, md: 8 });
  const resultPadX = useBreakpointValue({ base: 4, md: 4 });

  const memberKeys = Object.keys(memberData);
  const leagueKeys = Object.keys(leagues);
  const isInGlobalLeague = leagueKeys.includes('global');

  const [pendingList, setPendingList] = useState<Country[]>();
  const [pendingGoalsGuess, setPendingGoalsGuess] = useState<number>();
  const [pendingLeagueName, setPendingLeagueName] = useState<string>();
  useEffect(() => {
    setPendingLeagueName(localStorage.getItem('pendingLeagueName') ?? undefined);
    const pendingListString = localStorage.getItem('pendingList');
    if (pendingListString) {
      try {
        const countryList = pendingListString.split(',') as Country[];
        setPendingList(countryList);
        setPendingGoalsGuess(Number.parseInt(localStorage.getItem('pendingGoalsGuess') ?? '0'));
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  useEffect(() => {
    if (!!pendingList && !!pendingGoalsGuess) {
      if (countryListValid(pendingList) && pendingGoalsGuess > 0) {
        updateTeamSelectionData(ctx.db, ctx.user!.uid, {
          countryList: pendingList,
          goalsGuess: pendingGoalsGuess,
          spectator: false,
        }).then(() => {
          localStorage.removeItem('pendingList');
          localStorage.removeItem('pendingGoalsGuess');
          setPendingList(undefined);
          setPendingGoalsGuess(undefined);
        });
        updateUser(ctx.db, ctx.user!.uid, {
          requiresShareUpdate: true,
        });
      } else {
        localStorage.removeItem('pendingList');
        localStorage.removeItem('pendingGoalsGuess');
        setPendingList(undefined);
        setPendingGoalsGuess(undefined);
      }
    }
  }, [pendingList, pendingGoalsGuess]);

  useEffect(() => {
    if (!!pendingLeagueName && pendingLeagueName.trim().length > 0) {
      const cloudFunc = httpsCallable(ctx.funcs, 'createLeague');
      try {
        const details: leagueCreationDetails = { name: pendingLeagueName };
        cloudFunc(details).then((result) => {
          const uid = (result.data as { uid: string }).uid;
          toast(createLeagueToastOptions(details));
          navigate(`../league/${uid}`);
        });
      } catch (error) {
        toast(firebaseErrorToastOptions('Failed To Create League', error as FunctionsError));
      }
    }
  }, [pendingLeagueName]);

  const toast = useToast();

  if (!!memberData || Object.keys(leagues ?? {}).length === 0) {
    if (!!pendingList || !!pendingGoalsGuess) {
      return (
        <Center width='100vw' height='100vh'>
          <VStack spacing={4}>
            <Heading fontSize={24}>Creating your list.</Heading>
            <Spinner />
          </VStack>
        </Center>
      );
    } else {
      if (gameUpdates) {
        const tournamentStarted = Object.keys(gameUpdates).includes(sentinelType.tournamentStarted);
        const allowTeamSwap = countryList.length > 0 && Object.keys(gameUpdates).includes(sentinelType.allowTeamSwap);
        const latecomerAllowed = Object.keys(gameUpdates).includes(sentinelType.latecomersAllowed);
        const tournamentEnded = Object.keys(gameUpdates).includes(sentinelType.tournamentEnded);

        const gameResults = Object.keys(gameUpdates)
          .filter((x) => !(x in sentinelType) && gameUpdates[x].timestamp !== null)
          .map((x) => gameUpdates[x] as gameResult);

        const countryListToDisplay = [...countryList];
        const fixtureListToDisplay = [...fixtures];
        const swapPair = listIndexSwapPair;
        if (swapPair) {
          const temp = countryListToDisplay[swapPair[0]];
          countryListToDisplay[swapPair[0]] = countryListToDisplay[swapPair[1]];
          countryListToDisplay[swapPair[1]] = temp;
        }

        const fixtureListCard = (
          <Card maxW={cardMaxW} pl={resultPadX} pr={1}>
            <Box>
              <VStack height={{ base: 400, lg: 'full' }} spacing={4} overflowY='auto' overflowX='hidden'>
                <Heading>Fixtures</Heading>
                <FixtureListGrid fixtureList={fixtureListToDisplay} svg />
              </VStack>
            </Box>
          </Card>
        );

        const resultListCard = (
          <Card maxW={cardMaxW} pl={resultPadX} pr={1}>
            <Box>
              <VStack height={{ base: 400, lg: 'full' }} spacing={4} overflowY='auto' overflowX='hidden'>
                <Heading>Results</Heading>
                <ResultListGrid userData={userData} gameResults={gameResults} svg />
              </VStack>
            </Box>
          </Card>
        );

        const countryListCard = (
          <Card maxW={cardMaxW}>
            <Box>
              <VStack height='full' spacing={4}>
                <Heading>List</Heading>
                <CountryListGrid countryList={countryListToDisplay} gameResults={gameResults} svg />
                {goalsGuess > 0 ? (
                  <Heading fontSize={20}>Goals Guess: {goalsGuess}</Heading>
                ) : (
                  <Center height='full'>
                    <Text fontSize={18}>
                      <strong>Welcome to 10 Teams!</strong>
                      <br />
                      Press the button below to create your list before Euro 2024 starts!
                    </Text>
                  </Center>
                )}
              </VStack>
            </Box>
          </Card>
        );

        const tooltipLabel = latecomerAllowed
          ? 'The tournament has now started. Changing teams will reset your points'
          : 'The tournament has now started and you can no longer pick new teams';
        const countryListButtons = (
          <HStack width={'full'} spacing={8} pl={8} pr={8}>
            <Tooltip hasArrow placement='top' label={tooltipLabel} isDisabled={!(tournamentStarted && !allowTeamSwap)}>
              <Button
                colorScheme={countryList.length < 10 ? 'green' : 'gray'}
                onClick={editCountryListDisclosure.onOpen}
                width='full'
                isDisabled={tournamentStarted && !allowTeamSwap && !latecomerAllowed}
              >
                {countryList.length < 10 ? 'Create' : 'Edit'} List
              </Button>
            </Tooltip>
            <Button
              width='full'
              isDisabled={countryList.length < 10}
              onClick={shareDisclosure.onOpen}
              isLoading={countryList.length === 10 && requiresShareUpdate}
            >
              Share List
            </Button>
          </HStack>
        );

        const scoreDiff = currentScore - prevScore;
        const scoreCard = (
          <Card maxW={cardMaxW}>
            <VStack>
              <Heading fontSize={28}>Score</Heading>
              <Heading fontSize={48}>
                {currentScore} ({scoreDiff >= 0 ? '+' : null}
                {scoreDiff}){/* {potentialScore !== currentScore &&  */}
                {/* // <Text fontSize={14}>You could've been on {potentialScore} points if you'd joined on time :) </Text>} */}
              </Heading>
            </VStack>
          </Card>
        );

        const statusCard = (
          <Card overflowY={'unset'} textAlign='center'>
            <VStack>
              <Heading fontSize={28} mb={1}>
                Status
              </Heading>
              {tournamentEnded ? (
                <>
                  <Heading fontSize={18}>Euro 2024 Has Finished</Heading>
                  <Text fontSize={14}>Thank you for playing!</Text>
                </>
              ) : tournamentStarted ? (
                <>
                  <Heading fontSize={18}>Euro 2024 Is In Progress</Heading>
                  <Text fontSize={14}>
                    {allowTeamSwap
                      ? 'You may swap two of the teams in your list.'
                      : 'You may no longer edit your list.'}
                    <br /> You may no longer create or join a league.
                  </Text>
                </>
              ) : (
                <>
                  <Heading fontSize={18}>{"Euro 2024 Hasn't Started"}</Heading>
                  <Text fontSize={14}>You may edit your list until the start of Euro 2024.</Text>
                </>
              )}
            </VStack>
          </Card>
        );

        const pointsUpdatingCard = (
          <Card overflowY={'unset'} textAlign='center'>
            <VStack>
              <Heading fontSize={28} mb={1}>
                Status
              </Heading>
              <Heading fontSize={18}>Points and rankings are now updating</Heading>
              <Text fontSize={14}>Please check again later.</Text>
            </VStack>
          </Card>
        );

        const leagueListCard = (
          <Card h='full' maxW={cardMaxW} px={cardPadX}>
            <Box>
              <VStack height='full' spacing={6}>
                <Heading>Leagues</Heading>
                <Flex direction='column' width='100%' gap={2}>
                  {['global', ...leagueKeys.filter((x) => x !== 'global' && memberKeys.includes(x))].map((x) =>
                    x === 'global' ? (
                      isInGlobalLeague ? (
                        memberKeys.includes('global') ? (
                          <LeagueButton
                            key={'global'}
                            leagueID='global'
                            league={leagues.global}
                            memberData={memberData.global}
                          />
                        ) : (
                          <Fragment key='global' />
                        )
                      ) : (
                        <Link key={'global'} to={'../join/GLOBAL'}>
                          <Button width={'full'}>
                            <HStack width={'full'} spacing={4}>
                              <Text>Global League</Text>
                              <Spacer />
                              <em>click to join</em>
                            </HStack>
                          </Button>
                        </Link>
                      )
                    ) : (
                      <LeagueButton key={x} leagueID={x} league={leagues[x]} memberData={memberData[x]} />
                    ),
                  )}
                </Flex>
              </VStack>
            </Box>
          </Card>
        );

        const leagueListButtons = (
          <HStack width={'full'} spacing={4} pl={4} pr={4}>
            <Button width={'full'} px={2} onClick={createLeagueDisclosure.onOpen}>
              Create League
            </Button>
            <Button width={'full'} px={2} onClick={joinLeagueDisclosure.onOpen}>
              Join League
            </Button>
          </HStack>
        );

        return (
          <AuthedPageContainer>
            <Show above='xl'>
              {/* <VStack maxWidth='100vw' overflowX={'hidden'} padding={8} pb={0} spacing={8}> */}
              <Flex flex={'2 1 0'} direction='row' height='calc(100vh - 114px)' gap={8} px={8} pt={4}>
                <VStack flex={'1 1 0'} width='full' height={'full'} spacing={8}>
                  {countryListCard}
                  <Box width='full' pb={4}>
                    {countryListButtons}
                  </Box>
                </VStack>
                <VStack flex={'2 1 0'} height={'full'} width='full' spacing={4}>
                  <Stack direction={scoreStatusStackDirection} width='full'>
                    {tournamentStarted ? (pointsUpdating ? pointsUpdatingCard : scoreCard) : statusCard}
                  </Stack>
                  <Spacer />
                  {leagueListCard}
                  <Spacer />
                  <Box width='full' pb={4}>
                    {leagueListButtons}
                  </Box>
                </VStack>
                <VStack flex={'1 1 0'} maxHeight='calc(90vh - 7.5rem)' width='full' spacing={4}>
                  {gameResults.length > 0 ? resultListCard : null}
                  {fixtureListToDisplay.length > 0 ? fixtureListCard : null}
                </VStack>
              </Flex>
              {/* </VStack> */}
            </Show>
            <Show below='xl'>
              <VStack maxWidth={'100vw'} padding={4} spacing={8} overflowX='hidden'>
                {tournamentStarted ? leagueListCard : countryListCard}
                {tournamentStarted ? leagueListButtons : countryListButtons}
                {tournamentStarted ? (pointsUpdating ? pointsUpdatingCard : scoreCard) : leagueListCard}
                {tournamentStarted ? countryListCard : leagueListButtons}
                {tournamentStarted ? countryListButtons : statusCard}
                {gameResults.length > 0 ? resultListCard : null}
                {fixtureListToDisplay.length > 0 ? fixtureListCard : null}
              </VStack>
            </Show>

            <EditCountryListModal
              isOpen={
                editCountryListDisclosure.isOpen && (!tournamentStarted || (tournamentStarted && latecomerAllowed))
              }
              onClose={editCountryListDisclosure.onClose}
              getStartingList={() => countryList}
              getStartingGoalsGuess={() => goalsGuess}
              gameUpdates={gameUpdates}
            />

            <SwapTeamModal
              isOpen={editCountryListDisclosure.isOpen && tournamentStarted && allowTeamSwap}
              onClose={editCountryListDisclosure.onClose}
              countryList={countryList}
              getStartingSwap={() => listIndexSwapPair}
            />

            <CreateLeagueModal isOpen={createLeagueDisclosure.isOpen} onClose={createLeagueDisclosure.onClose} />

            <StringEntryModal
              isOpen={joinLeagueDisclosure.isOpen}
              title='Join League'
              label='6 Digit Authentication Code'
              onClose={joinLeagueDisclosure.onClose}
              onSubmit={(authCode) => navigate(`../join/${authCode}`)}
              isValid={(authCode) => authCode.trim().length === 6}
              helperText='Anyone in your league can give this to you.'
              converter={(x) => x.toUpperCase()}
            />

            {!!shareShortLink && !requiresShareUpdate ? (
              <ShareModal isOpen={shareDisclosure.isOpen} onClose={shareDisclosure.onClose} userData={userData} />
            ) : null}
          </AuthedPageContainer>
        );
      } else return null;
    }
  } else return null;
}
