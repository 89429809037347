import type React from "react";
import { HStack, Text, Icon, Flex, Image, Box, Grid } from "@chakra-ui/react";
import { FaUserCircle as UserIcon } from "react-icons/fa";
import { IoCalendar as CalendarIcon } from "react-icons/io5";
import { MdOutlineTimelapse as TimeIcon } from "react-icons/md";
import type { PostCardProps } from "../post.types";

export const FeaturedPostCard: React.FC<PostCardProps> = (props) => {
  switch (props.variant) {
    case "full-image":
      return <PostCardFullImage {...props} />;
    case "no-image":
      return <PostCardNoImage {...props} />;
    default:
      return <PostCardDefault {...props} />;
  }
};

export const PostCardDefault: React.FC<PostCardProps> = (props) => {
  return (
    <Flex
      flexDir="column"
      gap={8}
      bgColor={props.bgColor}
      justifyContent="space-between"
      borderRadius="3xl"
      p={["16px", "22px"]}
      minH={getMinHeight(props.isCompact)}
    >
      <Image
        h="100%"
        borderRadius="3xl"
        filter="brightness(40%)"
        src={props.image}
        bgRepeat="no-repeat"
        objectFit="cover"
        bgSize="cover"
        top={0}
        left={0}
        w="100%"
      />
      <PostCardMetadata {...props} />
    </Flex>
  );
};

export const PostCardFullImage: React.FC<PostCardProps> = (props) => {
  if (!props.image) return null;
  const minHeight = props.minHeight ?? getMinHeight(props.isCompact);
  return (
    <Flex
      flexDir="column"
      borderRadius="3xl"
      overflow="hidden"
      p={["16px", "22px"]}
      pos="relative"
      minH={minHeight}
    >
      <Image
        pos="absolute"
        h="100%"
        filter="brightness(40%)"
        src={props.image}
        bgRepeat="no-repeat"
        objectFit="cover"
        bgSize="cover"
        top={0}
        left={0}
        w="100%"
      />
      <Box flexGrow={1} />
      <Box zIndex={1}>
        <PostCardMetadata {...props} contentColor="gray.50" />
      </Box>
    </Flex>
  );
};

export const PostCardNoImage: React.FC<PostCardProps> = (props) => {
  return (
    <Flex
      flexDir="column"
      gap={8}
      bgColor={props.bgColor}
      justifyContent="space-between"
      borderRadius="3xl"
      p={["16px", "22px"]}
      minH={getMinHeight(props.isCompact)}
    >
      {props?.icon && (
        <Icon as={props.icon} fill={props.contentColor} boxSize="40px" />
      )}
      <PostCardMetadata {...props} />
    </Flex>
  );
};

type PostCardMetadataProps = PostCardProps & {
  isCompact?: boolean;
};

const PostCardMetadata: React.FC<PostCardMetadataProps> = ({
  title,
  author,
  publishedAt,
  readingTime,
  contentColor = "black",
  isCompact = false,
  iconConfig,
  titleConfig,
}) => {
  const data = [
    {
      value: author,
      icon: UserIcon,
    },
    {
      value: publishedAt,
      icon: CalendarIcon,
    },
    {
      value: readingTime,
      icon: TimeIcon,
    },
  ];

  const titleFontSize = titleConfig?.size
    ? titleConfig.size
    : isCompact
    ? "md"
    : "xl";
  const iconSize = iconConfig?.size ?? isCompact ? "16px" : "28px";
  const iconTextSize = iconConfig?.textSize ?? isCompact ? "9px" : "xs";
  const iconsGridTemplateColumns =
    iconConfig?.iconsGroupSpacing === "compact"
      ? "repeat(3, fit-content(100%))"
      : "repeat(3, 1fr)";
  const iconsGridGap =
    iconConfig?.iconsGroupSpacing === "compact"
      ? {
          base: 2,
          md: 4,
          lg: 8,
        }
      : 1;

  return (
    <Flex flexDir="column" gap={4} textAlign="left">
      <Text
        fontFamily="poppins"
        fontWeight={500}
        fontSize={titleFontSize}
        color={contentColor}
        textOverflow="ellipsis"
      >
        {title}
      </Text>
      <Grid templateColumns={iconsGridTemplateColumns} gap={iconsGridGap}>
        {data
          .filter((item) => !!item.value)
          .map((item) => (
            <HStack key={item.value} spacing={1}>
              <Icon boxSize={iconSize} fill={contentColor} as={item.icon} />
              <Text fontSize={iconTextSize} color={contentColor} noOfLines={1}>
                {item.value}
              </Text>
            </HStack>
          ))}
      </Grid>
    </Flex>
  );
};

const getMinHeight = (isCompact?: boolean) =>
  isCompact
    ? "128px"
    : {
        base: "178px",
        sm: "218px",
        md: "248px",
      };
