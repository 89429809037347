import { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import {
  Text,
  Center,
  Heading,
  Spinner,
  VStack,
  useDisclosure,
  Modal,
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Image,
  useColorModeValue,
  Spacer,
} from '@chakra-ui/react';
import { collection, collectionGroup, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import type { Fixture, League, User } from '@/models';
import type { TeamSelectionRecord, leagueMember } from '@/models/user';
import { type gameUpdate, queryGameUpdates, sentinelType } from '@/models/gameUpdate';
import { EnterNamePage, FirebaseContext } from '../components';
import { EditCountryListModal, RulesModal } from '../components/modals';
import { type Dict, toDict } from '../helpers';
import { ACTIVE_TOURNAMENT } from '../constants';
import TeamSwapImage from '../assets/team_swap.png';

type ContextType = {
  leagues: Dict<League>;
  userData: User;
  teamSelectionData: Dict<TeamSelectionRecord>;
  fixtures: Fixture[];
  memberData: Dict<leagueMember>;
  gameUpdates: Dict<gameUpdate>;
  tournamentStarted: boolean;
  allowTeamSwap: boolean;
};

export default function AuthLayout(props: {
  redirectTo: string;
  userData: User | undefined;
  teamSelectionData: Dict<TeamSelectionRecord>;
  userProfileExists: boolean | undefined;
}) {
  const ctx = useContext(FirebaseContext);
  const [listPromptDismissed, setListPromptDismissed] = useState(false);
  const [fixtures, setFixtures] = useState<Fixture[]>([]);
  const [leagues, setLeagues] = useState<Dict<League>>({});
  const [leagueKeys, setLeagueKeys] = useState<string[]>([]);
  const [memberData, setMemberData] = useState<Dict<leagueMember>>({});
  const [gameUpdates, setGameUpdates] = useState<Dict<gameUpdate>>({});
  const [tournamentStarted, setTournamentStarted] = useState<boolean>(true);
  const [allowTeamSwap, setAllowTeamSwap] = useState<boolean>(false);
  const loc = useLocation();

  useEffect(() => {
    return onSnapshot(
      queryGameUpdates(ctx.db, orderBy('timestamp', 'desc')),
      (snapshot) => {
        const _gameUpdates: Dict<gameUpdate> = toDict(snapshot);
        setGameUpdates(_gameUpdates);
        setTournamentStarted(_gameUpdates ? Object.keys(_gameUpdates).includes(sentinelType.tournamentStarted) : false);
        setAllowTeamSwap(_gameUpdates ? Object.keys(_gameUpdates).includes(sentinelType.allowTeamSwap) : false);
      },
      (error) => {
        console.error(error);
        throw error;
      },
    );
  }, [ctx.db]);

  useEffect(() => {
    if (ctx.user) {
      return onSnapshot(
        query(
          collection(ctx.db, 'competitions', ACTIVE_TOURNAMENT, 'fixtures'),
          where('date', '>', new Date(Date.now() - 60 * 110 * 1000)),
          orderBy('date', 'asc'),
        ),
        (snapshot) => {
          setFixtures(snapshot.docs.map((x) => x.data() as Fixture));
        },
        (error) => {
          console.error(error);
          throw error;
        },
      );
    } else {
      setFixtures([]);
    }
  }, [ctx.db, ctx.user]);

  useEffect(() => {
    if (ctx.user) {
      return onSnapshot(
        query(collection(ctx.db, 'leagues'), where('memberUIDs', 'array-contains', ctx.user.uid)),
        (snapshot) => {
          setLeagues(toDict(snapshot));
          setLeagueKeys(snapshot.docs.map((x) => x.id));
        },
        (error) => {
          console.error(error);
          throw error;
        },
      );
    } else {
      setLeagues({});
      setLeagueKeys([]);
    }
  }, [ctx.db, ctx.user]);

  useEffect(() => {
    if (ctx.user && leagueKeys && leagueKeys.length > 0) {
      return onSnapshot(
        query(collectionGroup(ctx.db, 'members'), where('userID', '==', ctx.user.uid)),
        (snapshot) => {
          setMemberData(toDict(snapshot, (x) => x.ref.parent.parent!.id));
        },
        (error) => {
          console.error(error);
          throw error;
        },
      );
    } else {
      setMemberData({});
    }
  }, [ctx.db, ctx.user, leagueKeys]);

  const outletContext = {
    leagues: leagues,
    userData: props.userData,
    teamSelectionData: props.teamSelectionData,
    fixtures: fixtures,
    memberData: memberData ?? {},
    tournamentStarted,
    gameUpdates: gameUpdates,
  };

  const onboardingRulesModalDisclosure = useDisclosure();
  const onboardingListModalDisclosure = useDisclosure();
  const teamSwapExplanationModalDisclosure = useDisclosure();
  const greenTextColour = useColorModeValue('green.500', 'green.300');

  function onRulesPromptClose() {
    onboardingRulesModalDisclosure.onClose();
    setListPromptDismissed(true);
  }

  function onRulesPromptNext() {
    onboardingRulesModalDisclosure.onClose();
    onboardingListModalDisclosure.onOpen();
  }

  function onListPromptClose() {
    onboardingListModalDisclosure.onClose();
    setListPromptDismissed(true);
  }

  useEffect(() => {
    if (props.teamSelectionData && Object.keys(props.teamSelectionData).length !== 0) {
      const {
        countryList = [],
        latecomer = undefined,
        listIndexSwapPair = null,
      } = props.teamSelectionData[ACTIVE_TOURNAMENT] ?? {};
      if (
        props.teamSelectionData[ACTIVE_TOURNAMENT] &&
        (!countryList || countryList.length < 10) &&
        !latecomer &&
        !listPromptDismissed &&
        !localStorage.getItem('pendingList')
      ) {
        onboardingRulesModalDisclosure.onOpen();
      }
      if (
        props.teamSelectionData[ACTIVE_TOURNAMENT] &&
        !latecomer &&
        !listPromptDismissed &&
        !listIndexSwapPair &&
        allowTeamSwap
      ) {
        teamSwapExplanationModalDisclosure.onOpen();
      }
    }
  }, [props.userData, listPromptDismissed]);

  if (ctx.userDataPresent && !ctx.authLoading) {
    if (ctx.user) {
      if (!ctx.user.emailVerified) {
        return <Navigate replace to={`/verifyEmail?redirect_to=${loc.pathname}`} relative='path' />;
      } else {
        if (!!props.userData && (props.userData.name ?? '').length <= 0) {
          return <EnterNamePage />;
        } else if (!!props.userData && !!leagues) {
          return (
            <>
              <Outlet context={outletContext as ContextType} />
              <RulesModal
                onClose={onRulesPromptClose}
                isOpen={onboardingRulesModalDisclosure.isOpen}
                prompt={!tournamentStarted}
                onNext={onRulesPromptNext}
              />
              <EditCountryListModal
                onClose={onListPromptClose}
                isOpen={onboardingListModalDisclosure.isOpen}
                getStartingList={() => []}
                getStartingGoalsGuess={() => 0}
                gameUpdates={gameUpdates}
                prompt
              />
              <Modal {...teamSwapExplanationModalDisclosure}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    <Heading fontSize={28}>New Rule: Swap Two Teams!</Heading>
                  </ModalHeader>
                  <ModalCloseButton color='black' mt={2} mr={2} />
                  <ModalBody>
                    <VStack spacing={4}>
                      <HStack>
                        <Heading>New rule,</Heading>
                        <Heading color={greenTextColour}>surprise!</Heading>
                      </HStack>
                      <Text fontWeight={'bolder'}>...and less than 24 hours to act on it</Text>
                      <Spacer h={4} />
                      <Text>
                        You now have the opportunity to swap <strong>two teams</strong> in your list to try to maximise
                        points. Choose your swap carefully!
                      </Text>
                      <Image src={TeamSwapImage} />
                      <Text>
                        You can only make <strong>one</strong> swap of two teams, and this will be the only opportunity
                        for you to alter your list during the tournament.
                      </Text>
                      <Text>
                        The swap option will be available between <strong>9:30pm Friday</strong> and{' '}
                        <strong>2:30pm Saturday</strong> via the <strong>Edit List</strong> button.
                      </Text>
                      <Text>
                        Your editied list will come into effect at the beginning of the <strong>Round of 16</strong>
                      </Text>
                      <Spacer h={4} />
                      <Text fontWeight={'bolder'}>Best of luck!</Text>
                    </VStack>
                  </ModalBody>
                  <ModalFooter>
                    <HStack>
                      <Button onClick={teamSwapExplanationModalDisclosure.onClose} colorScheme='green'>
                        Ok
                      </Button>
                    </HStack>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          );
        } else {
          return (
            <Center width='100vw' height='100vh'>
              <VStack spacing={4}>
                {!(props.userProfileExists ?? true) ? ( // We don't want to show this message by mistake so when the account hasn't been fetched yet we assume it exists
                  <Heading fontSize={24}>Creating your account.</Heading>
                ) : null}
                <Spinner />
              </VStack>
            </Center>
          );
        }
      }
    } else {
      return <Navigate replace to={`${props.redirectTo}?redirect_to=${loc.pathname}`} relative='path' />;
    }
  } else {
    return (
      <Center width='100vw' height='100vh'>
        <Spinner />
      </Center>
    );
  }
}

export function useAuthContext() {
  return useOutletContext<ContextType>();
}
