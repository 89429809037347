import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { type KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { parseNumberInput } from '../../helpers';

export function NumberInputModal(props: {
  title: string;
  label: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: number) => Promise<any> | undefined;
  getInitialValue?: () => number;
  isValid?: (s: number) => boolean;
  helperText?: string;
  max?: number;
  min?: number;
}) {
  const getInitialValue = useMemo(() => props.getInitialValue ?? (() => 0), [props.getInitialValue]);
  const [value, setValue] = useState(getInitialValue());
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => setValue(getInitialValue()), [props.isOpen, getInitialValue]);

  const isValid = (props.isValid ?? ((_) => true))(value);

  async function onSubmit() {
    if (isValid) {
      setIsBusy(true);
      await props.onSubmit(value);
      props.onClose();
      setIsBusy(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={28}>{props.title}</Heading>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody>
          <FormControl>
            <FormLabel>{props.label}</FormLabel>
            <NumberInput
              min={props.min}
              max={props.max}
              value={value}
              onChange={(x) => setValue(parseNumberInput(x))}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  onSubmit();
                }
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isBusy} isDisabled={!isValid} colorScheme='green' onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
