import {
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Grid,
  GridItem,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import type { Country } from '@/models/country';
import { updateTeamSelectionData, updateUser } from '@/models/user';
import { CountryTag, FirebaseContext } from '../';
import { getServerTimestamp } from '@/helpers';

export function SwapTeamModal(props: {
  countryList: Country[];
  isOpen: boolean;
  onClose: () => void;
  getStartingSwap: () => [number, number] | null;
}) {
  const ctx = useContext(FirebaseContext);
  const [isBusy, setIsBusy] = useState(false);
  const [swapPair, setSwapPair] = useState<string[]>(props.getStartingSwap()?.map((x) => x.toString()) ?? []);

  useEffect(() => {
    setSwapPair(props.getStartingSwap()?.map((x) => x.toString()) ?? []);
  }, [props.isOpen]);
  const inputValid = swapPair.length === 0 || swapPair.length === 2;

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <VStack align='stretch'>
            <Heading fontSize={28}>Swap Two Teams</Heading>
            <Text fontSize={16}>
              You can change this until the round of 16 begins. Your new list will come into effect after the end of the
              next game.
            </Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody>
          <CheckboxGroup
            value={swapPair}
            colorScheme='green'
            size={'lg'}
            onChange={(value) => setSwapPair(value as string[])}
          >
            <Center>
              <Grid templateColumns={'auto auto auto'} rowGap={3} columnGap={6}>
                {props.countryList.map((x, i) => (
                  <Fragment key={i}>
                    <GridItem rowStart={i + 1} colStart={1}>
                      <Center height={'full'} width='full' pb='0.5'>
                        <Heading width='full' textAlign={'center'} fontSize={20}>
                          {i + 1}
                        </Heading>
                      </Center>
                    </GridItem>
                    <GridItem rowStart={i + 1} colStart={2}>
                      <Center height='full' width='full'>
                        <CountryTag country={x} />
                      </Center>
                    </GridItem>
                    <GridItem rowStart={i + 1} colStart={3}>
                      <Center height='full'>
                        <Checkbox
                          value={i.toString()}
                          isDisabled={swapPair.length >= 2 && !swapPair.includes(i.toString())}
                        />
                      </Center>
                    </GridItem>
                  </Fragment>
                ))}
              </Grid>
            </Center>
          </CheckboxGroup>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              isLoading={isBusy}
              isDisabled={!inputValid}
              colorScheme='green'
              onClick={async () => {
                setIsBusy(true);
                await updateTeamSelectionData(ctx.db, ctx.user!.uid, {
                  // Type assertion valid due to isDisabled={!inputValid} check.
                  listIndexSwapPair:
                    swapPair.length > 0 ? (swapPair.map((x) => Number.parseInt(x)) as [number, number]) : null,
                  swapUpdatedAt: getServerTimestamp(),
                });
                await updateUser(ctx.db, ctx.user!.uid, {
                  requiresShareUpdate: true,
                });
                props.onClose();
                setIsBusy(false);
              }}
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
