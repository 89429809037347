import { Button, FormControl, FormLabel, Heading, Input, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { type KeyboardEvent, useContext, useState } from 'react';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { FirebaseContext } from '../FirebaseContext';
import { BaseAuthCard } from './base-card';

export function ForgotPasswordCard(props: { triggerSignup: () => void }) {
  const { auth } = useContext(FirebaseContext);

  const [email, setEmail] = useState<string>('');
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(auth);
  const [signInEmailSent, setSignInEmailSent] = useState<boolean>(false);

  const actionCodeSettings = {
    url: `${window.location.origin}/profile`,
    handleCodeInApp: true,
  };

  const handleEmail = async () => {
    setSignInEmailSent(false);
    const success = await sendPasswordResetEmail(email, actionCodeSettings);
    if (success) {
      setSignInEmailSent(true);
    }
  };

  return (
    <BaseAuthCard>
      <Heading fontSize={28}>Recover your password</Heading>
      <Text py={1}>Get instructions sent to this email that explain how to reset your password</Text>
      <VStack gap={2} pt={4}>
        <FormControl>
          <FormLabel mb={0}>Email</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            autoComplete='email'
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') handleEmail();
            }}
          />
        </FormControl>
        <Button
          isDisabled={signInEmailSent}
          onClick={handleEmail}
          width='full'
          backgroundColor={useColorModeValue('gray.200', 'white')}
          color='black'
          _hover={{}}
        >
          {signInEmailSent ? 'Sent' : sending ? 'Sending...' : 'Send recovery email'}
        </Button>

        {error && <Text color='red.500'>Error: {error.message}</Text>}
      </VStack>
    </BaseAuthCard>
  );
}
