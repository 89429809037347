import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { type KeyboardEvent, useContext, useState } from 'react';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { errorToastOptions, isValidEmail } from '@/helpers';
import { FirebaseContext } from '../FirebaseContext';
import { AuthDivider, GoogleButton } from './utils';
import { BaseAuthCard } from './base-card';

export function SignUpCard(props: { triggerLogin: () => void }) {
  const { auth } = useContext(FirebaseContext);
  const toast = useToast();

  const [email, setEmail] = useState<string>('');
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [createAttempted, setCreateAttempted] = useState<boolean>(false);
  const [signInEmailSent, setSignInEmailSent] = useState<boolean>(false);

  const handleCreate = async () => {
    setCreateAttempted(true);
    if (isValidEmail(email)) {
      setIsBusy(true);
      // User can sign in with email/link.
      sendSignInLinkToEmail(auth, email, {
        url: `${window.location.origin}/profile`,
        handleCodeInApp: true,
      }).catch((error) => {
        toast(
          errorToastOptions(
            'Something went wrong',
            'Please refresh the page and try again later or contact hello@tenteams.co.uk',
          ),
        );
        // console.error('sign in email link error', error);
        // Some error occurred, you can inspect the code: error.code
      });
      setSignInEmailSent(true);
      setIsBusy(false);
      localStorage.setItem('emailForSignIn', email);
    }
  };

  return (
    <BaseAuthCard>
      <Heading fontSize={28}>Create an account</Heading>
      <Text py={1}>
        Already have an account?{' '}
        <Button variant='link' onClick={props.triggerLogin}>
          <Text as='b'>Log In</Text>
        </Button>
      </Text>
      <VStack gap={2} pt={2}>
        <FormControl isInvalid={createAttempted && !isValidEmail(email)} isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            autoComplete='email'
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter' && !signInEmailSent) {
                handleCreate();
              }
            }}
          />
          <FormErrorMessage>A valid email is required.</FormErrorMessage>
        </FormControl>
        <Button
          isDisabled={signInEmailSent || (createAttempted && !isValidEmail(email))}
          isLoading={isBusy}
          onClick={handleCreate}
          width='full'
          backgroundColor={useColorModeValue('gray.200', 'white')}
          color='black'
          _hover={{}}
          mt={2}
        >
          {signInEmailSent ? 'Check your email to complete sign-in' : 'Create Account'}
        </Button>
      </VStack>
      <AuthDivider />
      <HStack pb={4}>
        <GoogleButton auth={auth}>Sign up with Google</GoogleButton>
      </HStack>
      <Center>
        <Text align={'center'}>
          {/* TODO: Add Terms of Service here when ready */}
          By continuing, you agree to our{' '}
          <Link to='/Privacy_Policy.pdf' target='_blank' rel='noopener noreferrer'>
            Privacy Policy
          </Link>
        </Text>
      </Center>
    </BaseAuthCard>
  );
}
