import { useParams } from "react-router-dom";
import { getPostBySlug, getRelatedPosts } from "../../posts";
import { BlogPostContent } from "../../posts/components/BlogPostContent";
import SEO from "../../posts/components/SEO";
import type { PostSlugType } from "../../posts/post.types";
import Layout from "../Layout";

const ScoutPost = () => {
  const { slug } = useParams<{ slug: PostSlugType }>();

  const post = getPostBySlug(slug);
  const relatedPosts = getRelatedPosts(post) ?? [];
  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <Layout>
      <SEO
        contentType="article"
        title={post.title}
        description={post.excerpt}
        image={post.image}
        lang="en"
      />
      <BlogPostContent post={post} relatedPosts={relatedPosts} />
    </Layout>
  );
};

export { ScoutPost };
