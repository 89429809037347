import type { IPost, PostSlugType } from "./post.types";
import {
  HOW_TO_WIN_EVERY_GAME,
  PERFECT_RANK_STRATEGY_GUIDE,
  THE_ULTIMATE_LEAGUE_GUIDE,
} from "./articles";

export const posts: IPost[] = [
  HOW_TO_WIN_EVERY_GAME,
  PERFECT_RANK_STRATEGY_GUIDE,
  THE_ULTIMATE_LEAGUE_GUIDE,
];

export const getPostBySlug = (slug?: PostSlugType) => {
  return posts.find((post) => post.slug === slug);
};

export const getRelatedPosts = (post?: IPost): IPost[] => {
  if (!post || !post.relatedPosts) {
    return [];
  }
  return post.relatedPosts
    .map((slug) => getPostBySlug(slug))
    .filter((post) => post !== undefined) as IPost[];
};
