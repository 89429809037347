import { FaPlayCircle } from "react-icons/fa";
import type { IPost } from "../post.types";

export const PERFECT_RANK_STRATEGY_GUIDE: IPost = {
  slug: "perfect-rank-strategy-guide",
  title: "A Guide to Perfect Rank Strategy",
  excerpt:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  content: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    <br><br>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    `,
  publishedAt: "2021-01-01",
  image: "https://picsum.photos/id/16/367/267",
  imageAlt: "cover image for a guide to perfect rank strategy article",
  isFeatured: true,
  relatedPosts: ["how-to-win-every-game", "the-ultimate-league-guide"],
  author: "Ben White",
  readingTime: "5 mins",
  cardConfig: {
    contentColor: "white",
    icon: FaPlayCircle,
    variant: "full-image",
  },
};
