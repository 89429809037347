import {
  Button,
  Checkbox,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import { type ChangeEvent, useContext, useState } from 'react';
import { errorToastOptions, successToastOptions } from '@/helpers';
import { FirebaseContext } from '../FirebaseContext';

export function SendEmailModal(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const ctx = useContext(FirebaseContext);
  const toast = useToast();
  const [isBusy, setIsBusy] = useState(false);
  const [emailSubject, setEmailSubject] = useState('Euro 2024 Update - Day XX');
  const [emailID, setEmailID] = useState('match-wwc-day-xx');
  const [templateFile, setTemplateFile] = useState('');
  const [isTest, setIsTest] = useState(true);
  const [testEmail, setTestEmail] = useState('');

  const isValid = templateFile && emailSubject && emailID && (!isTest || (isTest && testEmail));

  async function onSubmit() {
    if (isValid) {
      setIsBusy(true);
      const cloudFunc = httpsCallable(ctx.funcs, 'sendDailyUpdateEmail', { timeout: 530000 });
      await cloudFunc({ emailID, emailSubject, html: templateFile, ...(isTest && { testEmail }) }).then(
        (result) => {
          const response = result.data as { outcome: boolean; sent: number };
          toast(successToastOptions('Success!', `${response.sent} emails were sent!`));
        },
        (error) => {
          console.error('Error sending emails ', error);
          toast(errorToastOptions('Failed To send emails', `${error.message}`));
        },
      );
      setIsBusy(false);
      props.onClose();
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={28}>Send Email to Active Users</Heading>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody>
          <VStack>
            <FormControl>
              <Input
                type='file'
                accept='.html'
                py={4}
                h='full'
                onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                  const file = e.target!.files![0];
                  const fileContent = await file.text();
                  console.log('.text()', fileContent);
                  setTemplateFile(fileContent);
                }}
              />
            </FormControl>

            <FormControl>
              <InputGroup>
                <InputLeftAddon>Email ID: </InputLeftAddon>
                <Input
                  placeholder='email id'
                  value={emailID}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEmailID(e.target.value);
                  }}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftAddon>Subject: </InputLeftAddon>
                <Input
                  placeholder='email subject'
                  value={emailSubject}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEmailSubject(e.target.value);
                  }}
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={isTest}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setIsTest(e.target.checked);
                }}
              >
                Is Test?
              </Checkbox>
            </FormControl>
            {isTest && (
              <FormControl>
                <InputGroup>
                  <InputLeftAddon>Test Email: </InputLeftAddon>
                  <Input
                    placeholder='test email'
                    value={testEmail}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setTestEmail(e.target.value);
                    }}
                  />
                </InputGroup>
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isBusy} isDisabled={!isValid} colorScheme='green' onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
