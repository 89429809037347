import { VStack } from '@chakra-ui/react';
import type React from 'react';
import type { CountryCode } from '../../models/country';
import EU_BG from '../../assets/europe.svg';
import { HomeHeading } from './HomeHeading';
import DemoTeamList from './DemoTeamList';

const PredictSection: React.FC = () => {
  const teams: CountryCode[] = ['FR', 'GB_ENG', 'HU', 'HR', 'UA', 'DE', 'IT', 'TR', 'CZ', 'AL'];

  return (
    <VStack maxW='8xl' m='auto' justifyContent='center' bgImage={EU_BG} bgSize='contain' bgPos='top'>
      <HomeHeading mb='1em'>1. Predict the Best 10 Teams at Euro 2024</HomeHeading>
      <DemoTeamList />
    </VStack>
  );
};

export default PredictSection;
