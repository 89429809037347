import { DragHandleIcon } from '@chakra-ui/icons';
import { Box, HStack, Heading } from '@chakra-ui/react';
import { countriesInfo, type Country } from '@/models/country';
import { Flag } from './Flag';

export function CountryTag(props: { country: Country; opaque?: boolean; hasShadow?: boolean; draggable?: boolean }) {
  return (
    <HStack
      background={props.opaque ? 'listItemBackgroundOpaque' : 'listItemBackground'}
      borderRadius={6}
      spacing={3}
      p={1.5}
      width='full'
      userSelect={'none'}
      pr={props.draggable ? 2.5 : 4}
      boxShadow={props.hasShadow ? '0px 0px 3px 0.3px #3336' : undefined}
    >
      <Box minW={6}>
        <Flag country={props.country} borderRadius={3} h={6} w={6} />
      </Box>
      <Box width='full'>
        <Heading width='full' textAlign={'center'} fontSize={16}>
          {countriesInfo[props.country].name}
        </Heading>
      </Box>
      {props.draggable ? <DragHandleIcon fontSize={12} /> : null}
    </HStack>
  );
}
