import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
  FormControl,
  Input,
  FormLabel,
  Spacer,
} from '@chakra-ui/react';
import type { ParsedToken } from 'firebase/auth';
import { collection, collectionGroup, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { type FunctionsError, httpsCallable } from 'firebase/functions';
import { type ChangeEvent, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import type { User } from '@/models';
import { Country, countryCategory, tournamentCountriesInfo } from '@/models/country';
import { type gameUpdate, queryGameUpdates, sentinelType } from '@/models/gameUpdate';
import { queryLeagues } from '@/models/league';
import { queryUserTeams, queryUsers } from '@/models/user';
import { loadFixtures } from '@/models/fixture';
import { AdminGameManagement, AdminOldEmailForm, AuthedPageContainer, FirebaseContext } from '../components';
import { StringEntryModal, SendEmailModal } from '../components/modals';
import { type Dict, firebaseErrorToastOptions, isValidEmail, successToastOptions, toDict } from '../helpers';
import { ACTIVE_TOURNAMENT } from '../constants';

export default function AdminPage() {
  const ctx = useContext(FirebaseContext);
  const [claims, setClaims] = useState<ParsedToken>();
  const toggleAdminRightsDisclosure = useDisclosure();
  const sendEmailDisclosure = useDisclosure();
  const toast = useToast();
  const [adminUsers, setAdminUsers] = useState<Dict<User>>();
  const [gameUpdates, setGameUpdates] = useState<Dict<gameUpdate>>();
  const [allUsersUpToDate, setAllUsersUpToDate] = useState(true);
  const [allLeaguesUpToDate, setAllLeaguesUpToDate] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const cardBrackground = useColorModeValue('gray.50', 'gray.700');

  const [leagueUid, setLeagueUid] = useState('');

  useEffect(() => {
    if (ctx.user) {
      ctx.user.getIdTokenResult().then((result) => {
        setClaims(result.claims);
      });
    }
  }, [ctx.user]);

  useEffect(() => {
    if (claims?.admin) {
      return onSnapshot(queryUsers(ctx.db, where('admin', '==', true)), (snapshot) => {
        setAdminUsers(toDict(snapshot));
      });
    } else {
      setAdminUsers(undefined);
    }
  }, [claims, ctx.db]);

  useEffect(() => {
    if (claims?.admin) {
      return onSnapshot(queryGameUpdates(ctx.db, orderBy('timestamp', 'desc')), (snapshot) => {
        setGameUpdates(toDict(snapshot));
      });
    } else {
      setGameUpdates(undefined);
    }
  }, [claims, ctx.db]);

  useEffect(() => {
    if (claims?.admin) {
      if (gameUpdates) {
        const gameResultKeys = Object.keys(gameUpdates).filter(
          (x) => !(x in sentinelType) && gameUpdates[x].timestamp !== null,
        );
        const tournamentEnded = sentinelType.tournamentEnded in gameUpdates;
        if (gameResultKeys.length > 0) {
          console.log('query called');
          return onSnapshot(
            queryUserTeams(
              ctx.db,
              where('tournament', '==', ACTIVE_TOURNAMENT),
              where(
                'scoreLastUpdated',
                '<',
                tournamentEnded ? gameUpdates.tournamentEnded.timestamp : gameUpdates[gameResultKeys[0]].timestamp,
              ),
              where(
                'countryList',
                'array-contains-any',
                Object.values(tournamentCountriesInfo)
                  .filter((x) => x.category === countryCategory.Outsider)
                  .map((x) => x.id),
                // We're just checking that their list isn't empty, and we can do this by checking the outsiders which it must contain it has any elements.
              ),
              limit(1),
            ),
            (snapshot) => {
              setAllUsersUpToDate(snapshot.empty);
            },
          );
        } else if (Object.keys(gameUpdates).length < 1) {
          return onSnapshot(
            queryUserTeams(
              ctx.db,
              where('tournament', '==', ACTIVE_TOURNAMENT),
              where('currentScore', '!=', 0),
              limit(1),
            ),
            (snapshot) => {
              setAllUsersUpToDate(snapshot.empty);
            },
          );
        } else {
          setAllUsersUpToDate(true);
        }
      }
    } else {
      setAllUsersUpToDate(true);
    }
  }, [claims, ctx.db, gameUpdates]);

  useEffect(() => {
    if (claims?.admin) {
      if (gameUpdates) {
        const gameResultKeys = Object.keys(gameUpdates).filter(
          (x) => !(x in sentinelType) && gameUpdates[x].timestamp !== null,
        );
        const tournamentEnded = sentinelType.tournamentEnded in gameUpdates;
        if (gameResultKeys.length > 0) {
          return onSnapshot(
            queryLeagues(
              ctx.db,
              where(
                'ranksLastUpdated',
                '<',
                tournamentEnded ? gameUpdates.tournamentEnded.timestamp : gameUpdates[gameResultKeys[0]].timestamp,
              ),
              limit(1),
            ),
            (snapshot) => {
              setAllLeaguesUpToDate(snapshot.empty);
            },
          );
        } else if (Object.keys(gameUpdates).length < 1) {
          return onSnapshot(
            query(collectionGroup(ctx.db, 'members'), where('currentRank', '!=', -1), limit(1)),
            (snapshot) => {
              setAllLeaguesUpToDate(snapshot.empty);
            },
          );
        } else {
          setAllLeaguesUpToDate(true);
        }
      }
    } else {
      setAllLeaguesUpToDate(true);
    }
  }, [claims, ctx.db, gameUpdates]);

  async function toggleAdminRights(email: string) {
    try {
      const result = (await httpsCallable(ctx.funcs, 'toggleAdminRights')({ email: email })).data as { admin: boolean };
      toast(successToastOptions('Success!', `${email} is ${result.admin ? 'now' : 'no longer'} an admin.`));
    } catch (e) {
      toast(firebaseErrorToastOptions('User Not Found', e as FunctionsError));
    }
  }

  async function fixSpectators() {
    try {
      await httpsCallable(ctx.funcs, 'setAllSpectatorsToRankPop')();
      toast(successToastOptions('Fix Successful', 'Successfully Ran.'));
    } catch (e) {
      toast(firebaseErrorToastOptions('Calculations Error', e as FunctionsError));
    }
  }

  async function forceUpdateShareLinks() {
    try {
      await httpsCallable(ctx.funcs, 'updateRequireShareUpdate')();
      toast(successToastOptions('Run Successful', 'Share link updates successfully ran.'));
    } catch (e) {
      toast(firebaseErrorToastOptions('Functions Error', e as FunctionsError));
    }
  }

  async function copyAllUserEmails() {
    const snapshot = await getDocs(collection(ctx.db, 'users'));
    navigator.clipboard.writeText(snapshot.docs.map((x) => (x.data() as User).email).join('; '));
    toast(successToastOptions('Success!', 'Email addresses successfully copied to clipboard :)'));
  }

  // async function copyAllNoListUserEmails() {
  //   const snapshot = await getDocs(collection(ctx.db, 'users'));
  //   const accountsNoList = snapshot.docs.filter((user) => (user.data() as User).countryList.length === 0);
  //   navigator.clipboard.writeText(accountsNoList.map((x) => (x.data() as User).email).join('; '));
  //   toast(
  //     successToastOptions(
  //       'Success!',
  //       'Email addresses that have not completed their list successfully copied to clipboard :)',
  //     ),
  //   );
  // }

  // async function generateImages() {
  //   setIsBusy(true);

  //   const accountsWithList = (await getDocs(collection(ctx.db, 'users'))).docs
  //     .filter((user) => (user.data() as User).countryList.length != 0)
  //     .map((user) => user.id);

  //   console.log(accountsWithList);

  //   const BATCH_SIZE = 100;

  //   const batches = Math.ceil(accountsWithList.length / BATCH_SIZE);
  //   for (let i = 0; i < batches; i++) {
  //     httpsCallable(ctx.funcs, 'generateImages', { timeout: 530000 })(
  //       accountsWithList.slice(i * BATCH_SIZE, Math.min((i + 1) * BATCH_SIZE, accountsWithList.length)),
  //     );
  //   }
  //   setIsBusy(false);
  // }

  async function generateMatrix() {
    const options = { leagueUid: leagueUid };

    setIsBusy(true);
    try {
      const result = (await httpsCallable(ctx.funcs, 'getListMatrixForLeague', { timeout: 530000 })(options))
        .data as Array<Array<number>>;
      if (result) {
        console.log(result);
        toast(successToastOptions('Success!', 'Matrix has been generated. Check console logs.'));
        setLeagueUid('');
      }
    } catch (e) {
      toast(firebaseErrorToastOptions('Generate List Matrix Error', e as FunctionsError));
    }
    setIsBusy(false);
  }

  // async function downloadArchive() {
  //   const allListData = (await getDocs(collection(ctx.db, 'users'))).docs
  //     .filter((user) => (user.data() as User).countryList.length != 0)
  //     .map((user) => ({
  //       userID: user.id,
  //       countryList: (user.data() as User).countryList,
  //       listIndexSwapPair: (user.data() as User).listIndexSwapPair,
  //     }));

  //   const toDownload = {
  //     countriesInfo: countriesInfo,
  //     listData: allListData,
  //     gameUpdates: gameUpdates,
  //   };

  //   const filename = 'archive.json';
  //   const contentType = 'application/json;charset=utf-8;';
  //   const a = document.createElement('a');
  //   a.download = filename;
  //   a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(toDownload));
  //   a.target = '_blank';
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  async function downloadFlags() {
    const v = Object.values(Country);
    for (let i = 0; i < v.length / 2; i++) {
      if (v[i] !== Country[Country.TBD]) {
        const fName = `${(v[i] as string).toLowerCase().replace('_', '-')}.svg`;
        const a = document.createElement('a');
        a.download = `${v[i]}.svg`;
        a.href = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/${fName}`;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  }

  if (ctx.userDataPresent) {
    if (claims) {
      if (claims.admin) {
        if (!!adminUsers && !!gameUpdates) {
          const tournamentStarted = sentinelType.tournamentStarted in gameUpdates;
          const teamSwapAllowed = sentinelType.allowTeamSwap in gameUpdates;
          const tournamentEnded = sentinelType.tournamentEnded in gameUpdates;

          const adminUserCards = Object.keys(adminUsers).map((x) => (
            <Box key={x} bg={cardBrackground} borderRadius='lg' p={3} width='full'>
              <VStack fontSize={16} spacing={0}>
                <Heading fontSize={18}>{adminUsers[x].name}</Heading>
                <Text>{adminUsers[x].email}</Text>
              </VStack>
            </Box>
          ));

          console.log(allUsersUpToDate, allLeaguesUpToDate);

          return (
            <AuthedPageContainer>
              <Grid w='full' h='full' templateRows='auto auto 1fr' templateColumns='repeat(2, 1fr)' p={8} gap={8}>
                <GridItem rowStart={1} colStart={1} colSpan={2}>
                  <HStack height={'min-content'} width='full'>
                    <Link to='/profile'>
                      <IconButton position='static' top={24} left={8} aria-label='back' icon={<ArrowBackIcon />} />
                    </Link>
                    <Spacer />
                    <Heading>Admin Console</Heading>
                    <Spacer />
                  </HStack>
                </GridItem>
                <GridItem rowStart={2} colStart={2}>
                  <VStack>
                    <Heading>
                      The tournament
                      {tournamentStarted
                        ? tournamentEnded
                          ? ' has ended.'
                          : ' is in progress.'
                        : " hasn't started yet."}
                    </Heading>
                    <Text>
                      Players
                      {tournamentStarted
                        ? teamSwapAllowed
                          ? ' are currently allowed to swap one team.'
                          : ' are not allowed to edit their list.'
                        : ' are allowed to edit their list as they please.'}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem rowStart={2}>
                  <>
                    <Heading fontSize={24}>Admins: </Heading>
                  </>
                </GridItem>
                <GridItem rowStart={3} colStart={2}>
                  <Heading fontSize={24}>Game Results:</Heading>
                </GridItem>
                <GridItem rowStart={3}>
                  <VStack>
                    {/* Exists only for one-off use in loading in fixtures from JSON file. */}
                    <Button colorScheme={'red'} onClick={() => loadFixtures(ctx.db)}>
                      Load KO Fixtures - DO NOT CLICK
                    </Button>
                    <HStack width='full' align='center' justify='center'>
                      <Button colorScheme={'blue'} onClick={toggleAdminRightsDisclosure.onOpen}>
                        Add / Remove Admin
                      </Button>
                      <Button colorScheme={'blue'} onClick={copyAllUserEmails}>
                        Copy All User Emails
                      </Button>
                      {/* <Button colorScheme={'blue'} onClick={copyAllNoListUserEmails}>
                        Copy All Incomplete Users Emails
                      </Button> */}
                      <Button colorScheme={'blue'} onClick={() => fixSpectators()}>
                        Fix Spectators
                      </Button>
                    </HStack>
                    <HStack>
                      {/* <Button
                      colorScheme={'blue'}
                      onClick={downloadArchive}
                    >
                      Download Archive
                    </Button> */}
                      <Button colorScheme={'blue'} onClick={downloadFlags}>
                        Download Flags
                      </Button>
                    </HStack>
                    <HStack>
                      <Button colorScheme={'blue'} onClick={sendEmailDisclosure.onOpen}>
                        Send Daily Email Update
                      </Button>
                    </HStack>
                    <Button colorScheme={'green'} onClick={forceUpdateShareLinks}>
                      Force update all share links
                    </Button>
                    <div style={{ height: '50px' }} />
                    <AdminOldEmailForm />
                    {/* <HStack>
                      <Button colorScheme={'blue'} onClick={generateImages} isLoading={isBusy}>
                        Generate user list images
                      </Button>
                    </HStack> */}

                    <HStack align='end' pt={12}>
                      <FormControl>
                        <FormLabel>Generate League Matrix</FormLabel>
                        <Input
                          placeholder='League UID'
                          value={leagueUid}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setLeagueUid(e.target.value);
                          }}
                        />
                      </FormControl>
                      <Button colorScheme={'blue'} onClick={generateMatrix} isLoading={isBusy}>
                        Generate
                      </Button>
                    </HStack>
                    <VStack pt={8}>{adminUserCards}</VStack>
                  </VStack>
                </GridItem>
                <GridItem rowStart={3} colStart={2}>
                  <AdminGameManagement />
                </GridItem>
              </Grid>
              <StringEntryModal
                title='Add / Remove Admin'
                label='Enter the relevant email address.'
                isOpen={toggleAdminRightsDisclosure.isOpen}
                onClose={toggleAdminRightsDisclosure.onClose}
                onSubmit={(email) => toggleAdminRights(email)}
                isValid={(email) => isValidEmail(email)}
              />
              <SendEmailModal isOpen={sendEmailDisclosure.isOpen} onClose={sendEmailDisclosure.onClose} />
            </AuthedPageContainer>
          );
        } else return null;
      } else return <Navigate replace to='../profile' />;
    } else
      return (
        <Center width='100vw' height='100vh'>
          <Spinner />
        </Center>
      );
  } else
    return (
      <Center width='100vw' height='100vh'>
        <Spinner />
      </Center>
    );
}
