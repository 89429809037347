import type React from 'react';
import {
  VStack,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react';
import { AiOutlineUpCircle, AiOutlineDownCircle } from 'react-icons/ai';
import './home.css';
import Button from '../../components/Button';

const FAQSection: React.FC = () => {
  const faqData: { question: string; answer?: string }[] = [
    {
      question: 'Do I just rank the 10 Teams I think will do best at the Euros?',
      answer: 'Yes! It takes just 2 minutes',
    },
    {
      question: 'Can I change my list once the Euros begin on Friday 14th June?',
      answer: 'No! So be careful. Your list will be locked in. No transfers allowed.',
    },
    {
      question: 'What are leagues?',
      answer:
        'Leagues are where you test whether you really know your stuff and can compete with friends, family, and colleagues.',
    },
    {
      question: "Why can't I choose 6 seeds?",
      answer: 'That would be too easy! You’re only allowed 4 Seeds and you must choose 2 Outsiders.',
    },
    {
      question: 'Do I have to be a football fanatic?',
      answer: 'Absolutely not. Football is always unpredictable and every tournament has its upsets. Anyone can win',
    },
  ];

  return (
    <VStack
      pb='24px'
      px={{
        base: '1em',
        md: '24px',
      }}
    >
      <Heading
        mb='1em'
        fontStyle='italic'
        textAlign='center'
        color='black'
        fontWeight={700}
        w={{
          base: '60%',
          md: '100%',
        }}
        fontSize={{
          base: '20px',
          md: '24px',
          lg: '34px',
          xl: '48px',
        }}
      >
        Frequently Asked Questions
      </Heading>
      <Accordion maxW='container.md' w='full' allowToggle>
        <Flex flexDir='column' w='100%' gap='14px'>
          {faqData.map(({ question, answer }, index) => (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    border='none'
                    borderColor='white'
                    py='1.0em'
                    bgColor='#F6F7F9'
                    borderTopRadius='8px'
                    borderBottomRadius={isExpanded && answer ? 0 : '8px'}
                    overflowX='hidden'
                  >
                    <Box as='span' flex='1' textAlign='left'>
                      <Text
                        as='b'
                        fontWeight={600}
                        fontSize={{
                          base: '14px',
                          md: '18px',
                        }}
                      >
                        {question}
                      </Text>
                    </Box>
                    {isExpanded ? (
                      <AiOutlineUpCircle size='24px' fill='#27ADE4' />
                    ) : (
                      <AiOutlineDownCircle size='24px' fill='#27ADE4' />
                    )}
                  </AccordionButton>
                  {answer && (
                    <AccordionPanel
                      pb={4}
                      bgColor='#F6F7F9'
                      borderBottomRadius='8px'
                      color='grey'
                      textAlign='left'
                      fontSize={{
                        base: '14px',
                        md: '16px',
                      }}
                    >
                      {answer}
                    </AccordionPanel>
                  )}
                </>
              )}
            </AccordionItem>
          ))}
        </Flex>
      </Accordion>
    </VStack>
  );
};

export default FAQSection;
