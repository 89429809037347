import {
  Box,
  Button,
  Center,
  type ChakraProps,
  Code,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  SimpleGrid,
  Spacer,
  Tag,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
  useBreakpointValue,
  LightMode,
  type ResponsiveValue,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import {
  type DocumentData,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  type Query,
  type QueryDocumentSnapshot,
  startAfter,
  startAt,
} from 'firebase/firestore';
import { type FunctionsError, httpsCallable } from 'firebase/functions';
import { type PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon, CopyIcon, HamburgerIcon, RepeatIcon } from '@chakra-ui/icons';
import type { leagueMember } from '@/models/user';
import type League from '@/models/league';
import { deleteLeague as _deleteLeague, queryLeagueMembers, updateLeague } from '@/models/league';
import { ACTIVE_TOURNAMENT, worldPopulation } from '../constants';
import { type Dict, firebaseErrorToastOptions, ordinalSuffix, toDict } from '../helpers';
import { AuthedPageContainer, FirebaseContext, RankChangeIndicator } from '../components';
import { ConfirmModal, EditCountryListModal, StringEntryModal } from '../components/modals';
import smolLight from '../assets/logos/smolLight.svg';
import smolDark from '../assets/logos/smolDark.svg';
import { useAuthContext } from './AuthLayout';
import { sentinelType } from '@/models/gameUpdate';

function Card(props: ChakraProps & PropsWithChildren) {
  const cardBrackground = useColorModeValue('gray.50', 'gray.700');
  const ref = useRef(null);
  return <Box ref={ref} background={cardBrackground} width='full' padding={8} borderRadius={24} {...props} />;
}

function LTag(label: string) {
  const fontSize = useBreakpointValue<ResponsiveValue<number>>({
    base: 14,
    md: 16,
  });

  const maxW = useBreakpointValue<ResponsiveValue<string>>({
    base: '5em',
    md: '6em',
  });

  const colorScheme = useColorModeValue('blackAlpha', 'whiteAlpha');

  return (
    <LightMode>
      <Tag fontSize={fontSize} size='md' colorScheme={colorScheme} w='full' maxW={maxW}>
        {label}
      </Tag>
    </LightMode>
  );
}

const SpectatorTag = () => LTag('Spectator');
const LatecomerTag = () => LTag('Latecomer');

function InviteCard(props: { id: any; isAdmin: boolean; copyString: (s: string) => Promise<void>; league: League }) {
  const ctx = useContext(FirebaseContext);
  const { id, isAdmin, copyString, league } = props;
  const toast = useToast();
  const [isRefreshingPin, setIsRefreshingPin] = useState(false);
  const joinLink = `${window.location.host}/join/${league.authCode}`;

  const cardPadX = useBreakpointValue({ base: 4, md: 8 });
  const memberDetailTextSize = useBreakpointValue<ResponsiveValue<number>>({
    base: 14,
    md: 16,
  });
  const codeTextSize = useBreakpointValue<ResponsiveValue<number>>({
    base: 12,
    md: 14,
  });

  async function RefreshPin() {
    setIsRefreshingPin(true);
    try {
      await httpsCallable(ctx.funcs, 'resetLeaguePin')({ leagueUID: id });
    } catch (error) {
      toast(firebaseErrorToastOptions('Failed To Refresh Pin', error as FunctionsError));
    } finally {
      setIsRefreshingPin(false);
    }
  }

  return (
    <Card px={cardPadX}>
      <Grid templateColumns={'auto auto auto'} templateRows={'repeat(3, 1fr)'} gap={2}>
        <GridItem colSpan={3}>
          <Center pb={4}>
            <Heading fontSize={24}>Invite</Heading>
          </Center>
        </GridItem>
        <GridItem rowStart={2}>
          <Center height='full' pr={2}>
            <Heading fontSize={memberDetailTextSize}>Pin:</Heading>
          </Center>
        </GridItem>
        <GridItem rowStart={2} colStart={2} colSpan={isAdmin ? 1 : 2}>
          <Button
            width='full'
            rightIcon={<CopyIcon />}
            onClick={() => copyString(league.authCode)}
            fontSize={memberDetailTextSize}
          >
            {league.authCode}
          </Button>
        </GridItem>
        {isAdmin ? (
          <GridItem rowStart={2} colStart={3}>
            <Button
              width='full'
              isLoading={isRefreshingPin}
              onClick={RefreshPin}
              rightIcon={<RepeatIcon />}
              fontSize={memberDetailTextSize}
            >
              Refresh Pin
            </Button>
          </GridItem>
        ) : null}
        <GridItem rowStart={3}>
          <Center height='full' pr={2}>
            <Heading fontSize={memberDetailTextSize}>Invite:</Heading>
          </Center>
        </GridItem>
        <GridItem rowStart={3} colStart={2} colSpan={2}>
          <Button px={2} width='full' rightIcon={<CopyIcon />} onClick={() => copyString(joinLink)}>
            <Code width='full' background='none' fontSize={codeTextSize}>
              {joinLink}
            </Code>
          </Button>
        </GridItem>
      </Grid>
    </Card>
  );
}

export default function LeaguePage() {
  const { gameUpdates, leagues, memberData, teamSelectionData, tournamentStarted } = useAuthContext();
  const latecomerAllowed = Object.keys(gameUpdates).includes(sentinelType.latecomersAllowed);
  const currentTournamentSelection = teamSelectionData[ACTIVE_TOURNAMENT] ?? {};
  const { countryList = [], goalsGuess = 0, latecomer = undefined } = currentTournamentSelection;

  const { id } = useParams();
  const [members, setMembers] = useState<Dict<leagueMember>>();
  const toast = useToast();
  const navigate = useNavigate();
  const editNameDisclosure = useDisclosure();
  const deleteLeagueDisclosure = useDisclosure();
  const leaveLeagueDisclosure = useDisclosure();
  const editCountryListDisclosure = useDisclosure();
  const ctx = useContext(FirebaseContext);
  const colorMode = useColorMode();
  const smolSrc = useColorModeValue(smolLight, smolDark);
  const memberDetailTextSize = useBreakpointValue<ResponsiveValue<number>>({
    base: 14,
    md: 16,
  });
  const cardPadX = useBreakpointValue({ base: 4, md: 6 });

  async function copyString(s: string) {
    try {
      await navigator.clipboard.writeText(s);
      toast({
        title: 'Copied To Clipboard',
        status: 'success',
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: 'Failed To Copy',
        status: 'error',
        duration: 2000,
      });
    }
  }

  const membersPerPage = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [busyFindingPage, setBusyFindingPage] = useState(false);
  const [membersQuery, setMembersQuery] = useState<Query<DocumentData>>();
  const [firstMemberDoc, setFirstMemberDoc] = useState<QueryDocumentSnapshot<DocumentData>>();
  const [lastMemberDoc, setLastMemberDoc] = useState<QueryDocumentSnapshot<DocumentData>>();

  useEffect(() => {
    if (id !== undefined) {
      // const useActualRank = memberData[id].currentRank > 0;
      // TODO: Sort out how to reimplement ranking
      return onSnapshot(
        membersQuery ?? queryLeagueMembers(ctx.db, id, limit(membersPerPage)),
        (snapshot) => {
          setMembers(toDict(snapshot));
          if (!snapshot.empty) {
            setFirstMemberDoc(snapshot.docs[0]);
            setLastMemberDoc(snapshot.docs[snapshot.docs.length - 1]);
          }
          setBusyFindingPage(false);
        },
        (e) => {
          // TODO: Make indexes programmatic so can be identical across dev + main
          console.error(e.message);
        },
      );
    }
  }, [ctx.db, id, membersQuery]);

  async function jumpToUserPage() {
    if (!busyFindingPage) {
      if (id !== undefined) {
        setBusyFindingPage(true);
        setMembers({}); // This allows for a brief removal animation
        setMembers(undefined); // This fully clears the display, which relieves various UI bugs
        let snapshot = await getDocs(queryLeagueMembers(ctx.db, id, limit(membersPerPage)));
        let pn = 0;
        while (!snapshot.docs.map((x) => x.id).includes(ctx.user!.uid)) {
          snapshot = await getDocs(
            queryLeagueMembers(ctx.db, id, startAfter(snapshot.docs[snapshot.docs.length - 1]), limit(membersPerPage)),
          );
          pn += 1;
        }
        setMembersQuery(queryLeagueMembers(ctx.db, id, startAt(snapshot.docs[0]), limit(membersPerPage)));
        setPageNumber(pn);
      }
    }
  }

  async function jumpToTopPage() {
    if (!busyFindingPage) {
      if (id !== undefined) {
        setBusyFindingPage(true);
        setMembers({}); // This allows for a brief removal animation
        setMembers(undefined); // This fully clears the display, which relieves various UI bugs
        setMembersQuery(queryLeagueMembers(ctx.db, id, limit(membersPerPage)));
        setPageNumber(0);
      }
    }
  }

  function setNextLeaderboardPage() {
    if (!busyFindingPage) {
      setBusyFindingPage(true);
      setMembers({}); // This allows for a brief removal animation
      setMembers(undefined); // This fully clears the display, which relieves various UI bugs
      setMembersQuery(queryLeagueMembers(ctx.db, id!, startAfter(lastMemberDoc), limit(membersPerPage)));
      setPageNumber(pageNumber + 1);
    }
  }

  function setPrevLeaderboardPage() {
    if (!busyFindingPage) {
      setBusyFindingPage(true);
      setMembers({}); // This allows for a brief removal animation
      setMembers(undefined); // This fully clears the display, which relieves various UI bugs
      setMembersQuery(queryLeagueMembers(ctx.db, id!, endBefore(firstMemberDoc), limitToLast(membersPerPage)));
      setPageNumber(pageNumber - 1);
    }
  }

  async function transferOwnership(newAdminUID: string) {
    if (id !== undefined) {
      await updateLeague(ctx.db, id, { adminUID: newAdminUID });
    }
  }

  async function kickMember(memberUID: string) {
    if (id !== undefined) {
      await httpsCallable(ctx.funcs, 'leaveLeague')({ leagueUID: id, kickMemberUID: memberUID });
    }
  }

  async function leaveLeague() {
    httpsCallable(ctx.funcs, 'leaveLeague')({ leagueUID: id });
    navigate('/../../profile', { replace: true });
  }

  async function deleteLeague() {
    if (id !== undefined) {
      navigate('/../../profile', { replace: true });
      await _deleteLeague(ctx.db, id);
    }
  }

  async function returnHome() {
    navigate('/../../', { replace: true });
  }

  function isSpectator(x: string) {
    return members ? members[x].spectator ?? false : false;
  }

  function isLatecomer(x: string) {
    return members ? members[x].latecomer ?? false : false;
  }

  async function getFullLeagueStandings(useActualRank: boolean): Promise<void> {
    const q = queryLeagueMembers(ctx.db, id!)
    const qSnap = await getDocs(q)
    const lMembers: Dict<leagueMember> = toDict(qSnap)
    return copyString(
      lMembers
        ? Object.keys(lMembers)
          .filter((x) => lMembers[x].currentRank !== worldPopulation)
          .filter((x) => lMembers[x].currentRank !== -1)
          .map(
            (m, i) => `${!!lMembers[m].name && lMembers[m].name.trim().length > 0 ? lMembers[m].name : 'Anonymous'} - Rank: ${(useActualRank
              ? lMembers[m].currentRank
              : membersPerPage * pageNumber + i + 1
            ).toString()} - Points: ${lMembers[m].currentScore.toString()}`
          )
          .join('\n')
        : ''
    );
  }

  if (memberData) {
    if (id !== undefined && Object.keys(leagues).includes(id) && Object.keys(memberData).includes(id)) {
      const isGlobalLeague = id === 'global';
      const league = leagues[id];
      const isAdmin = ctx.user!.uid === league.adminUID;
      const userMemberData = memberData[id];
      const useActualRank = userMemberData.currentRank > 0;

      const backButton = (
        <Link to='../../profile'>
          <IconButton icon={<ArrowBackIcon />} aria-label='back' />
        </Link>
      );

      const menuArea = (
        <Box>
          <Menu>
            <MenuButton as={IconButton} icon={<HamburgerIcon />} />
            <MenuList>
              {isAdmin ? (
                <MenuItem onClick={editNameDisclosure.onOpen} fontSize={16}>
                  Edit League Name
                </MenuItem>
              ) : null}
              {isAdmin ? (
                <>
                  <MenuItem
                    onClick={deleteLeagueDisclosure.onOpen}
                    textColor={colorMode.colorMode === 'light' ? 'red.500' : 'red.300'}
                    fontSize={16}
                  >
                    Delete League
                  </MenuItem>

                  {/* TODO: Do something like "Type League Name" to confirm */}
                  <ConfirmModal
                    isOpen={deleteLeagueDisclosure.isOpen}
                    title='Delete League'
                    message='Are you sure that you would like to delete your league?'
                    onClose={deleteLeagueDisclosure.onClose}
                    isDestructive
                    onConfirm={deleteLeague}
                  />
                </>
              ) : (
                <>
                  <MenuItem
                    onClick={leaveLeagueDisclosure.onOpen}
                    textColor={colorMode.colorMode === 'light' ? 'red.500' : 'red.300'}
                    fontSize={16}
                  >
                    Leave League
                  </MenuItem>

                  <ConfirmModal
                    isOpen={leaveLeagueDisclosure.isOpen}
                    title='Leave League'
                    message='Are you sure that you would like to leave this league?'
                    onClose={leaveLeagueDisclosure.onClose}
                    isDestructive
                    onConfirm={leaveLeague}
                  />
                </>
              )}
            </MenuList>
          </Menu>
        </Box>
      );

      const pageControl =
        league.memberUIDs.length > membersPerPage ? (
          <HStack spacing={2} mb={1} w='full' justify='center'>
            <Button
              variant={'ghost'}
              fontSize={14}
              fontWeight='normal'
              isDisabled={pageNumber === 0 || busyFindingPage}
              onClick={() => jumpToTopPage()}
            >
              Skip to Top
            </Button>
            <IconButton
              bg='transparent'
              icon={<ArrowBackIcon />}
              aria-label='previous leaderboard page'
              isDisabled={busyFindingPage || pageNumber <= 0}
              onClick={setPrevLeaderboardPage}
            />
            <Text fontSize={16}>{pageNumber + 1}</Text>
            <IconButton
              bg='transparent'
              icon={<ArrowForwardIcon />}
              aria-label='next leaderboard page'
              isDisabled={busyFindingPage || league.memberUIDs.length <= (pageNumber + 1) * membersPerPage}
              onClick={setNextLeaderboardPage}
            />
            <Button
              variant={'ghost'}
              fontSize={14}
              fontWeight='normal'
              isDisabled={(members && Object.keys(members).includes(ctx.user!.uid)) || busyFindingPage}
              isLoading={busyFindingPage}
              onClick={() => jumpToUserPage()}
            >
              Skip to You
            </Button>
          </HStack>
        ) : (
          <Box h={2} />
        );

      const listCard = (
        <Card h='80vh' overflowY={'scroll'} px={cardPadX}>
          <SimpleGrid columns={1} width='full' rowGap={2}>
            <Heading textAlign={'center'} fontSize={24}>
              Leaderboard
            </Heading>
            {pageControl}
            {members ? (
              <>
                {Object.keys(members).map((x, i) => (
                  <HStack
                    p={2}
                    px={{ base: 3, sm: 4 }}
                    key={x}
                    spacing={{ base: 3, sm: 4 }}
                    width='full'
                    borderRadius={6}
                    background={x === ctx.user!.uid ? 'green.500' : 'listItemBackground'}
                  >
                    {isSpectator(x) ? null : members[x].currentRank === worldPopulation ? (
                      <Heading
                        fontSize={memberDetailTextSize}
                        minW={8}
                        maxW={20}
                        w='full'
                        fontStyle='italic'
                        color={x === ctx.user!.uid ? 'white' : 'default'}
                        textAlign={'start'}
                      >
                        pending
                      </Heading>
                    ) : (
                      <>
                        <Heading fontSize={memberDetailTextSize} color={x === ctx.user!.uid ? 'white' : 'default'}>
                          {members[x].currentRank > 0 ? members[x].currentRank : membersPerPage * pageNumber + i + 1}
                          <sup>
                            {ordinalSuffix(
                              members[x].currentRank > 0 ? members[x].currentRank : membersPerPage * pageNumber + i + 1,
                            )}
                          </sup>
                        </Heading>
                        {members[x].currentRank > 0 ? (
                          x === ctx.user!.uid ? (
                            <RankChangeIndicator
                              memberData={members[x]}
                              getListPosition={() => league.memberUIDs.indexOf(x)}
                              fontSize={memberDetailTextSize}
                              color='white'
                            />
                          ) : (
                            <RankChangeIndicator
                              memberData={members[x]}
                              getListPosition={() => league.memberUIDs.indexOf(x)}
                              fontSize={memberDetailTextSize}
                            />
                          )
                        ) : null}
                      </>
                    )}
                    <Text
                      fontSize={memberDetailTextSize}
                      noOfLines={1}
                      color={x === ctx.user!.uid ? 'white' : 'default'}
                      textAlign='start'
                      w='full'
                    >
                      {!!members[x].name && members[x].name.trim().length > 0 ? members[x].name : 'Anonymous'}
                    </Text>
                    <Spacer />
                    {x === league.adminUID ? (
                      x === ctx.user!.uid ? (
                        <LightMode>
                          <Box>
                            <Tag fontSize={memberDetailTextSize} size='md' colorScheme='whiteAlpha'>
                              Admin
                            </Tag>
                          </Box>
                        </LightMode>
                      ) : (
                        <Box>
                          <Tag fontSize={memberDetailTextSize} size='md' colorScheme='purple'>
                            Admin
                          </Tag>
                        </Box>
                      )
                    ) : null}
                    {isSpectator(x) ? <SpectatorTag /> : null}
                    {!isSpectator(x) && isLatecomer(x) ? <LatecomerTag /> : null}
                    {isSpectator(x) ? null : (
                      <Text
                        fontSize={memberDetailTextSize}
                        color={x === ctx.user!.uid ? 'white' : 'default'}
                        noOfLines={1}
                        w='full'
                        align='end'
                      >
                        {members[x].currentScore} points
                      </Text>
                    )}

                    {isAdmin && x !== league.adminUID ? (
                      <Box>
                        <Menu isLazy>
                          <MenuButton
                            as={IconButton}
                            m={-1.5}
                            mr={-3}
                            background={'transparent'}
                            icon={<HamburgerIcon />}
                          />
                          <MenuList fontSize={memberDetailTextSize}>
                            <MenuItem onClick={() => transferOwnership(x)}>Make Admin</MenuItem>
                            <MenuItem onClick={() => kickMember(x)}>Kick From League</MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>
                    ) : null}
                  </HStack>
                ))}
              </>
            ) : (
              <Center>
                <Spinner size='xl' />
              </Center>
            )}
            {!isGlobalLeague ? (
              <Button
                justifySelf='center'
                rightIcon={<CopyIcon />}
                onClick={() =>
                  getFullLeagueStandings(useActualRank)
                }
              >
                Copy League Standings
              </Button>
            ) : null}
          </SimpleGrid>
        </Card>
      );

      const userListPosition = league.memberUIDs.indexOf(ctx.user!.uid);
      const userRankToDisplay = useActualRank ? userMemberData.currentRank : userListPosition + 1;
      const userRankingArea = (
        <Card>
          <Center>
            <Grid templateColumns={'repeat(2, 1fr)'} templateRows={'1fr auto'} columnGap={6}>
              <GridItem colSpan={2}>
                <Center pb={4}>
                  <Heading fontSize={24}>Ranking</Heading>
                </Center>
              </GridItem>
              <GridItem rowStart={2} colSpan={useActualRank && userRankToDisplay !== worldPopulation ? 1 : 2}>
                <Center height='full'>
                  {userRankToDisplay === worldPopulation ? (
                    <Heading fontSize={32} fontStyle='italic'>
                      pending
                    </Heading>
                  ) : (
                    <Heading fontSize={64}>
                      {!tournamentStarted || userMemberData.spectator ? (
                        '-----'
                      ) : (
                        <>
                          {userRankToDisplay}
                          <sup>{ordinalSuffix(userRankToDisplay)}</sup>
                        </>
                      )}
                    </Heading>
                  )}
                </Center>
              </GridItem>
              {useActualRank && userRankToDisplay !== worldPopulation ? (
                <GridItem rowStart={2} colStart={2}>
                  <Center height='full'>
                    <RankChangeIndicator
                      memberData={userMemberData}
                      fontSize={48}
                      getListPosition={() => userListPosition}
                    />
                  </Center>
                </GridItem>
              ) : null}
            </Grid>
          </Center>
        </Card>
      );

      const inviteCard = <InviteCard id={id} isAdmin={isAdmin} copyString={copyString} league={league} />;

      const tooltipLabel = latecomerAllowed
        ? 'The tournament has now started. Changing teams will reset your points'
        : 'The tournament has now started and you can no longer pick new teams';
      const pickYourTeamsPrompt = (
        <Box width='full'>
          <Tooltip hasArrow placement='top' label={tooltipLabel} isDisabled={!tournamentStarted}>
            <Button
              width='full'
              height='64px'
              maxHeight={'64px'}
              colorScheme='green'
              onClick={editCountryListDisclosure.onOpen}
              isDisabled={tournamentStarted && !latecomerAllowed}
            >
              Pick Your Teams
            </Button>
          </Tooltip>
        </Box>
      );

      return (
        <AuthedPageContainer>
          <Show above='md'>
            <Grid
              height='1fr'
              maxWidth='100vw'
              overflowX={'hidden'}
              templateRows={'3rem calc(1fr - 9rem)'}
              templateColumns={'2fr 1fr'}
              py={4}
              px={8}
              gap={8}
            >
              <GridItem rowStart={1} colStart={1} colSpan={2}>
                <HStack height={'min-content'} width='full'>
                  {backButton}
                  <Spacer />
                  <Heading>{league.name}</Heading>
                  <Spacer />
                  {menuArea}
                </HStack>
              </GridItem>
              <GridItem rowStart={2}>{listCard}</GridItem>
              <GridItem rowStart={2} colStart={2}>
                <VStack height='full' direction={'column'} spacing={8}>
                  {countryList.length < 10 && (!tournamentStarted || (tournamentStarted && latecomerAllowed))
                    ? pickYourTeamsPrompt
                    : latecomer ?? false
                      ? null
                      : userRankingArea}
                  {!isGlobalLeague ? inviteCard : null}
                  <Center height={'full'} pb={8}>
                    <Image src={smolSrc} width={36} />
                  </Center>
                </VStack>
              </GridItem>
            </Grid>
          </Show>
          <Show below='md'>
            <VStack width='100vw' overflowX={'hidden'} padding={4} spacing={8}>
              <HStack height={'min-content'} width='full'>
                {backButton}
                <Spacer />
                <Heading fontSize={24}>{league.name}</Heading>
                <Spacer />
                {menuArea}
              </HStack>
              {countryList.length < 10 ? pickYourTeamsPrompt : userRankingArea}
              <Box width='full' maxH={'80vh'} height='full'>
                {listCard}
              </Box>
              {!isGlobalLeague ? inviteCard : null}
            </VStack>
          </Show>
          <StringEntryModal
            isOpen={editNameDisclosure.isOpen}
            title='Edit Name'
            label='League Name'
            onClose={editNameDisclosure.onClose}
            // TODO: Check if this should be async.
            onSubmit={(name) => updateLeague(ctx.db, id, { name: name })}
            getInitialValue={() => league.name}
          />

          <EditCountryListModal
            isOpen={editCountryListDisclosure.isOpen}
            onClose={editCountryListDisclosure.onClose}
            getStartingList={() => countryList}
            getStartingGoalsGuess={() => goalsGuess}
            gameUpdates={gameUpdates}
          />
        </AuthedPageContainer>
      );
    } else {
      return (
        <AuthedPageContainer>
          <Center width='full' height='full' minH='90vh'>
            <Link to='/profile'>
              <IconButton position='absolute' top={24} left={8} aria-label='back' icon={<ArrowBackIcon />} />
            </Link>
            <VStack spacing={4}>
              <Heading>404: League Not Found</Heading>
              <Button colorScheme='green' onClick={returnHome}>
                Return to Home
              </Button>
            </VStack>
          </Center>
        </AuthedPageContainer>
      );
    }
  } else return null;
}
