import { CheckCircleIcon } from '@chakra-ui/icons';
import { Text, Flex, Icon, HStack, Box } from '@chakra-ui/react';
import type React from 'react';
import { useEffect, useState } from 'react';
import { countriesInfo, Country, type CountryCode } from '../../models/country';
import { Flag } from '../Flag';

interface Config {
  default: string;
  selected: string;
}

interface TeamSelectProps {
  countryCode: Country;
  selected?: boolean;
  nonSelectable?: boolean;
  onClick?: (countryCode: CountryCode) => void;
  config?: {
    borderColor?: Config;
    iconColor?: Config;
    iconBorderColor?: Config;
    bgColor?: Config;
    textColor?: Config;
  };
}

export const TeamSelect: React.FC<TeamSelectProps> = ({ countryCode, selected, nonSelectable, onClick, config }) => {
  const countryName = countriesInfo[Country[countryCode]].name;

  const [isSelected, setIsSelected] = useState<boolean>(false);
  useEffect(() => {
    setIsSelected(selected ?? false);
  }, [selected]);

  const handleSelect = () => {
    if (nonSelectable) return;
    onClick?.(countryCode) ?? setIsSelected(!isSelected);
  };

  config = {
    textColor: {
      default: 'gray.600',
      selected: 'gray.600',
    },
    bgColor: {
      default: 'transparent',
      selected: 'gray.50',
    },
    borderColor: {
      default: 'gray.200',
      selected: '#00B93C',
    },
    iconColor: {
      default: 'transparent',
      selected: '#00B93C',
    },
    iconBorderColor: {
      default: 'gray.500',
      selected: 'transparent',
    },
    ...config,
  };

  const { textColor, bgColor, iconColor, iconBorderColor, borderColor } = config;

  const bg = isSelected ? bgColor?.selected : bgColor?.default;
  const borderColour = isSelected ? borderColor?.selected : borderColor?.default;
  const iconColour = isSelected ? iconColor?.selected : iconColor?.default;
  const iconBorderColour = isSelected ? iconBorderColor?.selected : iconBorderColor?.default;
  const iconBorderWidth = isSelected ? 0 : '2px';
  const textColour = isSelected ? textColor?.selected : textColor?.default;

  return (
    <Flex
      bg={bg}
      justify='space-between'
      alignItems='center'
      borderRadius={{ base: '8px', md: '12px', lg: '16px' }}
      borderColor={borderColour}
      borderWidth='2px'
      onClick={handleSelect}
      minW={{ base: '100px', md: '200px' }}
      width='full'
      px='9px'
      py='6px'
      cursor={nonSelectable ? 'default' : 'pointer'}
    >
      <HStack>
        <Box
          boxSize={{
            base: '32px',
            md: '48px',
          }}
          borderWidth='2px'
          borderColor='gray.100'
          borderRadius='8px'
          overflow='hidden'
          boxShadow='sm'
        >
          <Flag country={countryCode} svg />
        </Box>
        <Text fontSize='small' color={textColour}>
          {countryName}
        </Text>
      </HStack>
      {isSelected && (
        <Icon
          as={CheckCircleIcon}
          color={iconColour}
          borderRadius='full'
          borderWidth={iconBorderWidth}
          borderColor={iconBorderColour}
          boxSize='18px'
        />
      )}
    </Flex>
  );
};

export default TeamSelect;
