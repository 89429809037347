import React from 'react';
import { Code, Flex, Text, VStack } from '@chakra-ui/react';
import TeamSelect from '../components/TeamSelect';
import { Country } from '../models/country';

const ComponentsPage = () => {
  const countryCodes: Country[] = [Country.FR, Country.NG, Country.GB_ENG, Country.DE];
  return (
    <VStack p={10} spacing={8}>
      <VStack>
        <Text fontSize='xl' fontWeight='bold' mb={4}>
          <Code>{'<TeamSelect />'}</Code> Component
        </Text>
        <Flex gap={4} flexWrap='wrap'>
          {countryCodes.map((cc) => (
            <TeamSelect key={cc} countryCode={cc} selected={cc === Country.GB_ENG} />
          ))}
        </Flex>
      </VStack>
    </VStack>
  );
};

export default ComponentsPage;
