import {
  Box,
  Heading,
  Image,
  Flex,
  Text,
  SlideFade,
  Container,
  HStack,
  type SpaceProps,
  useColorModeValue,
  Tag,
  SimpleGrid,
  Divider,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { FirebaseContext, AuthedPageContainer } from '../components';
import smolLight from '../assets/logos/smolLight.svg';
import smolDark from '../assets/logos/smolDark.svg';

const PlaceholderBlog = ({ id = 1 }) => (
  <LinkBox
    as='article'
    marginTop={{ base: '1', sm: '5' }}
    px={{ base: 2, md: 4 }}
    display='flex'
    flexDirection={{ base: 'column' }}
    justifyContent='space-between'
  >
    <Box display='flex' flex='1' position='relative' alignItems='center'>
      <Box>
        <Box textDecoration='none' _hover={{ textDecoration: 'none' }}>
          <Image
            borderRadius='lg'
            // width={500}
            // height={300}
            src={
              id % 2 === 0
                ? 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*hSdaDr9jBxChz5Vg8wFvVw.jpeg'
                : 'https://miro.medium.com/v2/resize:fit:1400/format:webp/0*m9Vq_ZLeJQzmGoBc.jpg'
            }
            alt='some good alt text'
            objectFit='contain'
          />
        </Box>
      </Box>
    </Box>
    <Box
      pt={3}
      display='flex'
      flex='1'
      flexDirection='column'
      justifyContent='center'
      marginTop={{ base: '3', sm: '0' }}
    >
      <Heading marginTop='1' size={{ base: 'lg', md: 'lg' }}>
        <Text textAlign='start' textDecoration='none' _hover={{ textDecoration: 'none' }}>
          <LinkOverlay href='#'>Euro 2024 qualification preview — who can still qualify?</LinkOverlay>
        </Text>
      </Heading>
      <Text
        as='p'
        marginTop='2'
        color={useColorModeValue('gray.700', 'gray.200')}
        textAlign='start'
        fontSize='lg'
        display={{ base: 'none', md: 'block' }}
      >
        The international break is upon us once more, as the last few teams battle it out to secure qualification for
        Euro 2024. So, who needs what heading into the final round of fixtures?
      </Text>
      <BlogAuthor name='Lucy Blitz' date={new Date('2023-11-14')} />
    </Box>
  </LinkBox>
);

export default function BlogPage() {
  const ctx = useContext(FirebaseContext);

  if (ctx.userDataPresent) {
    return (
      <SlideFade in>
        <AuthedPageContainer>
          <Flex direction='column' width='full' maxWidth={'5xl'} alignItems={'center'} pt={4} margin={'auto'}>
            <Container gap={2} mb={4}>
              <Heading size='xl'>The 10 Teams Scout</Heading>
              <Text fontSize='xl' maxW='2xl'>
                Inside Euro 2024: Exploring Teams, Tactics, and Tales – Your Comprehensive Guide to the Pinnacle of
                European Football
              </Text>
            </Container>
            <Divider borderBottomWidth={3} />
            <SimpleGrid columns={{ base: 1, sm: 2 }} px={{ base: 2, md: 4 }} pb={8} maxW={'full'} flex='1' gap={4}>
              <PlaceholderBlog id={1} />
              <PlaceholderBlog id={2} />
              <PlaceholderBlog id={3} />
              <PlaceholderBlog id={4} />
              <PlaceholderBlog id={5} />
            </SimpleGrid>
          </Flex>
        </AuthedPageContainer>
      </SlideFade>
    );
  } else return null;
}

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

interface Props {
  marginTop?: number;
  tags: any[];
}

const BlogTags = (props: Props) => {
  const { marginTop = 0, tags } = props;

  return (
    <HStack spacing={2} marginTop={marginTop}>
      {tags.map((tag) => {
        return (
          <Tag size={'md'} variant='solid' colorScheme='blue' key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

const BlogAuthor = (props: BlogAuthorProps) => {
  const smolLogoSrc = useColorModeValue(smolLight, smolDark);

  return (
    <HStack marginTop='2' spacing='2' display='flex' alignItems='center' fontSize={{ base: 'sm', md: 'lg' }}>
      <Image borderRadius='full' boxSize='40px' fit='fill' h='60%' src={smolLogoSrc} alt={`Avatar of ${props.name}`} />
      <Text fontWeight='medium'>{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};
