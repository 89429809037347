// If this page is loaded for an unauthenticated user redirect to Home - DONE
// If this page is loaded for an email verified user redirect to Profile - DONE
// If this page is loaded for an email unverified user then show - DOING
// and redirect to 'specified redirect' - TBD
// else redirect to profile - TBD
// Add this to some authenticated layout at the root of router os you can't
// access any main page without being a verfied user.
// Figure out if there is some way to try do this for API/DB access as well.

import {
  Button,
  Center,
  Heading,
  SlideFade,
  VStack,
  Image,
  useColorModeValue,
  useToast,
  Flex,
  Stack,
  IconButton,
  HStack,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { sendEmailVerification } from 'firebase/auth';
import { useSearchParams } from 'react-router-dom';
import bannerLight from '../assets/logos/bannerLight.svg';
import bannerDark from '../assets/logos/bannerDark.svg';
import { FirebaseContext } from '../components/FirebaseContext';
import { errorToastOptions, firebaseErrorToastOptions, successToastOptions } from '../helpers';

export default function EmailVerificationPage() {
  const ctx = useContext(FirebaseContext);
  const toast = useToast();
  const heroSrc = useColorModeValue(bannerLight, bannerDark);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  async function sendEmail() {
    if (ctx.user) {
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 60000);
      await sendEmailVerification(ctx.user, { url: `${window.location.origin}/profile`, handleCodeInApp: true })
        .then(() => toast(successToastOptions('Email sent!', 'Check your email to complete sign-in')))
        .catch((err) => {
          console.error('send error', err);
          toast(firebaseErrorToastOptions('Email not sent!', err));
          toast(errorToastOptions('Email not sent!', err.message));
        });
    } else {
      toast(errorToastOptions('Error!', 'Please sign-in before you try to verify your email.'));
    }
  }

  const signOut = async () => {
    await ctx.auth.signOut();
    navigate('/', {
      replace: true,
      relative: 'path',
    });
  };

  if (ctx.user && !ctx.authLoading) {
    if (!ctx.user.emailVerified) {
      return (
        <Center width='100vw' height='100vh'>
          <IconButton
            position='absolute'
            top={8}
            left={8}
            aria-label='back'
            onClick={signOut}
            icon={
              <HStack p={4}>
                <ArrowBackIcon />
                <Text>Sign Out </Text>
              </HStack>
            }
          />
          <VStack spacing={4} padding={8}>
            <Image src={heroSrc} height={12} />
            <SlideFade in>
              <Flex
                // minH={'100vh'}
                align={'center'}
                justify={'center'}
                bg={useColorModeValue('gray.50', 'gray.800')}
              >
                <Stack
                  spacing={4}
                  w={'full'}
                  maxW={'sm'}
                  bg={useColorModeValue('white', 'gray.700')}
                  rounded={'xl'}
                  boxShadow={'lg'}
                  p={6}
                  m={10}
                >
                  <Center>
                    <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                      Verify your Email
                    </Heading>
                  </Center>
                  <Center fontSize={{ base: 'sm', sm: 'md' }} color={useColorModeValue('gray.800', 'gray.400')}>
                    In order to complete your sign-up, you must verify your email address. Click the link below to
                    generate an email.
                  </Center>
                  <Center
                    fontSize={{ base: 'sm', sm: 'md' }}
                    fontWeight='bold'
                    color={useColorModeValue('gray.800', 'gray.400')}
                  >
                    {ctx.user.email}
                  </Center>
                  <Stack spacing={6}>
                    <Button
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={sendEmail}
                      isDisabled={isButtonDisabled}
                    >
                      {isButtonDisabled ? `Wait ${timer} seconds` : 'Get a verification email'}
                    </Button>
                  </Stack>
                  <Center fontSize={{ base: 'sm', sm: 'md' }} color={useColorModeValue('gray.800', 'gray.400')}>
                    Check your email to complete sign-in.
                  </Center>
                </Stack>
              </Flex>
            </SlideFade>
          </VStack>
        </Center>
      );
    } else {
      // TODO: Navigate to some redirect which is defined in the URL
      // Or to profile page by default.
      if (searchParams.get('redirect_to')) {
        return <Navigate to={`${searchParams.get('redirect_to')}`} relative='path' />;
      }
      return <Navigate replace to='/profile' relative='path' />;
    }
  } else {
    return (
      <Center width='100vw' height='100vh'>
        <Spinner />
      </Center>
    );
  }
}
