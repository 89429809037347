import { Grid } from "@chakra-ui/react";
import BlogPostItem from "./BlogPostItem";
import type { IPost } from "../post.types";

interface BlogPostsListProps {
  posts: IPost[];
}

export const BlogPostsList: React.FC<BlogPostsListProps> = ({ posts }) => {
  return (
    <Grid gap={8} maxW="container.lg" m="auto">
      <Grid>
        {posts.map((post, idx) => {
          post = {
            ...post,
            ...post.cardConfig,
          };
          return (
            <BlogPostItem
              key={idx}
              {...post}
              isLastItem={idx == posts.length - 1}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
