import { useRef, useState } from 'react';
import {
  Center,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Divider,
  Box,
  Tfoot,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { countriesInfo, Country as countryCode } from '@/models/country';
import scorePointsLight from '../../assets/logos/scorePointsLight.png';
import scorePointsDark from '../../assets/logos/scorePointsDark.png';
import competeLight from '../../assets/logos/competeLight.png';
import competeDark from '../../assets/logos/competeDark.png';
import createListLight from '../../assets/logos/createListLight.png';
import createListDark from '../../assets/logos/createListDark.png';
import { Flag } from '../Flag';

export function RulesModal(props: { isOpen: boolean; onClose: () => void; prompt?: boolean; onNext?: () => void }) {
  const modalMaxW = useBreakpointValue({
    base: undefined,
    md: '70vw',
  });

  const scorePointsSrc = useColorModeValue(scorePointsLight, scorePointsDark);
  const competeSrc = useColorModeValue(competeLight, competeDark);
  const createListSrc = useColorModeValue(createListLight, createListDark);
  const textAlign = useBreakpointValue<'center' | 'start'>({
    base: 'center',
    md: 'start',
  });
  const fontSize = useBreakpointValue({ base: undefined, md: 18 });
  const cardBackground = useColorModeValue('gray.50', 'gray.700');
  const videoRef = useRef(null);

  const [tabIndex, setTabIndex] = useState(0);

  const rankTeamsText = (
    <Text textAlign={textAlign} fontSize={fontSize}>
      Choose your list of ten teams with a maximum of 4 Seeds and a minimum of 2 Outsiders!
    </Text>
  );

  const rankTeamsImage = (
    <Center borderRadius={6} p={2} background={cardBackground}>
      <Image src={createListSrc} />
    </Center>
  );

  const scorePointsText = (
    <Text textAlign={textAlign} fontSize={fontSize}>
      Points are awarded if any of your teams score a goal, draw, win, or progress through the competition. The higher
      your team is in your list, the more points these are worth.
    </Text>
  );

  const scorePointsImage = (
    <Center borderRadius={6} p={2} background={cardBackground}>
      <Image src={scorePointsSrc} />
    </Center>
  );

  const competeText = (
    <Text textAlign={textAlign} fontSize={fontSize}>
      Join or create a league and compete with friends, family and colleagues.
    </Text>
  );

  const competeImage = (
    <Center borderRadius={6} p={2} background={cardBackground}>
      <Image src={competeSrc} />
    </Center>
  );

  const pointsTable = (
    <TableContainer>
      <Table variant='simple' size='sm'>
        <Thead>
          <Tr>
            <Th />
            <Th isNumeric>Points Earned</Th>
          </Tr>
        </Thead>
        <Tbody>
          {[
            ['Goal', 1],
            ['Win', 3],
            ['Draw', 1],
            ['Round of 16', 3],
            ['Quarter Final', 6],
            ['Semi Final', 9],
            ['Final', 12],
            ['Winner', 15],
          ].map((item, idx) => (
            <Tr key={idx}>
              <Td>{item[0]}</Td>
              <Td isNumeric>{item[1]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  const staticExampleList: [countryCode, number, string, number][] = [
    [countryCode.ES, 12, 'x10', 120],
    [countryCode.US, 8, 'x9', 72],
    [countryCode.BR, 14, 'x8', 112],
    [countryCode.PT, 9, 'x7', 63],
    [countryCode.CH, 3, 'x6', 18],
    [countryCode.HT, 7, 'x5', 35],
    [countryCode.GB_ENG, 2, 'x4', 8],
    [countryCode.JP, 1, 'x3', 3],
    [countryCode.NZ, 3, 'x2', 6],
    [countryCode.CR, 1, 'x1', 1],
  ];

  const exampleListTable = (
    <TableContainer>
      <Table variant='simple' size='sm'>
        <Thead>
          <Tr>
            <Th>Country List</Th>
            <Th isNumeric>Points</Th>
            <Th isNumeric>Multiplier</Th>
            <Th isNumeric>Weighted Points</Th>
          </Tr>
        </Thead>
        <Tbody>
          {staticExampleList.map((item, idx) => (
            <Tr key={idx}>
              <Td>
                <HStack>
                  <Text>{countriesInfo[item[0]].name}</Text>
                  <Flag country={item[0]} w={4} h={4} borderRadius='3px' svg />
                </HStack>
              </Td>
              <Td isNumeric>{item[1]}</Td>
              <Td isNumeric>{item[2]}</Td>
              <Td isNumeric>{item[3]}</Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td />
            <Td />
            <Td>Total Points</Td>
            <Td isNumeric>438</Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} scrollBehavior='outside'>
      <ModalOverlay />
      <ModalContent maxW={modalMaxW}>
        <ModalHeader>
          <VStack align='stretch'>
            <Heading fontSize={28}>How To Play</Heading>
          </VStack>
          <Show above='lg'>
            <HStack justify='center' width='full'>
              <Tabs variant='line' index={tabIndex} onChange={setTabIndex}>
                <TabList>
                  <Tab>Video Explainer</Tab>
                  <Tab>1. Rank Your Teams</Tab>
                  <Tab>2. Score Points</Tab>
                  <Tab>3. Compete in Leagues</Tab>
                </TabList>
              </Tabs>
            </HStack>
          </Show>
        </ModalHeader>
        <ModalCloseButton color='black' mt={2} mr={2} />
        <ModalBody>
          <Show above='lg'>
            <Tabs index={tabIndex} onChange={setTabIndex} mt={-3}>
              <TabPanels>
                <TabPanel minH='480px'>
                  <VStack padding={{base: 2, sm: 4, md: 8}} spacing={4} w='full'>
                    <Image
                      width='full'
                      mx='auto'
                      as='iframe'
                      ref={videoRef}
                      src='https://www.youtube.com/embed/LD9bX2K2dao'
                      sx={{
                        aspectRatio: '16/9'
                      }}
                    />
                  </VStack>
                </TabPanel>
                <TabPanel minH='480px'>
                  <VStack height={'full'} justify='center' align='center'>
                    <Heading textAlign={'center'} width='full'>
                      Rank Your Teams
                    </Heading>
                    <Center maxW='70%'>{rankTeamsImage}</Center>
                    {rankTeamsText}
                  </VStack>
                </TabPanel>
                <TabPanel minH='480px'>
                  <VStack height={'full'} justify='center' spacing={8}>
                    <Heading textAlign={'center'} width='full'>
                      Score Points
                    </Heading>
                    <HStack justify='center' height={'full'}>
                      {pointsTable}
                      <Box px={2}>
                        <Divider
                          orientation='vertical'
                          height='300px'
                          borderColor={useColorModeValue('black', 'white')}
                        />
                      </Box>
                      {exampleListTable}
                    </HStack>
                    {scorePointsText}
                  </VStack>
                </TabPanel>
                <TabPanel minH='480px'>
                  <VStack height={'full'} justify='center'>
                    <Heading textAlign={'center'} width='full'>
                      Compete In Leagues
                    </Heading>
                    <Center maxW='80%'>
                      <HStack>
                        {competeImage}
                        {scorePointsImage}
                      </HStack>
                    </Center>
                    {competeText}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Show>
          <Show below='lg'>
            <VStack spacing={0}>
              <VStack padding={{base: 2, sm: 4, md: 8}} spacing={4} w='full'>
                <Image
                  width='full'
                  mx='auto'
                  as='iframe'
                  ref={videoRef}
                  src='https://www.youtube.com/embed/LD9bX2K2dao'
                  sx={{
                    aspectRatio: '16/9'
                  }}
                />
              </VStack>
              <VStack padding={8} spacing={4}>
                <Heading fontSize={24}>Rank Your Teams</Heading>
                {rankTeamsImage}
                {rankTeamsText}
              </VStack>
              <VStack padding={8} spacing={4}>
                <Heading fontSize={24}>Score Points</Heading>
                {pointsTable}
                {scorePointsText}
              </VStack>
              <VStack padding={8} spacing={4}>
                <Heading fontSize={24}>Compete In Leagues</Heading>
                {competeImage}
                {scorePointsImage}
                {competeText}
              </VStack>
            </VStack>
          </Show>
        </ModalBody>
        {props.prompt ? (
          <ModalFooter alignItems='end'>
            <Button colorScheme='green' onClick={props.onNext}>
              Pick Your Teams
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
}
