import type React from 'react';
import { Flex, SlideFade, Spacer as ChakraSpacer, useDisclosure } from '@chakra-ui/react';
import { useAuthModal } from '../hooks/useAuthModal';
import NavBar from './HomePage/NavBar';
import Footer from './HomePage/Footer';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { openLoginModal: showLoginModal, openRegisterModal: showRegisterModal } = useAuthModal();
  return (
    <SlideFade in>
      <Flex flexDir='column' minH='100vh'>
        <NavBar onClickLogin={showLoginModal} onClickRegister={showRegisterModal} />
        <Flex flexDir='column' w='full' overflowX='hidden' flexGrow={1}>
          <Flex flexDir='column' justifyContent='center' px='12px'>
            {children}
          </Flex>
        </Flex>
        <Spacer />
        <Footer />
      </Flex>
    </SlideFade>
  );
};

export default Layout;

const Spacer: React.FC<{ mt?: string | number }> = ({ mt }) => {
  const config = {
    base: '2rem',
    md: '3rem',
    lg: '4rem',
    xl: '5rem',
  };
  return <ChakraSpacer mt={mt ?? config} />;
};
