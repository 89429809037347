import {
  AspectRatio,
  Box,
  Button,
  Fade,
  GridItem,
  HStack,
  Heading,
  Image,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useContext, useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import type { User } from '@/models';
import { successToastOptions } from '../../helpers';
import { FirebaseContext } from '../FirebaseContext';

export function ShareModal(props: { isOpen: boolean; onClose: () => void; userData: User }) {
  const toast = useToast();
  const ctx = useContext(FirebaseContext);
  const shortLink = props.userData.shareShortLink!;
  const [imageUrl, setImageDataUrl] = useState<string>();
  async function shareToTwitter() {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(`Here's my #10Teams list!\nMake yours at ${shortLink}`)}`,
      '_blank',
    );
    props.onClose();
  }

  async function shareToFacebook() {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shortLink)}`, '_blank');
    props.onClose();
  }

  useEffect(() => {
    if (props.isOpen) {
      getDownloadURL(ref(ctx.storage, `listImages/euro-24/${ctx.user!.uid}.png`)).then((url) => setImageDataUrl(url));
    } else {
      setImageDataUrl(undefined);
    }
  }, [props.isOpen]);

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered scrollBehavior='inside'>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <Heading fontSize={28}>Share List</Heading>
          </ModalHeader>
          <ModalCloseButton color='black' mt={2} mr={2} />
          <ModalBody>
            <Box border='2px solid' borderColor={'chakra-border-color'}>
              <AspectRatio ratio={1.91} width='full'>
                <Fade in={!!imageUrl}>
                  <Image src={imageUrl} width='full' />
                </Fade>
              </AspectRatio>
            </Box>
          </ModalBody>
          <ModalFooter>
            <SimpleGrid gap={2} columns={2} width='full'>
              <GridItem colSpan={2}>
                <Button
                  width='full'
                  onClick={() => {
                    navigator.clipboard.writeText(shortLink.replace('https://', ''));
                    toast(successToastOptions('Share Link Copied'));
                  }}
                >
                  <HStack width='full'>
                    <Text width='full' pl={4}>
                      {shortLink.replace('https://', '')}
                    </Text>
                    <CopyIcon />
                  </HStack>
                </Button>
              </GridItem>

              <LightMode>
                <Button
                  leftIcon={<FaXTwitter color={'white'} />}
                  width='full'
                  colorScheme='twitter'
                  onClick={shareToTwitter}
                >
                  Share to Twitter
                </Button>
              </LightMode>
              <LightMode>
                <Button
                  leftIcon={<FaFacebook color={'white'} />}
                  width='full'
                  colorScheme='facebook'
                  onClick={shareToFacebook}
                >
                  Share to Facebook
                </Button>
              </LightMode>
            </SimpleGrid>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
