import { Heading } from "@chakra-ui/react";
import { posts } from "../../posts";
import Layout from "../Layout";
import { BlogPostsList } from "../../posts/components";

const ScoutsPage = () => {
  return (
    <Layout>
      <Heading my={8}>Scout</Heading>
      <BlogPostsList posts={posts} />
    </Layout>
  );
};

export default ScoutsPage;
export { ScoutPost } from "./ScoutPost";
