import { type ChakraProps, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import type { ChangeEvent, KeyboardEvent } from 'react';
import type { leagueCreationDetails } from '@/models/league';

export function CreateLeagueView(
  props: {
    details: leagueCreationDetails;
    setDetails: (details: leagueCreationDetails) => void;
    onEnterPressed: VoidFunction;
    isDisabled?: boolean;
  } & ChakraProps,
) {
  return (
    <VStack>
      <FormControl isRequired>
        <FormLabel>League Name</FormLabel>
        <Input
          value={props.details.name}
          disabled={props.isDisabled}
          onChange={(e: ChangeEvent<HTMLInputElement>) => props.setDetails({ ...props.details, name: e.target.value })}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              props.onEnterPressed();
            }
          }}
        />
      </FormControl>
    </VStack>
  );
}
