import { Fragment, useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  Image,
  useColorModeValue,
  useColorMode,
  Show,
  useBreakpointValue,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import type { User } from '@/models';
import smolLight from '@/assets/logos/smolLight.svg';
import smolDark from '@/assets/logos/smolDark.svg';
import bannerDark from '@/assets/logos/bannerDark.svg';
import bannerLight from '@/assets/logos/bannerLight.svg';
import { FirebaseContext } from '../FirebaseContext';
import { logEvent } from 'firebase/analytics';

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem, idx) => (
        <Fragment key={`${navItem.label}/${idx}`}>
          {idx !== 0 && (
            <Divider
              key={`${navItem.label}/${idx}/div`}
              borderColor={useColorModeValue('#0d0b2d', 'white')}
              orientation='vertical'
            />
          )}
          <Box>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                {navItem.cta ? (
                  <Button
                    as='a'
                    p={2}
                    href={navItem.href ?? '#'}
                    fontSize={'xl'}
                    fontWeight={500}
                    colorScheme={'green'}
                    color={'white'}
                    _hover={{
                      textDecoration: 'none',
                      color: linkHoverColor,
                    }}
                  >
                    {navItem.label}
                  </Button>
                ) : (
                  <Box
                    as='a'
                    p={2}
                    href={navItem.href ?? '#'}
                    fontSize={'xl'}
                    fontWeight={500}
                    color={linkColor}
                    _hover={{
                      textDecoration: 'none',
                      color: linkHoverColor,
                    }}
                    lineHeight={'40px'}
                  >
                    {navItem.label}
                  </Box>
                )}
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        </Fragment>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as='a'
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} _groupHover={{ color: 'pink.400' }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} py={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={2} onClick={children && onToggle}>
      <Box
        py={2}
        as='a'
        href={href ?? '#'}
        justifyContent='space-between'
        alignItems='center'
        textAlign={'start'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <HStack>
          <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </HStack>
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children?.map((child) => (
            <Box as='a' key={child.label} py={2} href={child.href}>
              {child.label}
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  cta?: boolean;
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'Inspiration',
  //   children: [
  //     {
  //       label: 'Explore Design Work',
  //       subLabel: 'Trending Design to inspire you',
  //       href: '#',
  //     },
  //     {
  //       label: 'New & Noteworthy',
  //       subLabel: 'Up-and-coming Designers',
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: 'Find Work',
  //   children: [
  //     {
  //       label: 'Job Board',
  //       subLabel: 'Find your dream design job',
  //       href: '#',
  //     },
  //     {
  //       label: 'Freelance Projects',
  //       subLabel: 'An exclusive list for contract work',
  //       href: '#',
  //     },
  //   ],
  // },
  {
    label: 'Home',
    href: '/home',
  },
  {
    label: 'Pick Your Team',
    href: '/profile',
    cta: true,
  },
  {
    label: 'Info',
    href: '#',
  },
  {
    label: 'Scout',
    href: 'blog',
  },
  {
    label: 'Tournament',
    href: '#',
  },
  {
    label: 'Insights',
    href: '#',
  },
  {
    label: 'About',
    href: '#',
  },
];

export function LoggedInNav(props: {
  userData: User;
  openRules: () => void;
  openEditName: () => void;
  openDeleteAccount: () => void;
}) {
  const { admin, name: userName } = props.userData;
  const { colorMode, toggleColorMode } = useColorMode();
  const smolLogoSrc = useColorModeValue(smolLight, smolDark);
  const heroSrc = useColorModeValue(bannerLight, bannerDark);
  const ctx = useContext(FirebaseContext);
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  const openAdmin = () => {
    navigate('../admin', {
      replace: true,
    });
  };

  const signOut = async () => {
    await ctx.auth.signOut();
    logEvent(ctx.analytics, 'log_out');
    navigate('/', {
      replace: true,
      relative: 'path',
    });
  };

  const navPaddingL = useBreakpointValue({ base: 6, lg: 8 });
  const navPaddingR = useBreakpointValue({ base: 4, lg: 8 });

  return (
    <>
      <Box
        maxW={'1444px'}
        width='full'
        position='fixed'
        py={2}
        pl={navPaddingL}
        pr={navPaddingR}
        // borderBottom={1}
        // borderStyle={'solid'}
        // borderColor={useColorModeValue('gray.200', 'gray.300')}
        bgColor={useColorModeValue('white', 'bgBlue')}
        zIndex={1}
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          {/* <IconButton
            ml={-3}
            display={{ base: 'flex', md: 'none' }}
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          /> */}
          <Image alignSelf='center' height={10} mt={1} src={useBreakpointValue({ base: smolLogoSrc, lg: heroSrc })} />

          <HStack justifyContent={'end'} alignContent={'center'} height='full'>
            <Show above='md'>
              {admin ? (
                <Button fontSize={14} onClick={openAdmin}>
                  Admin Console
                </Button>
              ) : null}
            </Show>
            <Show above='sm'>
              <Button onClick={props.openRules} fontSize={14}>
                How To Play
              </Button>
            </Show>
            <Box>
              <Menu>
                <MenuButton as={Button} rightIcon={<HamburgerIcon />}>
                  <Text mr={1.5} fontSize={14}>
                    {userName}
                  </Text>
                </MenuButton>
                <MenuList>
                  {/* <MenuItem fontSize={16} onClick={toggleColorMode}>
                    Use {colorMode === 'light' ? 'Dark' : 'Light'} Theme
                  </MenuItem> */}
                  <Show below='md'>
                    {admin ? (
                      <MenuItem fontSize={16} onClick={openAdmin}>
                        Admin Console
                      </MenuItem>
                    ) : null}
                  </Show>
                  <Show below='sm'>
                    <MenuItem fontSize={16} onClick={props.openRules}>
                      How To Play
                    </MenuItem>
                  </Show>
                  <MenuItem fontSize={16} onClick={props.openEditName}>
                    Edit Name
                  </MenuItem>
                  <MenuItem fontSize={16} onClick={signOut}>
                    Sign Out
                  </MenuItem>
                  <MenuItem
                    textColor={colorMode === 'light' ? 'red.500' : 'red.300'}
                    fontSize={16}
                    onClick={props.openDeleteAccount}
                  >
                    Delete Account
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </Flex>
        {/* <Flex
          display={{ base: 'none', md: 'flex' }}
          flex={{ base: 1 }}
          justify={{ base: 'center', md: 'start' }}
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          align='center'
        >
          <DesktopNav />
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse> */}
      </Box>
    </>
  );
}
