import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import type React from 'react';
import { Flag } from '../../components';
import Button from '../../components/Button';
import { Country, type CountryCode, countriesInfo } from '../../models/country';
import { HomeHeading } from './HomeHeading';

const CollectPointsSection: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data: TeamVsProps['data'] = [
    { countryCode: 'DE', score: 3, points: 6 },
    { countryCode: 'IT', score: 2, points: 2 },
  ];
  return (
    <VStack maxW='8xl' m='auto' justifyContent='center' w='100%'>
      <HomeHeading mb='2rem' fontWeight={700} width={{ base: '90%', md: '70%', lg: '70%', xl: '80%' }}>
        2. Collect Points When Your Teams Score Goals, Win Games, and Progress Through the Tournament
      </HomeHeading>
      <TeamVs data={data} />
      <Button text='Points System' onClick={onOpen} />
      <PointsModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};

export default CollectPointsSection;

interface TeamVsProps {
  data: Array<{
    countryCode: CountryCode;
    score: number;
    points: number;
  }>;
}
const TeamVs: React.FC<TeamVsProps> = ({ data }) => {
  const [team1, team2] = data;

  return (
    <Grid gridTemplateColumns='repeat(5, 1fr)' alignItems='center' gap={0} w='100%' pos='relative'>
      <GridItem colStart={2}>
        <Team {...team1} />
      </GridItem>
      <HStack
        justifyContent={'center'}
        gap={10}
        mb={{
          base: '2em',
          md: '20%',
          lg: '25%',
        }}
        mx={4}
      >
        {[team1.score, '−', team2.score].map((text) => (
          <Text
            key={text}
            textAlign='center'
            fontWeight={600}
            fontSize={{
              base: '20px',
              lg: '24px',
            }}
          >
            {text}
          </Text>
        ))}
      </HStack>
      <Team {...team2} />
    </Grid>
  );
};

const Team = ({ countryCode, points }: Omit<TeamVsProps['data'][0], 'score'>) => {
  return (
    <VStack gap={0}>
      <Box
        boxSize={{
          base: '48px',
          md: '56px',
          lg: '64px',
          xl: '148px',
        }}
        borderRadius='4px'
        overflow='hidden'
      >
        <Flag country={Country[countryCode]} svg />
      </Box>
      <Text
        fontWeight={600}
        fontSize={{
          base: '12px',
          md: '16px',
          lg: '24px',
        }}
      >
        {countriesInfo[countryCode].name}
      </Text>
      <Text
        fontWeight={600}
        fontSize={{
          base: '12px',
          md: '16px',
          lg: '24px',
        }}
      >
        (+{points})
      </Text>
    </VStack>
  );
};

const PointsModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: VoidFunction }) => {
  const data = [
    ['Goal', 1],
    ['Win', 3],
    ['Draw', 1],
    ['Round of 16', 3],
    ['Quarter Finalist', 6],
    ['Semi Finalist', 9],
    ['Finalist', 12],
    ['Champions 🏆', 15],
  ];
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack justifyContent='space-between' pr='2em'>
            <Text fontWeight={600}>10 Teams</Text>
            <Text fontWeight={600}>Points</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton color='black' />
        <ModalBody>
          <VStack gap={2} borderRadius='16px' borderColor='gray.200' borderWidth='1px' px='26px' py='12px'>
            {data.map(([text, points], idx) => (
              <Flex
                key={text}
                w='full'
                justifyContent='space-between'
                borderBottomWidth={idx === data.length - 1 ? '0px' : '1px'}
                borderBottomColor='gray.200'
                py='16px'
                pb={idx === data.length - 1 ? '0px' : 'inherit'}
              >
                <Text fontWeight={600}>{text}</Text>
                <Text fontWeight={600}>{points}</Text>
              </Flex>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
