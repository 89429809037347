import {
  Button,
  Center,
  Heading,
  SlideFade,
  VStack,
  Image,
  useColorModeValue,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { excludeUserFromMarketing } from '@/models/user';
import bannerLight from '../assets/logos/bannerLight.svg';
import bannerDark from '../assets/logos/bannerDark.svg';
import { FirebaseContext } from '../components/FirebaseContext';
import { errorToastOptions, successToastOptions } from '../helpers';

export function UnsubscribePage() {
  const ctx = useContext(FirebaseContext);
  const navigate = useNavigate();
  const toast = useToast();
  const heroSrc = useColorModeValue(bannerLight, bannerDark);
  const [isBusy, setIsBusy] = useState(false);
  const { userID } = useParams();

  console.log(userID);

  async function submit() {
    if (userID) {
      setIsBusy(true);
      await excludeUserFromMarketing(ctx.db, userID)
        .then((_) => {
          setIsBusy(false);
          navigate('../../');
          toast(successToastOptions('Success!', 'You have now been unsubscribed from marketing emails.'));
        })
        .catch((err) => {
          setIsBusy(false);
          // console.error(err)
          toast(errorToastOptions('Something went wrong', 'Try again later or contact hello@tenteams.co.uk'));
        });
    } else {
      toast(errorToastOptions('Something went wrong', 'Try again later or contact hello@tenteams.co.uk'));
    }
  }

  if (userID) {
    return (
      <Center width='100vw' height='100vh'>
        <VStack spacing={4} padding={8}>
          <Image src={heroSrc} height={16} />
          <SlideFade in>
            <VStack>
              <Heading pt={8} fontSize={28}>
                Unsubscribing from 10 Teams
              </Heading>
              <Text fontSize={22}>Are you sure you wish to unsubscribe from marketing emails from 10 Teams?</Text>
              <HStack pt={4}>
                <Button colorScheme={'red'} isLoading={isBusy} onClick={submit}>
                  Confirm
                </Button>
              </HStack>
            </VStack>
          </SlideFade>
        </VStack>
      </Center>
    );
  } else return <Navigate replace to='../' />;
}
