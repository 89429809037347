import type React from "react";
import { Box, Image, Heading, VStack } from "@chakra-ui/react";
import FootballStadium from "../../assets/football-fans-stadium.png";
import Button from "../../components/Button";
import { useRef } from "react";

type HeroSectionProps = {
  onClickCTA: () => void;
};

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  const videoRef = useRef(null);

  return (
    <Box 
      w="100%"
      position="relative"
      maxW="8xl"
      alignSelf="center"
    >
      <VStack
        alignItems="center"
        gap={{
          base: "22px",
          xl: "52px",
        }}
        zIndex={1}
        position="absolute"
        left="0"
        right="0"
        top={{ base: "25%", sm: "25%" }}
      >
        <Heading
          fontStyle='italic'
          textAlign={'center'}
          color='white'
          width={{
            base: "90%",
            sm: "90%",
            md: "70%",
            lg: "80%",
          }}
          fontSize={{
            base: "20px",
            sm: "28px",
            md: "48px",
            lg: "64px",
            xl: "64px",
          }}
        >
          PREDICT THE UNPREDICTABLE
        </Heading>
        <Button onClick={props.onClickCTA} text='Create Your List' hoverBgColor='blue.800' />
      </VStack>
      <Image
        src={FootballStadium}
        borderRadius={["8px", "16px"]}
        width="100%"
        overflow="hidden"
        objectFit="cover"
        filter="brightness(40%)"
        maxH="700px"
        minH="200px"
        height={{
          base: '200px',
          sm: '45vh',
        }}
      />
    </Box>
  );
};

export default HeroSection;
