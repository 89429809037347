import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  type IconProps,
  useColorModeValue,
} from '@chakra-ui/react';
import fans from '../assets/logos/undraw_fans.svg';

export function CTA(props: { onHowTo: () => void; onGetStarted: () => void }) {
  const heroSrc = useColorModeValue(fans, fans);
  return (
    <Container maxW={'full'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        px={{ base: 4, md: 0 }}
        pt={{ base: 0, md: 4 }}
        pb={{ base: 20, md: 24 }}
        direction={{ base: 'column', md: 'row-reverse' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              // _after={{
              //   content: "''",
              //   width: 'full',
              //   height: '30%',
              //   position: 'absolute',
              //   bottom: 1,
              //   left: 0,
              //   bg: 'red.400',
              //   zIndex: -1,
              // }}
            >
              The best way to experience{' '}
            </Text>
            <Text as={'span'} color={'green.400'}>
              Euro 2024!
            </Text>
          </Heading>
          <Stack pb={8} spacing={{ base: 4, sm: 6 }} direction={{ base: 'row', sm: 'row' }} justify='center'>
            <Button
              onClick={props.onGetStarted}
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              colorScheme={'green'}
            >
              Get started
            </Button>
          </Stack>
        </Stack>
        <Flex flex={1} justify={'center'} align={'center'} position={'relative'} w={'full'}>
          <Blob
            w={'150%'}
            h={'150%'}
            position={'absolute'}
            top={'-20%'}
            // left={0}
            zIndex={-1}
            color={useColorModeValue('green.100', 'green.500')}
          />
          <Box position={'relative'} height={'250px'} width={'full'} overflow={'visible'}>
            <Image
              position={'relative'}
              top={'-20%'}
              alt={'Hero Image'}
              fit={'contain'}
              align={'center'}
              w={'100%'}
              h={'130%'}
              src={heroSrc}
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

export const Blob = (props: IconProps) => {
  return (
    <Icon width={'100%'} viewBox='0 0 578 440' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z'
        fill='currentColor'
      />
    </Icon>
  );
};
