import React, { useContext } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type AuthCardsMode, FirebaseContext } from '../components';
import { AuthModal } from '../components/modals';

interface AuthModalContextType {
  openLoginModal: () => void;
  openRegisterModal: () => void;
  authModalMode: AuthCardsMode;
  authModalDisclosure: ReturnType<typeof useDisclosure>;
}

const AuthModalContext = React.createContext<AuthModalContextType>({} as AuthModalContextType);

export const AuthModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authModalMode, setAuthModalMode] = React.useState<AuthCardsMode>('sign-up');
  const authModalDisclosure = useDisclosure();
  const ctx = useContext(FirebaseContext);

  const openLoginModal = () => {
    setAuthModalMode('log-in');
    authModalDisclosure.onOpen();
  };
  const openRegisterModal = () => {
    setAuthModalMode('sign-up');
    authModalDisclosure.onOpen();
  };
  return (
    <AuthModalContext.Provider
      value={{
        authModalMode,
        authModalDisclosure,
        openLoginModal,
        openRegisterModal,
      }}
    >
      {children}
      <AuthModal
        mode={authModalMode}
        isOpen={authModalDisclosure.isOpen && !ctx.user}
        onClose={authModalDisclosure.onClose}
      />
    </AuthModalContext.Provider>
  );
};

export const useAuthModal = (): AuthModalContextType => useContext(AuthModalContext);
