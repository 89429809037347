import { Box, Container, Flex, Grid, GridItem, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import type React from 'react';
import { Flag } from '../../components';
import TeamSelect from '../../components/TeamSelect';
import { countriesInfo, Country, type CountryCode } from '../../models/country';
import { HomeHeading } from './HomeHeading';

const ChooseSeedsSection: React.FC = () => {
  const seeds1: CountryCode[] = ['DE', 'PT', 'FR', 'ES', 'BE', 'GB_ENG'];
  const seeds2: CountryCode[] = ['HU', 'TR', 'RO', 'DK', 'AL', 'AT', 'NL', 'GB_SCT', 'HR', 'SI', 'SK', 'CZ'];
  const outsiders: CountryCode[] = ['IT', 'RS', 'CH', 'PL', 'UA', 'GE'];

  return (
    <VStack
      py='48px'
      px={{
        base: '1em',
        sm: '2em',
        md: '24px',
      }}
      bgColor='#F6F7F9'
      borderRadius='8px'
      justifyContent='center'
    >
      <HomeHeading
        mb={4}
        width={{
          base: '90%',
          sm: '80%',
          md: '70%',
          lg: '70%',
          xl: '70%',
        }}
        fontSize={{
          base: '20px',
          md: '24px',
          lg: '34px',
          xl: '48px',
        }}
      >
        Choose Only 4 Seeds and At Least 2 Outsiders
      </HomeHeading>
      <Grid maxW='container.xl' gap={8}>
        <Teams heading='Seeds' data={seeds1} selectedTeams={['FR', 'DE', 'GB_ENG']} />
        <Teams heading='Intermediates' data={seeds2} selectedTeams={['HU', 'TR', 'RO', 'AL', 'CZ']} />
        <Teams heading='Outsiders' data={outsiders} selectedTeams={['IT', 'UA']} />
      </Grid>
    </VStack>
  );
};

export default ChooseSeedsSection;

const Teams = ({
  heading,
  data,
  selectedTeams,
}: {
  heading: string;
  data: CountryCode[];
  selectedTeams: CountryCode[];
}) => {
  return (
    <Box w='full'>
      <Heading
        mb={2}
        textAlign='left'
        fontStyle='italic'
        fontWeight={600}
        fontSize={{
          base: '20px',
          md: '24px',
        }}
      >
        {heading}
      </Heading>
      <Grid
        w='full'
        gap={4}
        display='grid'
        templateColumns={{
          base: 'repeat(2, 1fr)',
          sm: 'repeat(3, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
      >
        {data.map((team) => {
          const selected = selectedTeams.includes(team);
          return (
            <GridItem key={team} width='full'>
              <TeamSelect countryCode={Country[team]} selected={selected} nonSelectable={true} />
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};
