import { type ChangeEvent, useContext, useState } from 'react';
import {
  Button,
  HStack,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { type FunctionsError, httpsCallable } from 'firebase/functions';
import type batchEmailOptions from '@/models/batchEmailOptions';
import { batchEmailSubset } from '@/models/batchEmailOptions';
import { errorToastOptions, firebaseErrorToastOptions, successToastOptions } from '@/helpers';
import { FirebaseContext } from './FirebaseContext';

export function AdminOldEmailForm() {
  const ctx = useContext(FirebaseContext);
  const toast = useToast();

  const [emailSubset, setEmailSubset] = useState(batchEmailSubset.admin);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailPersonalisation, setEmailPersonalisation] = useState(false);
  const [emailCustomList, setEmailCustomList] = useState('');
  const [isBusy, setIsBusy] = useState(false);

  async function sendEmail() {
    const options: batchEmailOptions = {
      subset: emailSubset,
      template: emailTemplate, // this is for static imports
      subject: emailSubject,
      customList: emailCustomList.split(','),
      personalisation: emailPersonalisation,
    };

    setIsBusy(true);
    try {
      const result = (await httpsCallable(ctx.funcs, 'sendBatchEmail', { timeout: 530000 })(options)).data as {
        outcome: boolean;
        error: any;
      };
      if (result.outcome) {
        toast(successToastOptions('Success!', 'Email has been sent'));
        setEmailSubject('');
        setEmailTemplate('');
        setEmailSubset(batchEmailSubset.admin);
      } else {
        toast(errorToastOptions('Something went wrong', result.error));
      }
    } catch (e) {
      toast(firebaseErrorToastOptions('Sending Emails Error', e as FunctionsError));
    }
    setIsBusy(false);
  }

  return (
    <>
      <HStack>
        <FormControl width='200%'>
          <InputGroup>
            <InputLeftAddon>emailTemplate/</InputLeftAddon>
            <Input
              placeholder='template name'
              value={emailTemplate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailTemplate(e.target.value)}
            />
            <InputRightAddon>.html</InputRightAddon>
          </InputGroup>{' '}
          {/* this is for static imports */}
        </FormControl>
        <div style={{ width: '2vw' }} />
        <FormControl>
          <Checkbox
            isChecked={emailPersonalisation}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailPersonalisation(e.target.checked);
            }}
          >
            Requires Personalisation?
          </Checkbox>
        </FormControl>
      </HStack>
      <HStack>
        <FormControl>
          <Select
            value={emailSubset}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setEmailSubset(Number.parseInt(e.target.value))}
          >
            <option value={batchEmailSubset.all}>All</option>
            <option value={batchEmailSubset.incompleteList}>Incomplete List</option>
            <option value={batchEmailSubset.completeList}>Complete List</option>
            <option value={batchEmailSubset.admin}>Admins Only</option>
          </Select>
        </FormControl>
        <FormControl>
          <Input
            placeholder='custom email list'
            value={emailCustomList}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailCustomList(e.target.value);
            }}
          />
        </FormControl>
      </HStack>
      <HStack>
        <FormControl>
          <Input
            placeholder='email subject'
            value={emailSubject}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailSubject(e.target.value);
            }}
          />
        </FormControl>
        <Button colorScheme={'blue'} onClick={sendEmail} isLoading={isBusy}>
          Send email
        </Button>
      </HStack>
    </>
  );
}
