import type React from 'react';
import { Spacer as ChakraSpacer } from '@chakra-ui/react';
import Layout from '../Layout';
import { useAuthModal } from '../../hooks/useAuthModal';
import HeroSection from './HeroSection';
import PredictSection from './PredictSection';
import ChooseSeedsSection from './ChooseSeedsSection';
import CollectPointsSection from './CollectPointsSection';
import JoinLeagueSection from './JoinLeagueSection';
import FAQSection from './FAQSection';

const HomePage: React.FC = () => {
  const { openRegisterModal } = useAuthModal();
  return (
    <Layout>
      <Spacer mt='1em' />
      <HeroSection onClickCTA={openRegisterModal} />
      <Spacer />
      <PredictSection />
      <Spacer />
      <ChooseSeedsSection />
      <Spacer />
      <CollectPointsSection />
      <Spacer />
      <JoinLeagueSection onClickCTA={openRegisterModal} />
      <Spacer />
      <FAQSection />
    </Layout>
  );
};

export default HomePage;

const Spacer: React.FC<{ mt?: string | number }> = ({ mt }) => {
  const config = {
    base: '2rem',
    md: '3rem',
    lg: '4rem',
    xl: '5rem',
  };
  return <ChakraSpacer mt={mt ?? config} />;
};
