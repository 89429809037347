import type React from "react";
import { useContext } from "react";
import {
  Box,
  Button as CButton,
  Flex,
  HStack,
  Link,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import smallLogo from "@/assets/logos/smolLight.svg";
import bigLogo from "@/assets/logos/bannerLight.svg";
import { AuthCardsMode, FirebaseContext } from "@/components";
import Button from "../../components/Button";
import type { LinksType } from "./Footer";
import { Link as ReactRouterLink } from "react-router-dom";

interface NavBarProps {
  onClickLogin: () => void;
  onClickRegister: () => void;
}

const NavBar: React.FC<NavBarProps> = ({ onClickLogin, onClickRegister }) => {
  const ctx = useContext(FirebaseContext);
  const navigate = useNavigate();

  const logoSrc = useBreakpointValue({ base: smallLogo, md: bigLogo });

  const goToGame = () => {
    navigate("../profile");
  };

  return (
    <Box
      as="nav"
      bg="white"
      w="full"
      maxW="8xl"
      m="auto"
      px={{ base: 4, xl: 8 }}
      py={2}
      pos="sticky"
      top={0}
      zIndex={3}
      borderBottom={"1px solid"}
      borderColor={"rgb(0 0 0 / 10%)"}
    >
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <HStack spacing={8} alignItems={"center"}>
          <Box as={ReactRouterLink} to="/home">
            <Image
              alignSelf="center"
              height={{
                base: 6,
                xl: 8,
              }}
              mt={1}
              src={logoSrc}
            />
          </Box>
          <HStack as="nav" spacing={4} display={{ base: "none", md: "flex" }}>
            {Links.map((link, idx) => (
              <NavLink key={idx} {...link} />
            ))}
          </HStack>
        </HStack>
        <Flex>
          <HStack spacing={4} display={{ base: "flex", md: "flex" }}>
            {ctx.user ? (
              <CButton
                rightIcon={<ArrowForwardIcon />}
                colorScheme="green"
                bgColor='#27ADE4'
                onClick={goToGame}
              >
                Go to game
              </CButton>
            ) : (
              <>
                <Button
                  variant="outline"
                  bgColor="none"
                  text="Log In"
                  fontSize="md"
                  py="1.5em"
                  onClick={onClickLogin}
                />
                <Button
                  text="Register"
                  fontSize="md"
                  py="1.5em"
                  onClick={onClickRegister}
                />
              </>
            )}
          </HStack>
        </Flex>
        {/* <IconButton
          size='md'
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        /> */}
      </Flex>

      {/* {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map((link, idx) => (
              <NavLink key={idx} {...link} />
            ))}
          </Stack>
        </Box>
      ) : null} */}
    </Box>
  );
};

export default NavBar;

const NavLink = ({ name, href }: { name: string; href: string }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: "gray.200",
    }}
    href={href}
  >
    {name}
  </Link>
);

const Links: LinksType = [
  // {
  //   name: 'Home',
  //   href: '/',
  // },
  // {
  //   name: 'Game',
  //   href: '/game',
  // },
  // {
  //   name: 'Insights',
  //   href: '/insights',
  // },
  // {
  //   name: 'Scout',
  //   href: '/scout',
  // },
  // {
  //   name: 'Tournament',
  //   href: '/tournament',
  // },
  // {
  //   name: 'Share',
  //   href: '/share',
  // },
  // {
  //   name: 'Support Us',
  //   href: '/support-us',
  // },
];
