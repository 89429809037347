import { Button, type CreateToastFnReturn, Divider, Flex, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';
import {
  type Auth,
  type AuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { type NavigateFunction, useNavigate } from 'react-router';
import { errorToastOptions } from '@/helpers';
import { useSearchParams } from 'react-router-dom';

const GAUTH_PROVIDER: AuthProvider = new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' });

export const handleGoogle = (
  auth: Auth,
  navigate: NavigateFunction,
  toast: CreateToastFnReturn,
  redirectTo: string | null,
) => {
  // TODO: Investigate. signInWithPopup doesn't want to work on Safari
  // Only when action is result of a user click?
  // https://github.com/firebase/firebase-js-sdk/issues/6716

  signInWithPopup(auth, GAUTH_PROVIDER)
    .then(() => {
      if (redirectTo !== null) {
        navigate(redirectTo);
      } else {
        navigate('/profile');
      }
    })
    .catch((error) => {
      // console.error('error ', error);
      toast(
        errorToastOptions(
          'Something went wrong',
          'Please refresh the page and try again later or contact hello@tenteams.co.uk',
        ),
      );
    });
};
export const _handleEmailLogin = async (auth: Auth, toast: CreateToastFnReturn, email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then(() => true)
    .catch((error) => {
      toast(
        errorToastOptions(
          'Something went wrong',
          'Please refresh the page and try again later or contact hello@tenteams.co.uk',
        ),
      );
      // console.error('error ', error);
      return false;
    });
};

export function AuthDivider() {
  return (
    <Flex align='center' py={4}>
      <Divider
        orientation='horizontal'
        width={'100%'}
        height={'100%'}
        borderColor={useColorModeValue('gray', 'white')}
      />
      <Text fontSize='11px' padding={2} sx={{ whiteSpace: 'nowrap' }} color={useColorModeValue('black ', 'white')}>
        OR CONTINUE WITH
      </Text>
      <Divider
        orientation='horizontal'
        width={'100%'}
        height={'100%'}
        borderColor={useColorModeValue('gray', 'white')}
      />
    </Flex>
  );
}

export function GoogleButton({ auth, children }: { auth: Auth; children: string }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();

  return (
    <Button
      onClick={() => handleGoogle(auth, navigate, toast, searchParams.get('redirect_to'))}
      width='full'
      leftIcon={<FaGoogle color={'white'} />}
      backgroundColor={useColorModeValue('red.500', 'red.500')}
      _hover={{}}
    >
      <Text ml={1.5} fontSize={14} color={'white'}>
        {children}
      </Text>
    </Button>
  );
}
