import { type ChangeEvent, useContext, useEffect, useState, type KeyboardEvent } from 'react';
import './App.css';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  Center,
  Heading,
  Spinner,
  VStack,
  useToast,
  SlideFade,
  FormControl,
  Button,
  FormLabel,
  Input,
  Image,
  useColorModeValue,
  LightMode,
  Box,
} from '@chakra-ui/react';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import type { User } from '@/models';
import type { TeamSelectionData } from '@/models/user';
import ProfilePage from './views/ProfilePage';
import LeaguePage from './views/LeaguePage';
import { FirebaseContext } from './components/FirebaseContext';
import { firebaseErrorToastOptions, isValidEmail, toDict } from './helpers';
import AuthLayout from './views/AuthLayout';
import JoinLeagueView from './views/JoinLeagueView';
import LegacyHomePage from './views/HomePage/LegacyHomePage';
import HomePage from './views/HomePage';
import AdminPage from './views/AdminPage';
import ListPage from './views/ListPage';
import bannerLight from './assets/logos/bannerLight.svg';
import bannerDark from './assets/logos/bannerDark.svg';
import { UnsubscribePage } from './views/UnsubscribePage';
import BlogPage from './views/BlogPage';
import EmailVerificationPage from './views/EmailVerificationPage';
import SignOutPage from './views/SignOutPage';
import LogInPage from './views/LogInPage';
import ComponentsPage from './views/ComponentsPage';
import ScoutsPage, { ScoutPost } from './views/ScoutsPage';
import { logEvent } from 'firebase/analytics';

export default function App() {
  const ctx = useContext(FirebaseContext);
  const location = useLocation();

  const [userData, setUserData] = useState<User>();
  const [teamSelectionData, setTeamSelectionData] = useState<TeamSelectionData>({});
  const [pendingRedirect, setPendingRedirect] = useState<string>();
  const [userProfileExists, setUserProfileExists] = useState<boolean>();
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false);
  const [emailToConfirm, setEmailToConfirm] = useState<string>('');
  const toast = useToast();
  const heroSrc = useColorModeValue(bannerLight, bannerDark);

  useEffect(() => {
    if (ctx.user) {
      return onSnapshot(
        query(collection(ctx.db, `users/${ctx.user.uid}/tournaments`)),
        (snapshot) => {
          setTeamSelectionData(toDict(snapshot));
        },
        (error) => {
          console.error(error);
          throw error;
        },
      );
    } else {
      setTeamSelectionData({});
    }
  }, [ctx.db, ctx.user]);

  useEffect(() => {
    if (ctx.user) {
      return onSnapshot(
        doc(ctx.db, 'users', ctx.user.uid),
        (snapshot) => {
          setUserData(snapshot.data() as User);
          setUserProfileExists(snapshot.exists()); // If when we first query the account is doesn't exist then we know the profile is being set up
          setPendingRedirect(undefined);
        },
        (error) => {
          console.error(error);
          throw error;
        },
      );
    } else {
      setUserData(undefined);
      setUserProfileExists(undefined);
    }
  }, [ctx.db, ctx.user]);

  useEffect(() => {
    if (isSignInWithEmailLink(ctx.auth, window.location.href)) setPendingRedirect(window.location.href);
  }, [window.location.href]);

  useEffect(() => {
    if (pendingRedirect) {
      const email = localStorage.getItem('emailForSignIn')?.replaceAll('"', '')?.replaceAll('"', '') ?? null;
      if (email) {
        setEmailConfirmed(true);
        signInWithEmailLink(ctx.auth, email, pendingRedirect).catch((error) => {
          setPendingRedirect(undefined);
          setEmailConfirmed(false);
          setEmailToConfirm('');
          toast(firebaseErrorToastOptions('Something went wrong', error));
        });
        localStorage.removeItem('emailForSignIn');
      } else {
        setEmailConfirmed(false);
        setEmailToConfirm('');
      }
    }
  }, [pendingRedirect]);

  function onConfirmEmail(email: string) {
    signInWithEmailLink(ctx.auth, email, pendingRedirect).catch((error) => {
      setPendingRedirect(undefined);
      setEmailConfirmed(false);
      setEmailToConfirm('');
      toast(firebaseErrorToastOptions('Something went wrong', error));
    });
  }

  useEffect(() => {
    // logEvent(ctx.analytics, "screen_view", { page_path: location.pathname});
    logEvent(ctx.analytics, 'page_view', { page_title: location.pathname, page_path: location.pathname });
  }, [location]);

  if (pendingRedirect) {
    if (emailConfirmed) {
      return (
        <div className='App'>
          <Center width={'100vw'} height={'100vh'}>
            <SlideFade in>
              <VStack spacing={4}>
                <Heading fontSize={24}>Signing In</Heading>
                <Spinner />
              </VStack>
            </SlideFade>
          </Center>
        </div>
      );
    } else {
      return (
        <div className='App'>
          <Center width='100vw' height='100vh'>
            <VStack spacing={4}>
              <Heading>Welcome to</Heading>
              <Image src={heroSrc} />
              <SlideFade in>
                <VStack spacing={4} mt={8}>
                  <FormControl>
                    <FormLabel fontSize={18} textAlign='center'>
                      Please confirm your email address.
                    </FormLabel>
                    <Input
                      type={'email'}
                      textAlign={'center'}
                      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          onConfirmEmail(emailToConfirm);
                        }
                      }}
                      value={emailToConfirm}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailToConfirm(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    colorScheme={'green'}
                    isDisabled={!isValidEmail(emailToConfirm)}
                    onClick={() => onConfirmEmail(emailToConfirm)}
                  >
                    Confirm
                  </Button>
                </VStack>
              </SlideFade>
            </VStack>
          </Center>
        </div>
      );
    }
  } else {
    const SentryRoutes = withSentryReactRouterV6Routing(Routes);

    return (
      <div className='App'>
        <ColorMode mode='light' background='white'>
          <SentryRoutes>
            <Route path='/home' element={<HomePage />} />
            <Route path='/legacy-home' element={<LegacyHomePage />} />
            <Route path='/signout' element={<SignOutPage />} />
            <Route path='/login' element={<LogInPage />} />
            <Route path='/list' element={<ListPage />} />
            <Route path='/unsubscribe/:userID' element={<UnsubscribePage />} />
            <Route path='/verifyEmail' element={<EmailVerificationPage />} />
            <Route path='/components' element={<ComponentsPage />} />
            <Route path='/scout' element={<ScoutsPage />} />
            <Route path='/scout/:slug' element={<ScoutPost />} />
            <Route
              element={
                <AuthLayout
                  redirectTo='/login'
                  userData={userData}
                  teamSelectionData={teamSelectionData}
                  userProfileExists={userProfileExists}
                />
              }
            >
              <Route path='/join/:authCode' element={<JoinLeagueView userData={userData} />} />
              <Route path='/blog' element={<BlogPage />} />
              <Route path='/profile' element={<ProfilePage />} />
              <Route path='/league/:id' element={<LeaguePage />} />
              <Route path='/admin' element={<AdminPage />} />
            </Route>
            <Route
              path='*'
              element={
                ctx.userDataPresent ? (
                  <Navigate replace to={ctx.user ? '/profile' : '/home'} />
                ) : (
                  <Center width='100vw' height='100vh'>
                    <Spinner />
                  </Center>
                )
              }
            />
          </SentryRoutes>
        </ColorMode>
      </div>
    );
  }
}

type ColorModeProps = {
  mode: 'light' | 'dark';
  background?: string;
  children: React.ReactNode;
};

function ColorMode({ mode, background, children }: ColorModeProps): JSX.Element {
  if (mode === 'light') {
    return (
      <LightMode>
        <Box data-theme='light' color='var(--chakra-colors-chakra-body-text)' background={background}>
          {children}
        </Box>
      </LightMode>
    );
  } else {
    return (
      <LightMode>
        <Box data-theme='dark' color='var(--chakra-colors-chakra-body-text)' background={background}>
          {children}
        </Box>
      </LightMode>
    );
  }
}
