import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SlideFade,
  VStack,
  Image,
  useColorModeValue,
  FormHelperText,
} from '@chakra-ui/react';
import { type ChangeEvent, type KeyboardEvent, useContext, useState } from 'react';
import { updateUser } from '@/models/user';
import bannerLight from '../assets/logos/bannerLight.svg';
import bannerDark from '../assets/logos/bannerDark.svg';
import { FirebaseContext } from './FirebaseContext';

export function EnterNamePage() {
  const ctx = useContext(FirebaseContext);
  const [name, setName] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const heroSrc = useColorModeValue(bannerLight, bannerDark);

  async function onSubmit() {
    if (name.trim().length > 0) {
      setIsBusy(true);
      await updateUser(ctx.db, ctx.user!.uid, {
        name: name,
        requiresShareUpdate: true,
      });
      setIsBusy(false);
    }
  }

  return (
    <Center width='100vw' height='100vh'>
      <VStack spacing={4}>
        <Heading>Welcome to</Heading>
        <Image src={heroSrc} />
        <SlideFade in>
          <VStack spacing={4} mt={8}>
            <FormControl>
              <FormLabel fontSize={18} textAlign='center'>
                What name would you like to use?
              </FormLabel>
              <Input
                textAlign={'center'}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
                value={name}
                isDisabled={isBusy}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              />
              <FormHelperText>This will appear in all your leagues.</FormHelperText>
            </FormControl>
            <Button colorScheme={'green'} isDisabled={name.trim().length <= 0} onClick={onSubmit}>
              Submit
            </Button>
          </VStack>
        </SlideFade>
      </VStack>
    </Center>
  );
}
